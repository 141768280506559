import { Stack, Typography } from "@mui/material";

const Footer = () => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{
      // p: "24px 16px 0px",
      pl: 2,
      mt: "auto",
      height: "20px",
    }}
  >
    <Typography variant="caption">
      &copy; RegTech ♥ crafted by Team Maven
    </Typography>
  </Stack>
);

export default Footer;

import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Box,
  IconButton,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import { Toastr } from "components/Toastr";
import { useFormik } from "formik";
import { isError } from "lodash";
import { ApiServices } from "services/apiServices";
import { Constants } from "services/keyConstants";
import * as url from "services/url_helper";
import { Okb } from "iconsax-react";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import { ReactComponent as CrossIcon } from "../../../assets/button_svg/Vector.svg";
import { getObjectPermissions, getSecureToken } from "services/utils";
import {
  BookmarkBorderOutlined,
  BookmarkOutlined,
  Cancel,
} from "@mui/icons-material";
import InformationCard from "components/InformationCard";
import CustomDatePicker from "components/CustomDatePicker";
import { boxStyle, dataStyle, titleStyle } from "services/commonStyles";
import { helperFunction } from "services/helperFunction";
import DeleteModal from "components/DeleteModal";
import * as Yup from "yup";

function AddEditFInvoice({
  id = null,
  data,
  setData,
  setAddEditOpen,
  setId,
  parentData,
  parentModel,
  setRecallState,
}) {
  const [options, setOptions] = useState({
    pinvoice: [],
  });
  const [deleteData, setDeleteData] = useState(null);
  const validationSchema = Yup.object({
    pInvoiceId: Yup.mixed().test(
      "pInvoiceId-validation",
      "P Invoice is required",
      (value) => {
        if (!value) return false;
        if (typeof value === "string" && value.trim() !== "") return true;
        if (typeof value === "object" && value._id) return true;
        return false;
      }
    ),
    invoiceDate: Yup.string().trim().required("Invoice Date is required"),
  });
  const formik = useFormik({
    initialValues: {
      ...data,
      pInvoiceId: data?.pInvoiceId || "",
      invoiceDate: data?.invoiceDate ? data?.invoiceDate : new Date(),
      expiryDate: data?.expiryDate
        ? data?.expiryDate
        : new Date(new Date().setDate(new Date().getDate() + 7)),
    },
    validationSchema,
    onSubmit: (values) => {
      if (id) {
        const updatedValues = helperFunction.getUpdatedValues(values, data);
        updateFinvoice(updatedValues);
      } else {
        create(values);
      }
    },
  });

  const updateFinvoice = (values) => {
    try {
      let uri = url.FINVOICE + id;
      let body = {
        ...values,
      };

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);
            Toastr("F Invoice Updated Successfully", Constants.SUCCESS);
            if (setRecallState) {
              setRecallState(true);
            }
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.tesult?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const PinvoiceData = (query) => {
    try {
      let uri = url.PINVOICE + `/name/${query}`;
      // let uri = url.PINVOICE + formik.values.pInvoiceId._id;;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              pinvoice: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const getOnePinvoice = (idx) => {
    try {
      let uri = url.PINVOICE + idx;
      // let uri = url.PINVOICE + formik.values.pInvoiceId._id;;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setFieldValue(
              "proposalId",
              response?.result?.data.proposalId
            );
            formik.setFieldValue("accountId", response?.result?.data.accountId);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const getFInvoice = () => {
    try {
      let uri = url.FINVOICE + id;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.data);
            setData(response?.result?.data);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const handleInputChange = (event, newInputValue, reason) => {
    if (reason === "input") {
      if (newInputValue?.length >= 3) {
        PinvoiceData(newInputValue);
      } else {
        setOptions((prev) => ({ ...prev, pinvoice: [] }));
      }
    }
  };

  const create = (values) => {
    try {
      let uri = url.FINVOICE;
      let body = {
        ...values,
      };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("F Invoice Added Successfully", Constants.SUCCESS);
            if (setRecallState) {
              setRecallState(true);
            }
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn("call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: data?.name });
  };

  const permission = getObjectPermissions()?.["f-invoice"];
  useEffect(() => {
    if (id && !data) {
      getFInvoice();
    }

    if (parentModel) {
      switch (parentModel) {
        case "p-invoice":
          formik.setFieldValue("pInvoiceId", parentData);
          formik.setFieldValue("accountId", parentData?.accountId);
          formik.setFieldValue("opportunityId", parentData?.opportunityId);
          formik.setFieldValue("proposalId", parentData?.proposalId);
          break;
        case "proposal":
          formik.setFieldValue("proposalId", parentData);
          formik.setFieldValue("opportunityId", parentData?.opportunityId);
          formik.setFieldValue("accountId", parentData?.accountId);
          PinvoiceData(`getallvalues?${parentModel}Id=${parentData?._id}`);
          break;
        case "opportunity":
          formik.setFieldValue("opportunityId", parentData);
          formik.setFieldValue("accountId", parentData?.accountId);
          PinvoiceData(`getallvalues?${parentModel}Id=${parentData?._id}`);
          break;
        case "account":
          formik.setFieldValue("accountId", parentData);
          PinvoiceData(`getallvalues?${parentModel}Id=${parentData?._id}`);
          break;
        default:
          if (parentData?.proposalId) {
            formik.setFieldValue("proposalId", parentData?.proposalId);
            // formik.setFieldValue("opportunityId", parentData?.opportunityId);
            formik.setFieldValue("accountId", parentData?.accountId);
          } else {
            formik.setFieldValue("opportunityId", parentData?.opportunityId);
            formik.setFieldValue("accountId", parentData?.accountId);
            PinvoiceData(`getallvalues?${parentModel}Id=${parentData?._id}`);
          }
      }
    }

    // if (parentModel) {
    //   if (parentModel == "pInvoice") {
    //     formik.setFieldValue("pInvoiceId", parentData);
    //     formik.setFieldValue("accountId", parentData?.accountId);
    //     formik.setFieldValue("proposalId", parentData?.proposalId);
    //   } else if (parentModel == "proposal") {
    //     formik.setFieldValue("proposalId", parentData);
    //     formik.setFieldValue("accountId", parentData?.accountId);
    //   } else if (parentData?.pInvoice) {
    //     formik.setFieldValue("pInvoiceId", parentData?.pInvoiceId);
    //     formik.setFieldValue("accountId", parentData?.accountId);
    //     formik.setFieldValue("proposalId", parentData?.proposalId);
    //   } else if (parentData?.proposalId) {
    //     formik.setFieldValue("proposalId", parentData.proposalId);
    //     formik.setFieldValue("accountId", parentData?.accountId);
    //   } else {
    //     formik.setFieldValue("accountId", parentData);
    //     PinvoiceData(`getallvalues?${parentModel}Id=${parentData?._id}`);
    //   }
    // }
  }, []);

  return (
    <>
      {/* <button onClick={()=>PinvoiceData()}>check</button> */}
      <div>
        <DeleteModal
          dataUrl={url.FINVOICE}
          deleteData={deleteData}
          setDeleteData={setDeleteData}
          //    callFunc={addFilter}
          navLink={"/fInvoice-list"}
        />
        <Box sx={boxStyle}>
          <Typography
            component='div'
            sx={{
              fontSize: "14px",
              fontWeight: "400",
            }}>
            F Invoice Information
          </Typography>

          {/* {permission !== undefined && permission[0] >= 3 && ( */}
          <>
            <Typography
              component='span'
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}>
              <IconButton
                size='small'
                onClick={() => setAddEditOpen((prev) => !prev)}
                sx={{
                  p: 0.95,
                  borderRadius: "5px",
                  border: "1px solid red",
                }}>
                <CrossIcon width='2' height='2' />
              </IconButton>

              <IconButton
                size='small'
                color='green'
                onClick={() => formik.handleSubmit()}
                sx={{
                  p: 0,
                  borderRadius: "5px",
                  border: "1px solid green",
                }}>
                <BookmarkBorderOutlined
                  sx={{ color: "green", height: "16px" }}
                />{" "}
              </IconButton>

              {id && permission !== undefined && permission[0] >= 3 && (
                <IconButton
                  size='small'
                  // color="primary"
                  onClick={handleOpenDelete}
                  sx={{
                    p: 0,
                    borderRadius: "5px",
                    border: "1px solid #4680FF ",
                  }}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Typography>
          </>
          {/* )} */}
        </Box>

        <Box
          sx={{
            m: 0,
            p: 0,
            maxHeight: "65vh",
            overflowY: "auto",
            scrollbarWidth: "none",
          }}>
          <form onSubmit={formik.handleSubmit}>
            <InformationCard title='Core Details' color='red'>
              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle}>F Invoice Number</Typography>

                <Typography {...dataStyle}>
                  {formik?.values?.name ? formik.values?.name : "" || "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle}>
                  P Invoice Number
                  <Typography variant='span' color='red'>
                    *
                  </Typography>
                </Typography>

                <Autocomplete
                  id='pInvoiceId'
                  name='pInvoiceId'
                  options={options?.pinvoice}
                  getOptionLabel={(option) => option?.name}
                  value={
                    typeof formik.values.pInvoiceId === "object"
                      ? {
                          name: formik.values.pInvoiceId?.name || "",
                          _id: formik.values.pInvoiceId?._id || "",
                        }
                      : options.pinvoice.find(
                          (option) => option._id === formik.values?.pInvoiceId
                        ) || null
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "pInvoiceId",
                      newValue ? newValue?._id : ""
                    );
                    getOnePinvoice(newValue?._id);
                  }}
                  onInputChange={handleInputChange}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder='Select P Invoice Number'
                      error={
                        formik.touched.pInvoiceId &&
                        Boolean(formik.errors.pInvoiceId)
                      }
                      helperText={
                        formik.touched.pInvoiceId && formik.errors.pInvoiceId
                      }
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          opacity: 1,
                          color: "#4c4d4f !important",
                          WebkitTextFillColor: "#4c4d4f !important",
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle}>Prposal Number</Typography>

                <Typography {...dataStyle}>
                  {formik?.values?.proposalId?.name
                    ? formik.values.proposalId.name
                    : "" || "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle}>Account Name </Typography>

                <Typography {...dataStyle}>
                  {formik?.values?.accountId?.name
                    ? formik.values.accountId.name
                    : "" || "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle}>Opportunity</Typography>

                <Typography {...dataStyle}>
                  {formik?.values?.opportunityId?.name
                    ? formik.values.opportunityId.name
                    : "" || "-"}
                </Typography>
              </Grid>
            </InformationCard>

            <InformationCard title='General Details' color='blue'>
              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle} variant='h6'>
                  Invoice Date
                  <Typography variant='span' color='red'>
                    *
                  </Typography>
                </Typography>

                <CustomDatePicker
                  value={formik?.values?.invoiceDate}
                  onChange={(date) => {
                    formik.setFieldValue("invoiceDate", date);
                    formik.setFieldValue(
                      "expiryDate",
                      new Date().setDate(date?.getDate() + 7)
                    );
                  }}
                  error={
                    formik.touched.invoiceDate &&
                    Boolean(formik.errors.invoiceDate)
                  }
                  helperText={
                    formik.touched.invoiceDate && formik.errors.invoiceDate
                  }
                  // skipDay={0}
                />
              </Grid>

              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle} variant='h6'>
                  Expiry Date
                </Typography>
                <CustomDatePicker
                  value={formik?.values?.expiryDate}
                  onChange={(date) => formik.setFieldValue("expiryDate", date)}
                  // skipDay={7}
                />
              </Grid>

              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle}>Currency</Typography>

                <Typography {...dataStyle}>
                  {formik?.values?.currencyId?.isoCode
                    ? formik.values?.currencyId?.isoCode
                    : "" || "-"}
                </Typography>
              </Grid>
            </InformationCard>

            <InformationCard title='Other Details' color='green'>
              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle}>Total Invoiced Value</Typography>

                <Typography {...dataStyle}>
                  {formik?.values?.totalInvoicedValue
                    ? formik.values.totalInvoicedValue
                    : "" || "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle}>FP Bank Charges</Typography>

                <TextField
                  name='fpBankCharges'
                  value={formik?.values?.bankCharges}
                  onChange={formik.handleChange}
                  fullWidth
                  variant='outlined'
                  placeholder='Enter FP Bank Charges'
                  InputProps={{
                    sx: {
                      height: "41px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle}>GST(9%)</Typography>

                <Typography {...dataStyle}>
                  {formik?.values?.gst9 ? formik.values?.gst9 : "" || "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle}>GST</Typography>

                <Typography {...dataStyle}>
                  {formik?.values?.gst ? formik.values?.gst : "" || "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle}>Converted INR Value</Typography>

                <Typography {...dataStyle}>
                  {formik?.values?.convertedINRPayment
                    ? formik.values.convertedINRPayment
                    : "" || "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle}>
                  Total Invoiced Value (With GST)
                </Typography>

                <TextField
                  name='fpBankCharges'
                  value={formik?.totalFinalInvoicedValueWithGST}
                  onChange={formik.handleChange}
                  fullWidth
                  variant='outlined'
                  placeholder='Enter Total Invoiced Value (With GST)'
                  InputProps={{
                    sx: {
                      height: "41px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                <Typography {...titleStyle}>Notes</Typography>

                <TextField
                  name='fpBankCharges'
                  value={formik?.values?.bankCharges}
                  onChange={formik.handleChange}
                  fullWidth
                  variant='outlined'
                  placeholder='Enter Notes'
                  InputProps={{
                    sx: {
                      height: "41px",
                    },
                  }}
                />
              </Grid>
            </InformationCard>
          </form>
        </Box>
      </div>
    </>
  );
}

export default AddEditFInvoice;

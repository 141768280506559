import { useLayoutEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import NavGroup from "./NavGroup";
import menuItem from "menu-items";
import { useSelector } from "store";
import useConfig from "hooks/useConfig";
import withRouter from "components/withRouter";
import NavItem from "./NavItem";

const Navigation = (props) => {
  const { theme } = props,
    { HORIZONTAL_MAX_ITEM, MenuOrientation } = props?.config;

  const downLG = useMediaQuery(theme.breakpoints.down("lg"));

  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);

  const [selectedItems, setSelectedItems] = useState("");
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [menuItems, setMenuItems] = useState({ items: [] });

  useLayoutEffect(() => {
    setMenuItems(menuItem);
    // eslint-disable-next-line
  }, [menuItem]);

  const isHorizontal =
    menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItems.items.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < menuItems?.items?.length) {
    lastItemId = menuItems?.items[lastItem - 1]?.id;
    lastItemIndex = lastItem - 1;
    remItems = menuItems?.items
      .slice(lastItem - 1, menuItems?.items?.length)
      ?.map((item) => ({
        title: item?.title,
        elements: item?.children,
        icon: item?.icon,
      }));
  }

  const navGroups = menuItems?.items
    .slice(0, lastItemIndex + 1)
    ?.map((item) => {
      switch (item.type) {
        case "item": // Handle standalone items
          return (
            <NavItem
              key={item?.id}
              item={item} // Pass the item object
              level={1} // Adjust the level as per your structure
            />
          );
        case "group":
          return (
            <NavGroup
              key={item?.id}
              setSelectedItems={setSelectedItems}
              setSelectedLevel={setSelectedLevel}
              selectedLevel={selectedLevel}
              selectedItems={selectedItems}
              lastItem={lastItem}
              remItems={remItems}
              lastItemId={lastItemId}
              item={item}
            />
          );
        default:
          return (
            <Typography
              key={item?.id}
              variant="h6"
              color="error"
              align="center"
            >
              Fix - Navigation Group
            </Typography>
          );
      }
    });
  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        "& > ul:first-of-type": { mt: 0 },
        display: isHorizontal ? { xs: "block", lg: "flex" } : "block",
      }}
    >
      {navGroups}
    </Box>
  );
};

export default withRouter(Navigation);

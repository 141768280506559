import React from "react";
import Loaders from "../Loaders/Loader.css";

import { Box, styled, keyframes } from "@mui/material";

const rotateWithPause = keyframes`
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;


const Container = styled(Box)`
  position: relative;
  width: 250px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-weight: bold;
  background: linear-gradient(45deg, #2465e1, #80abff);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: diagonalFlow 3s linear infinite alternate;

  @keyframes diagonalFlow {
    0% {
      background-position: 0% 0%; /* Start from top-left */
    }
    50% {
      background-position: 100% 100%; /* Move to bottom-right */
    }
    100% {
      background-position: 0% 0%; /* Return to top-left */
    }
  }
`;
// url(#clip0_376_89889)

const LoaderWrapper = styled(Box)({
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  zIndex: 5,
});

const LoaderContainer = styled(Box)({
  width: 200,
  height: 200,
  position: "relative",
});

const centerSize = 180;
const gap = 60;

const arcSize = centerSize + gap;
const offset = (arcSize - centerSize) / 2;

const CenterCircle = styled(Box)({
  position: "absolute",
  top: offset,
  left: offset,
  width: centerSize,
  height: centerSize,
  borderRadius: "50%",
  backgroundColor: "#1A3D7C",
  zIndex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ArcBase = styled(Box)({
  position: "absolute",
  width: arcSize,
  height: arcSize,
  top: 0,
  left: 0,
  borderRadius: "50%",
  border: "15px solid transparent",
  animation: `${rotateWithPause} 3s linear infinite`,
});

const ArcWrapper = styled(Box)(({ rotation }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  transform: `rotate(${rotation}deg)`,
  transformOrigin: "50% 50%",
}));

const Arc1 = () => (
  <ArcWrapper rotation={0}>
    <ArcBase sx={{ borderTopColor: "#143E8F" }} />
  </ArcWrapper>
);
const Arc2 = () => (
  <ArcWrapper rotation={120}>
    <ArcBase sx={{ borderTopColor: "#2465E1" }} />
  </ArcWrapper>
);
const Arc3 = () => (
  <ArcWrapper rotation={240}>
    <ArcBase sx={{ borderTopColor: "#80ABFF" }} />
  </ArcWrapper>
);

export default function ThreeArcLoader() {
  return (
    <LoaderWrapper>
      <LoaderContainer sx={{ width: arcSize, height: arcSize }}>
        <CenterCircle>
          <div class="container">
            <div class="box">
              <div class="linesPage">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
              </div>
              <div class="plus">+</div>
            </div>
            <div class="circle"></div>
            <div class="base"></div>
            <div class="stand"></div>
            <div class="screen">
              <div class="plate"></div>
              <div class="separator"></div>
              <div class="plate"></div>
              <div class="separator"></div>
              <div class="plate"></div>
              <div class="separator"></div>
            </div>
            <div class="button1"></div>
            <div class="button2"></div>
            <div class="badge">
              <div class="baseCircle"></div>
            </div>
            <div class="ribbon"></div>

            <div class="topBox"></div>
          </div>
        </CenterCircle>

        <Arc1 />
        <Arc2 />
        <Arc3 />
      </LoaderContainer>
      <Container>RegTech</Container>
    </LoaderWrapper>
  );
}

import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Box, Container, Toolbar } from "@mui/material";
import Drawer from "./Drawer";
import Header from "./Header";
import Footer from "./Footer";
import HorizontalBar from "./Drawer/HorizontalBar";
import { DRAWER_WIDTH } from "config";
import useConfig from "hooks/useConfig";
import { dispatch } from "store";
import { openDrawer } from "store/reducers/menu";
import { MenuOrientation } from "config";
import NavBar from "./nav";
// import BreadCrumb from "./breadcrumb";
import bgImg from "../../assets/bg-images/download.png";

const MainLayout = () => {
  const theme = useTheme();
  const downXL = useMediaQuery(theme.breakpoints.down("xl"));
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));

  const { container, miniDrawer, menuOrientation } = useConfig();

  const isHorizontal =
    menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  useEffect(() => {
    if (miniDrawer) {
      dispatch(openDrawer(!downXL));
    }
  }, [downXL]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100dvh",
          overflow: "hidden",
          background: `
                url(${bgImg}) repeat-x center bottom/100% 70%,
                linear-gradient(to bottom, rgba(155,187,255,1) 0%, rgba(202,219,255,1) 25%, rgba(241,246,255,1) 80%);
          `,
          backgroundSize: "100% 70%, 100% 100%",
          backgroundPosition: "center bottom, center top",
          backgroundRepeat: "repeat-x",
          backgroundBlendMode: "darken",
          backgroundAttachment: "fixed",

          // "@media (max-width: 768px)": {
          //   backgroundSize: "150% 70%, cover",
          //   backgroundPosition: "center bottom, center top",
          // },
        }}
      >
        {/* <Header />
      {!isHorizontal ? <Drawer /> : <HorizontalBar />} */}

        <Box
          component="main"
          sx={{
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            flexGrow: 1,
            // p: { xs: 2, md: 3 },
          }}
        >
          {/* <Box sx={{ height: "10dvh" }}> */}
          <NavBar />
          {/* </Box> */}
          {/* <Toolbar
            sx={{
              mt: isHorizontal ? 8 : "inherit",
              mb: isHorizontal ? 2 : "inherit",
            }}
          /> */}
          <Container
            maxWidth={container ? "xl" : false}
            sx={{
              xs: 0,
              ...(container && { px: { xs: 0, md: 2 } }),
              position: "relative",
              height: "calc(100vh - 93px)",
              // maxHeight: "90dvh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Outlet />
          </Container>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;

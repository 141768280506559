import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";

import AuthGuard from "utils/route-guard/AuthGuard";
import Dashboard from "pages/dashboard/dashboard";
import QuestionStandardList from "pages/apps/Standards/QuestionStandardList";
import Opportuntiy from "pages/CRM/Opportunity/Opportunity";
import Currency from "pages/CRM/Currency/Currency";
import CurrencyView from "pages/CRM/Currency/CurrencyView";
import ServiceBrief from "pages/CRM/ServiceBrief/ServiceBrief";
import ServiceBriefView from "pages/CRM/ServiceBrief/ServiceBriefView";
// import Account from "pages/CRM/Account/Account";

const AppClientList = Loadable(lazy(() => import("pages/apps/client/list")));

const StandardList = Loadable(
  lazy(() => import("pages/apps/Standards/StandardList"))
);
const StandardDetails = Loadable(
  lazy(() => import("pages/apps/Standards/StandardDetails"))
);
const ShortQuestion = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/ShortQuestion"))
);
const McqQuestions = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/McqQuestions"))
);
const McmcQuestions = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/McmcQuestions"))
);
const QuestionList = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/QuestionList"))
);
const DropdownQuestions = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/DropdownQuestions"))
);

const FollowUpTask = Loadable(
  lazy(() => import("pages/CRM/FollowUpTask/FollowUpTask"))
);

const Account = Loadable(lazy(() => import("pages/CRM/Account/Account")));

const AccountView = Loadable(
  lazy(() => import("pages/CRM/Account/AccountView"))
);
const Opportunity = Loadable(
  lazy(() => import("pages/CRM/Opportunity/Opportunity"))
);
const OpportunityView = Loadable(
  lazy(() => import("pages/CRM/Opportunity/OpportunityView"))
);
const PaymentDetail = Loadable(
  lazy(() => import("pages/CRM/PaymentDetails/PaymentDetails"))
);
const PaymentDetailView = Loadable(
  lazy(() => import("pages/CRM/PaymentDetails/PaymentDetailsView"))
);
const Proposal = Loadable(lazy(() => import("pages/CRM/Proposal/Proposal")));
const ProposalView = Loadable(
  lazy(() => import("pages/CRM/Proposal/ProposalView"))
);
const ProposalLine = Loadable(
  lazy(() => import("pages/CRM/ProposalLine/ProposalLine"))
);
const PInvoice = Loadable(lazy(() => import("pages/CRM/PInvoice/PInvoice")));

const PInvoiceView = Loadable(
  lazy(() => import("pages/CRM/PInvoice/PInvoiceView"))
);

const FInvoice = Loadable(lazy(() => import("pages/CRM/FInvoice/FInvoice")));

const FInvoiceView = Loadable(
  lazy(() => import("pages/CRM/FInvoice/FInvoiceView"))
);

const RegTech = Loadable(lazy(() => import("pages/CRM/RagTech/RegTechView")));

const PaymentDistribution = Loadable(
  lazy(() => import("pages/CRM/PaymentDistribution/PaymentDistribution"))
);
const PaymentDistributionView = Loadable(
  lazy(() => import("pages/CRM/PaymentDistribution/PaymentDistributionView"))
);

const PDFView = Loadable(lazy(() => import("pages/CRM/PDF/PDFViewPage")));

const TermCondition = Loadable(
  lazy(() => import("pages/CRM/TermCondition/TermCondition"))
);
const TermConditionView = Loadable(
  lazy(() => import("pages/CRM/TermCondition/TermConditionView"))
);

const Contact = Loadable(lazy(() => import("pages/CRM/Contact/Contact")));
const ContactView = Loadable(
  lazy(() => import("pages/CRM/Contact/ContactView"))
);

const ObjectPermission = Loadable(
  lazy(() => import("pages/CRM/Admin/ObjectPermission"))
);

const Users = Loadable(lazy(() => import("pages/CRM/Users/Users")));
const UsersView = Loadable(lazy(() => import("pages/CRM/Users/UsersView")));


const Bank = Loadable(lazy(() => import("pages/CRM/Bank/Bank")));
const BankView = Loadable(lazy(() => import("pages/CRM/Bank/BankView")));

const createRoute = (path, element, children) => ({ path, element, children });

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    createRoute(
      "/",
      <AuthGuard>
        <MainLayout />
      </AuthGuard>,
      [
        createRoute("dashboard", <Dashboard />),
        createRoute("client", null, [
          createRoute("client-list", <AppClientList />),
        ]),
        createRoute("standards", null, [
          createRoute("standard-list", <StandardList />),
          createRoute("standard-details", <StandardDetails />),
          createRoute("questions", <QuestionStandardList />),
        ]),
        createRoute("documentQuestion", null, [
          createRoute("mcq-question", <McqQuestions />),
          createRoute("mcmc-question", <McmcQuestions />),
          createRoute("short-question", <ShortQuestion />),
          createRoute("allQuestions", <QuestionList />),
          createRoute("dropdown-question", <DropdownQuestions />),
        ]),

        createRoute("followUpTask", <FollowUpTask />),

        createRoute("account-list", <Account />),
        createRoute("account/view/:id", <AccountView />),

        createRoute("opportunity-list", <Opportunity />),
        createRoute("opportunity/view/:id", <OpportunityView />),

        createRoute("proposal-list", <Proposal />),
        createRoute("proposal/view/:id", <ProposalView />),
        createRoute("proposal-line", <ProposalLine />),

        createRoute("paymentDetail-list", <PaymentDetail />),
        createRoute("paymentDetail/view/:id", <PaymentDetailView />),

        createRoute("paymentDistribution-list", <PaymentDistribution />),
        createRoute(
          "paymentDistribution/view/:id",
          <PaymentDistributionView />
        ),
        createRoute("pInvoice-list", <PInvoice />),
        createRoute("pInvoice/view/:id", <PInvoiceView />),

        createRoute("fInvoice-list", <FInvoice />),
        createRoute("fInvoice/view/:id", <FInvoiceView />),

        createRoute("currency", <Currency />),
        createRoute("currency/view/:id", <CurrencyView />),

        createRoute("serviceBrief-list", <ServiceBrief />),
        createRoute("serviceBrief/view/:id", <ServiceBriefView />),

        createRoute("regtech", <RegTech />),

        createRoute("/pdf/view/:type/:id", <PDFView />),

        createRoute("termCondition-list", <TermCondition />),
        createRoute("termCondition/view/:id", <TermConditionView />),

        createRoute("contact-list", <Contact />),
        createRoute("contact/view/:id", <ContactView />),

        createRoute("objectPermissions", <ObjectPermission />),

        createRoute("users-list", <Users />),
        createRoute("users/view/:id", <UsersView />),

        createRoute("bank-list", <Bank />),
        createRoute("bank/view/:id", <BankView />),
      ]
    ),
  ],
};

export default MainRoutes;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import { ApiServices } from "../../../services/apiServices";
import * as url from "../../../services/url_helper";
import DeleteModal from "components/DeleteModal";
import InformationCard from "components/InformationCard";
import BreadCrumb from "components/BreadCrumb";
import { Container } from "@mui/system";
import RelatedList from "components/RelatedList/RelatedList";
import { Clock, Edit } from "iconsax-react";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import { helperFunction } from "services/helperFunction";
import AddEditCurrency from "./AddEditCurrency";
import Loaders from "components/Loaders/Loaders";
import {
  titleStyle,
  dataStyle,
  cardStyle,
  containerStyle,
  boxStyle,
  typographyStyle,
} from "services/commonStyles";
import { getObjectPermissions } from "services/utils";

const CurrencyView = () => {
  const [currencyData, setCurrencyData] = useState({});
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [deleteData, setDeleteData] = useState(null);
  const [addEditOpen, setAddEditOpen] = useState(false);

  const fetchCurrencyData = () => {
    let uri = url.CURRENCY + id;
    ApiServices.callServiceGet(uri)
      .then((response) => {
        if (response?.result?.type === "success") {
          setIsLoading(false);
          setCurrencyData(response?.result?.data);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.warn("API Error", error);
      });
  };

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: currencyData?.isoCode });
  };

  useEffect(() => {
    fetchCurrencyData();
  }, []);

  const permission = getObjectPermissions().currency;

  return (
    <div className="page-content" style={{ height: "100%" }}>
      <BreadCrumb title="Currency" currentPage="RegTech" />
      {isLoading ? (
        <Loaders />
      ) : currencyData && Object.keys(currencyData).length > 0 ? (
        <Grid container spacing={2} height="90%">
          <Grid item xs={12} sm={8} md={6} sx={{ height: "99%" }}>
            <Card sx={cardStyle}>
              <DeleteModal
                dataUrl={url.CURRENCY}
                deleteData={deleteData}
                setDeleteData={setDeleteData}
                navLink={"/currency"}
              />

              <Container
                sx={containerStyle}
                onDoubleClick={() => handleDoubleClick()}
              >
                <CardContent sx={{ p: 0, height: "100%" }}>
                  {addEditOpen ? (
                    <>
                      <AddEditCurrency
                        id={id}
                        data={currencyData}
                        setData={setCurrencyData}
                        setAddEditOpen={setAddEditOpen}
                      />
                    </>
                  ) : (
                    <>
                      <Box sx={boxStyle}>
                        <Typography variant="div">
                          Currency Information
                        </Typography>
                        {permission !== undefined && permission[0] >= 3 && (
                          <>
                            <Typography {...typographyStyle}>
                              <Button
                                variant="contained"
                                startIcon={<Clock />}
                                color="success"
                                size="small"
                              >
                                History
                              </Button>

                              <IconButton
                                size="small"
                                color="green"
                                onClick={() => setAddEditOpen(!addEditOpen)}
                                sx={{
                                  p: 0,
                                  borderRadius: "5px",
                                  border: "1px solid green",
                                }}
                              >
                                <Edit size="small" color="green" />
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={handleOpenDelete}
                                sx={{
                                  p: 0,
                                  borderRadius: "5px",
                                  border: "1px solid #4680FF ",
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Typography>
                          </>
                        )}
                      </Box>

                      <Box
                        sx={{
                          m: 0,
                          p: 0,
                          maxHeight: "66vh",
                          overflowY: "auto",
                          scrollbarWidth: "none",
                        }}
                      >
                        <>
                          <InformationCard title="Core Details">
                            <Grid item xs={12} sm={6} sx={{ pl: 2, pt: 1.5 }}>
                              <Typography {...titleStyle}>
                                Currency Name
                              </Typography>
                              <Typography>
                                {currencyData?.isoCode || ""}
                              </Typography>
                              <Typography {...titleStyle}>
                                Decimal Places
                              </Typography>
                              <Typography>
                                {currencyData?.decimalPlaces || ""}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5 }}>
                              <Typography {...titleStyle}>
                                Conversion Rate
                              </Typography>
                              <Typography>
                                {currencyData?.conversionRate || ""}
                              </Typography>
                            </Grid>
                          </InformationCard>

                          <InformationCard title="Author Detail" color="orange">
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              sx={{ px: 2, pt: 1.5, m: 0 }}
                            >
                              <Typography {...titleStyle}>
                                Created By
                              </Typography>
                              <Typography {...dataStyle}>
                                <span style={{ display: "block" }}>
                                  {currencyData?.createdBy?.fullName},{" "}
                                </span>
                                {helperFunction.dateFormat(
                                  currencyData?.createdAt,
                                  true
                                )}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              sm={6}
                              sx={{ px: 2, pt: 1.5, m: 0 }}
                            >
                              <Typography {...titleStyle}>
                                Last Modified By
                              </Typography>
                              <Typography {...dataStyle}>
                                <span style={{ display: "block" }}>
                                  {currencyData?.lastModifiedBy?.fullName ||
                                    currencyData?.createdBy?.fullName}
                                  ,{" "}
                                </span>
                                {helperFunction.dateFormat(
                                  currencyData?.updatedAt,
                                  true
                                )}
                              </Typography>
                            </Grid>
                          </InformationCard>
                        </>
                      </Box>
                    </>
                  )}
                </CardContent>
              </Container>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={6} sx={{ height: "100%" }}>
            <RelatedList relatedName={"currency"} />
          </Grid>
        </Grid>
      ) : (
        <h5 className="text-primary text-center" style={{ fontWeight: "bold" }}>
          No Data Available / Currency Deleted
        </h5>
      )}
    </div>
  );
};

export default CurrencyView;

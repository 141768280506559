import React from "react";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Box,
  Stack,
  Select,
  MenuItem,
  Card,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import InformationCard from "components/InformationCard";
import { ReactComponent as CrossIcon } from "../../../assets/button_svg/Vector.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import {
  BookmarkBorderOutlined,
  BookmarkOutlined,
  Cancel,
} from "@mui/icons-material";
import * as url from "services/url_helper";
import { ApiServices } from "services/apiServices";
import { helperFunction } from "services/helperFunction";
import { Toastr } from "components/Toastr";
import { Constants } from "services/keyConstants";
import { titleStyle } from "services/commonStyles";

const permission = [3, 1];

function AddEditRedTech({ id = null, data, setData, setAddEditOpen }) {
  const formik = useFormik({
    initialValues: {
      ...data,
    },
    onSubmit: (values) => {
      if (values) {
        const updatedValues = helperFunction.getUpdatedValues(values, data);
        regTechUpdate(updatedValues);
      }
    },
  });

  const regTechUpdate = (values) => {
    try {
      let uri = url.REGTECH;

      let body = {
        ...values,
      };

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);
            Toastr("RegTech Updated Successfully", Constants.SUCCESS);
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          py: 2,
        }}
      >
        <Typography
          component="div"
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          RegTech Information
        </Typography>
        {permission !== undefined && permission[0] >= 3 && (
          <>
            <Typography
              component="span"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <IconButton
                size="small"
                color="green"
                onClick={() => setAddEditOpen((prev) => !prev)}
                sx={{
                  p: 0.95,
                  borderRadius: "5px",
                  border: "1px solid red",
                }}
              >
                <CrossIcon width="2" height="2" />
              </IconButton>

              <IconButton
                size="small"
                color="green"
                onClick={(event) => {
                  formik.handleSubmit();
                }}
                sx={{
                  p: 0,
                  borderRadius: "5px",
                  border: "1px solid green",
                }}
              >
                <BookmarkBorderOutlined
                  sx={{ color: "green", height: "16px" }}
                />{" "}
              </IconButton>
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          m: 0,
          p: 0,
          maxHeight: "65vh",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <InformationCard title="Core Details" color="#E76767">
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Name</Typography>

              <TextField
                name="name"
                value={formik?.values?.name}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter name"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Email</Typography>

              <TextField
                name="email"
                value={formik?.values?.email}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter name"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Phone</Typography>

              <TextField
                name="phone"
                value={formik?.values?.phone}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter name"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>GST Number</Typography>

              <TextField
                name="gstNumber"
                value={formik.values.gstNumber}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter name"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
          </InformationCard>

          <InformationCard title="General Details" color="#0000FF">
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Postal Code</Typography>

              <TextField
                name="street"
                value={formik?.values?.postalCode}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter street"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Street</Typography>

              <TextField
                name="street"
                value={formik.values.street}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter street"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>City</Typography>

              <TextField
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter city"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>State</Typography>

              <TextField
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter state"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Country</Typography>

              <TextField
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter country"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
          </InformationCard>
        </form>
      </Box>
    </div>
  );
}

export default AddEditRedTech;

import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Okb } from "iconsax-react";
import React from "react";
import { Colors } from "services/colorConstant";

const InformationCard = ({
  title = "Core Details",
  children,
  color = "blue",
}) => {
  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{
          p: 0,
          pb: 1,
          m: 0,
          my: 2,
          border: `0.5px solid ${Colors.CARD_BORDERCOLOR}`,
          borderRadius: "5px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            px: "5px",
            position: "absolute",
            top: "-10px",
            left: "20px",
            zIndex: 2,
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            textAlign: "center",
            gap: "8px",
          }}
        >
          <Typography
            sx={{
              height: "16px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Okb variant="Bold" color={color} size={16} />
            <span> {title}</span>
          </Typography>
        </Box>
        {children}
      </Grid>
    </>
  );
};

export default InformationCard;

import { BookmarkBorderOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Constants } from "services/keyConstants";
import { Box } from "@mui/system";
import CustomDatePicker from "components/CustomDatePicker";
import InformationCard from "components/InformationCard";
import { Toastr } from "components/Toastr";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { ApiServices } from "services/apiServices";
import * as url from "services/url_helper";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import { ReactComponent as CrossIcon } from "../../../assets/button_svg/Vector.svg";
import JoditEditor from "jodit-react";
import DeleteModal from "components/DeleteModal";
import { getObjectPermissions, getSecureToken } from "services/utils";
const AddEditTermCondition = ({
  id = null,
  data,
  setData,
  setAddEditOpen,
  setId,
  setRecallState,
}) => {
  const [options, setOptions] = useState({
    users: [],
  });
  const editor = useRef(null);
  const [deleteData, setDeleteData] = useState(null);

  const formik = useFormik({
    initialValues: {
      ...data,
      ownerId: data?.ownerId
        ? data.ownerId
        : getSecureToken(Constants.KEY_USER_DATA),
    },
    onSubmit: (values) => {
      if (id) {
        update(values);
      } else {
        create(values);
      }
    },
  });
  const [localContent, setLocalContent] = useState(formik.values.terms);

  const getTermData = () => {
    try {
      let uri = url.TERM_CONDITION + id;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.data);
            setData(response?.result?.data);
            setLocalContent(response?.result?.data?.terms);
          } else {
            setIsLoading(false);
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" Function Error", error);
    }
  };

  const getOwner = () => {
    try {
      let uri = url.USERS;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              users: response?.result?.users,
            }));
          } else {
            setIsLoading(false);
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" Function Error", error);
    }
  };

  //   const handleInputChange = (event, newInputValue, reason) => {
  //     if (reason === "input") {
  //       if (newInputValue?.length >= 3) {
  //         getAccount(newInputValue);
  //       } else {
  //         setOptions((prev) => ({ ...prev, account: [] }));
  //       }
  //     }
  //   };

  const update = (values) => {
    try {
      let uri = url.TERM_CONDITION + id;
      let body = {
        ...values,
      };

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);
            setAddEditOpen(false);
            if (setRecallState) {
              setRecallState(true);
            }
            Toastr("Term Condition Updated Successfully", Constants.SUCCESS);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };
  const create = (values) => {
    try {
      let uri = url.TERM_CONDITION;
      let body = {
        ...values,
      };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("Term Condition Added Successfully", Constants.SUCCESS);
            setAddEditOpen(false);
            setRecallState(true);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: data?.name });
  };

  useEffect(() => {
    if (id && !data) {
      getTermData();
    }
    getOwner();
  }, []);
  const permission = getObjectPermissions()?.["term-condition"];

  return (
    <>
      <DeleteModal
        dataUrl={url.TERM_CONDITION}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        //    callFunc={addFilter}
        navLink={"/termCondition-list"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          py: 2,
        }}>
        <Typography
          component='div'
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}>
          Term & Comdition
        </Typography>
        {/* <Typography variant="h6">Account Information</Typography> */}
        {permission !== undefined && permission[0] >= 3 && (
          <>
            <Typography
              component='span'
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}>
              <IconButton
                size='small'
                onClick={() => {
                  setAddEditOpen((prev) => !prev);
                  if (location?.pathname === "/termCondition-list") {
                    setData(null);
                    setId(null);
                  }
                }}
                sx={{
                  p: 0.95,
                  borderRadius: "5px",
                  border: "1px solid red",
                }}>
                <CrossIcon width='2' height='2' />
              </IconButton>

              <IconButton
                size='small'
                color='green'
                onClick={() => {
                  formik.handleSubmit();
                  // if (location?.pathname === "/termCondition-list") {
                  //   setData(null);
                  //   setId(null);
                  // }
                }}
                sx={{
                  p: 0,
                  borderRadius: "5px",
                  border: "1px solid green",
                }}>
                <BookmarkBorderOutlined
                  sx={{ color: "green", height: "16px" }}
                />
              </IconButton>

              {id && (
                <IconButton
                  size='small'
                  // color="primary"
                  onClick={handleOpenDelete}
                  sx={{
                    p: 0,
                    borderRadius: "5px",
                    border: "1px solid #4680FF ",
                  }}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          m: 0,
          p: 0,
          maxHeight: "65vh",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}>
        <form onSubmit={formik.handleSubmit}>
          <InformationCard title='Core Details' color='red'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography
                color='secondary'
                sx={{
                  p: 0,
                  fontSize: "12px",
                }}
                variant='h6'>
                TermCondition Name
              </Typography>
              <TextField
                name='name'
                value={formik?.values?.name}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Term Name'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
              {/* <Typography
                variant="body2"
                component="div"
                sx={{
                  p: 0,
                  color: "#1E1E1E",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {formik?.values?.name || "-"}
              </Typography> */}
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography
                color='secondary'
                sx={{
                  p: 0,
                  fontSize: "12px",
                }}>
                Owner Name
              </Typography>
              <Autocomplete
                id='ownerId'
                name='ownerId'
                options={options?.users || []}
                getOptionLabel={(option) => option?.fullName}
                value={
                  typeof formik.values?.ownerId === "object"
                    ? {
                        fullName: formik.values.ownerId?.fullName || "",
                        _id: formik.values?.ownerId?._id || "",
                      }
                    : options?.users?.find(
                        (option) => option?._id === formik.values?.ownerId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "ownerId",
                    newValue ? newValue?._id : ""
                  );
                }}
                onBlur={formik.handleBlur}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </InformationCard>
          <InformationCard title='General Detail' color='blue'>
            <Grid item xs={12} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography
                // component="div"
                color='secondary'
                sx={{
                  p: 0,
                  fontSize: "12px",
                }}>
                Terms
              </Typography>

              <JoditEditor
                ref={editor}
                value={localContent}
                onBlur={() => formik.setFieldTouched("terms", true)}
                onChange={(content) => formik.setFieldValue("terms", content)}
              />
            </Grid>
          </InformationCard>
        </form>
      </Box>
    </>
  );
};

export default AddEditTermCondition;

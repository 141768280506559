import { useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Box,
  Stack,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { Toastr } from "components/Toastr";
import { Formik, useFormik } from "formik";
import { useLocation } from "react-router";
import { ApiServices } from "services/apiServices";
import { Constants } from "services/keyConstants";
import * as url from "services/url_helper";
import InformationCard from "components/InformationCard";
import CustomDatePicker from "components/CustomDatePicker";
import { Colors } from "services/colorConstant";
import { helperFunction } from "services/helperFunction";
import {
  BookmarkBorderOutlined,
  Close as CrossIcon,
} from "@mui/icons-material";
import JoditEditor from "jodit-react";
import { dataStyle, titleStyle } from "services/commonStyles";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import { getObjectPermissions, getSecureToken } from "services/utils";
import DeleteModal from "components/DeleteModal";
import * as Yup from "yup";

const gstOption = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const permission = getObjectPermissions()?.proposal;
const AddEditProposal = ({
  id = null,
  data,
  setData,
  setAddEditOpen,
  addEditOpen,
  setId,
  setRecallState,
  parentModel,
  parentData,
}) => {
  const editor = useRef(null);
  const [user, setUser] = useState([]);
  const [deleteData, setDeleteData] = useState(null);
  const location = useLocation();
  const [options, setOptions] = useState({
    opportunity: [],
    serviceBrief: [],
    contacts: [],
    termCondtion: [],
  });

  const validationSchema = Yup.object({
    opportunityId: Yup.mixed().test(
      "opportunityId-validation",
      "Opportunity Name is required",
      (value) => {
        if (!value) return false;
        if (typeof value === "string" && value.trim() !== "") return true;
        if (typeof value === "object" && value._id) return true;
        return false;
      }
    ),
    date: Yup.string().trim().required("Date is required"),
  });

  const formik = useFormik({
    initialValues: {
      ...data,
      opportunityId: data?.opportunityId || "",
      date: data?.date ? data?.date : new Date(),
      validTill: data?.validTill
        ? data?.validTill
        : new Date(new Date().setMonth(new Date().getMonth() + 1)),
      ownerId: data?.ownerId
        ? data?.ownerId
        : getSecureToken(Constants.KEY_USER_DATA),
    },
    validationSchema,
    onSubmit: (values) => {
      if (id) {
        const updatedValues = helperFunction.getUpdatedValues(values, data);
        updateProposal(updatedValues);
      } else {
        create(values);
      }
    },
  });
  const [localContent, setLocalContent] = useState(formik.values.scope);

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: data?.name });
  };

  const handleInputChange = (event, newInputValue, reason) => {
    if (reason === "input") {
      if (newInputValue?.length >= 3) {
        opportunityData(newInputValue);
      } else {
        setOptions((prevData) => ({
          ...prevData,
          opportunity: [],
        }));
      }
    }
  };

  const userData = () => {
    try {
      let uri = url.USERS;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setUser(response?.result?.users);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const opportunityData = (query) => {
    try {
      let uri = url.OPPORTUNITY + `name/${query}`;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prevState) => ({
              ...prevState,
              opportunity: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const opportunityDataById = (opportunityId) => {
    try {
      let uri = url.OPPORTUNITY + opportunityId;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setFieldValue(
              "accountId",
              response?.result?.data?.accountId
                ? response?.result?.data?.accountId
                : ""
            );
            const accountId = response?.result?.data?.accountId?._id;
            getContact(accountId);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const getTCData = () => {
    try {
      let uri = url.TERM_CONDITION;
      ApiServices.callServiceGet(uri, "")
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prevState) => ({
              ...prevState,
              termCondtion: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" Function Error", error);
    }
  };

  const getSBData = () => {
    try {
      let uri = url.SERVICE_BRIEF;
      ApiServices.callServiceGet(uri, "")
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prevState) => ({
              ...prevState,
              serviceBrief: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" Function Error", error);
    }
  };

  const getContact = (accountId) => {
    try {
      let uri = url.CONTACT + `?accountId=` + accountId;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              contacts: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const updateProposal = (values) => {
    try {
      let uri = url.PROPOSAL + id;
      let body = {
        ...values,
      };

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("Proposal Updated Successfully", Constants.SUCCESS);
            if (setRecallState) {
              setRecallState(true);
            }
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const proposalData = () => {
    try {
      let uri = url.PROPOSAL + id;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.data);
            setData(response?.result?.data);
            setLocalContent(response?.result?.data?.scope);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const create = (values) => {
    try {
      let uri = url.ADDPROPOSAL;
      let body = {
        ...values,
      };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("Proposal Added Successfully", Constants.SUCCESS);

            if (setRecallState) {
              setRecallState(true);
            }

            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  useEffect(() => {
    userData();
    if (id && !data) {
      proposalData();
    }

    if (formik?.values?.opportunityId) {
      opportunityDataById(formik?.values?.opportunityId?._id);
    }
    if (parentModel) {
      if (parentModel == "account") {
        formik.setFieldValue("accountId", parentData);
        opportunityData(`getallvalues?${parentModel}Id=${parentData?._id}`);
      } else if (parentModel == "opportunity") {
        formik.setFieldValue("opportunityId", parentData);
        formik.setFieldValue("accountId", parentData?.accountId);
      } else if (parentData?.opportunityId) {
        formik.setFieldValue("opportunityId", parentData?.opportunityId);
        formik.setFieldValue("accountId", parentData?.accountId);
        // } else if (parentData?.accountId) {
        //   formik.setFieldValue("accountId", parentData?.accountId);
      } else {
        opportunityData(`getallvalues?${parentModel}Id=${parentData?._id}`);
      }
    }
    const promises = [getSBData(), getTCData()];
    Promise.all(promises);
  }, []);

  return (
    <>
      <DeleteModal
        dataUrl={url.PROPOSAL}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        //    callFunc={addFilter}
        navLink={"/proposal-list"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          py: 2,
        }}>
        <Typography
          component='div'
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}>
          Proposal Information
        </Typography>
        <>
          <Typography
            component='span'
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
            }}>
            <IconButton
              size='small'
              onClick={() => {
                setAddEditOpen((prev) => !prev);
                if (location?.pathname === "/proposal-list") {
                  setData(null);
                  setId(null);
                }
              }}
              sx={{
                p: 0.95,
                borderRadius: "5px",
                border: `1px solid ${Colors.DANGER_COLOR}`,
                color: `${Colors.DANGER_COLOR}`,
              }}>
              <CrossIcon width='2' height='2' />
            </IconButton>

            <IconButton
              size='small'
              onClick={() => formik.handleSubmit()}
              sx={{
                p: 0,
                borderRadius: "5px",
                border: `1px solid ${Colors.SUCCESS_COLOR}`,
                color: `${Colors.SUCCESS_COLOR}`,
              }}>
              <BookmarkBorderOutlined sx={{ height: "16px" }} />
            </IconButton>

            {id && permission !== undefined && permission[0] >= 3 && (
              <IconButton
                size='small'
                onClick={handleOpenDelete}
                sx={{
                  p: 0,
                  borderRadius: "5px",
                  border: `1px solid ${Colors.PRIMARY_COLOR} `,
                  color: `${Colors.PRIMARY_COLOR}`,
                }}>
                <DeleteIcon />
              </IconButton>
            )}
          </Typography>
        </>
      </Box>
      <Box
        sx={{
          m: 0,
          p: 0,
          maxHeight: "65vh",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}>
        <form onSubmit={formik.handleSubmit}>
          <InformationCard title='Core Details' color={Colors.DANGER_COLOR}>
            <Grid item xs={12} sm={6} sx={{ pl: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Proposal Name</Typography>
              <Typography {...dataStyle}>
                {formik?.values?.name || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Proposal Owner</Typography>
              <Autocomplete
                id='ownerId'
                name='ownerId'
                options={user}
                getOptionLabel={(option) => option?.fullName}
                value={
                  typeof formik?.values?.ownerId === "object"
                    ? {
                        fullName: formik?.values?.ownerId?.fullName || "",
                        _id: formik?.values?.ownerId?._id || "",
                      }
                    : user.find(
                        (option) => option._id === formik.values?.ownerId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "ownerId",
                    newValue ? newValue?._id : ""
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select Proposal Owner'
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                        mb: 1,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Opportunity Name{" "}
                <Typography variant='span' color='red'>
                  *
                </Typography>
              </Typography>

              <Autocomplete
                id='opportunityId'
                name='opportunityId'
                placeholder={formik.values.opportunityId}
                options={options?.opportunity || []}
                getOptionLabel={(option) => option?.name || ""}
                value={
                  typeof formik?.values?.opportunityId === "object"
                    ? formik?.values?.opportunityId
                    : options?.opportunity?.find(
                        (option) => option?._id === formik.values?.opportunityId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "opportunityId",
                    newValue ? newValue?._id : ""
                  );
                  if (newValue?._id) {
                    opportunityDataById(newValue._id);
                  }
                }}
                onInputChange={handleInputChange}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select Opportunity Name'
                    error={
                      formik.touched?.opportunityId &&
                      Boolean(formik.errors?.opportunityId)
                    }
                    helperText={
                      formik.touched?.opportunityId &&
                      formik.errors?.opportunityId
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                        mb: 1,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography
                color='secondary'
                sx={{
                  p: 0,
                  mt: 1,
                  fontSize: "12px",
                }}>
                Contact Name
              </Typography>
              <Autocomplete
                id='contactId'
                name='contactId'
                options={options.contacts || []}
                getOptionLabel={(option) => option?.fullName}
                value={
                  typeof formik?.values?.contactId === "object"
                    ? formik?.values?.contactId
                    : options?.contacts?.find(
                        (option) => option._id === formik?.values?.contactId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "contactId",
                    newValue ? newValue?._id : ""
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select Contact Name'
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography
                color='secondary'
                sx={{
                  p: 0,
                  fontSize: "12px",
                  mt: 1,
                }}>
                Account Name
              </Typography>
              <Typography {...dataStyle}>
                {formik?.values?.accountId?.name || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle} variant='h6'>
                Heading
              </Typography>
              <TextField
                name='heading'
                value={formik?.values?.heading}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Heading'
                InputProps={{
                  sx: {
                    height: "41px",
                    mb: 1,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Total Proposal Amount</Typography>
              {/* <TextField
                name="totalProposalAmount"
                type="number"
                value={formik?.values?.totalProposalAmount}
                onChange={formik.handleChange}
                onWheel={(e) => e.target.blur()}
                fullWidth
                variant="outlined"
                placeholder="Enter totalProposalAmount"
                InputProps={{
                  sx: {
                    height: "41px",
                    mb: 1,
                  },
                }}
              /> */}
              <Typography {...dataStyle}>
                {helperFunction.amountFormat(data?.totalProposalAmount) || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Currency</Typography>
              <Typography {...dataStyle}>
                {data?.currencyId?.isoCode || "-"}
              </Typography>
            </Grid>
          </InformationCard>

          <InformationCard title='General Details' color='#0000FF'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Date{" "}
                <Typography variant='span' color='red'>
                  *
                </Typography>
              </Typography>
              <CustomDatePicker
                value={formik?.values?.date}
                onChange={(date) => {
                  formik?.setFieldValue("date", date);
                  formik?.setFieldValue(
                    "validTill",
                    new Date(date).setMonth(date.getMonth() + 1)
                  );
                }}
                // skipDay={0}
                error={formik.touched?.date && Boolean(formik.errors?.date)}
                helperText={formik.touched?.date && formik.errors?.date}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>valid Till</Typography>

              <CustomDatePicker
                // skipDay={30}
                value={formik?.values?.validTill}
                onChange={(date) => formik.setFieldValue("validTill", date)}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography
                color='secondary'
                sx={{
                  p: 0,
                  fontSize: "12px",
                  mt: 1,
                }}>
                Terms & Condition
              </Typography>
              <Autocomplete
                id='termCondtionId'
                name='termCondtionId'
                options={options?.termCondtion || []}
                getOptionLabel={(option) => option?.name}
                value={
                  typeof formik?.values?.termCondtionId === "object"
                    ? {
                        name: formik?.values?.termCondtionId?.name || "",
                        _id: formik?.values?.termCondtionId?._id || "",
                      }
                    : options?.termCondtion?.find(
                        (option) =>
                          option._id === formik?.values?.termCondtionId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik?.setFieldValue(
                    "termCondtionId",
                    newValue ? newValue?._id : ""
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                onBlur={formik?.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select Terms & Condition'
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Service Brief</Typography>
              <Autocomplete
                id='serviceBriefId'
                name='serviceBriefId'
                getOptionLabel={(option) => option?.name || ""}
                options={options.serviceBrief}
                value={
                  typeof formik.values.serviceBriefId === "object"
                    ? formik?.values?.serviceBriefId
                    : options?.serviceBrief?.find(
                        (option) => option._id === formik.values?.serviceBriefId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik?.setFieldValue(
                    "serviceBriefId",
                    newValue ? newValue._id : ""
                  );
                }}
                onBlur={formik?.handleBlur}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select Service Brief'
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                        mb: 1,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>GST Applicable</Typography>
              <Autocomplete
                id='gstApplicable'
                name='gstApplicable'
                getOptionLabel={(option) => option?.label || ""}
                options={gstOption}
                value={
                  gstOption.find(
                    (option) =>
                      option.value === (formik.values?.gstApplicable || "No")
                  ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "gstApplicable",
                    newValue ? newValue.value : ""
                  );
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                        mb: 1,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Version</Typography>
              <TextField
                name='version'
                type='number'
                value={formik?.values?.version}
                onChange={formik.handleChange}
                onWheel={(e) => e.target.blur()}
                fullWidth
                variant='outlined'
                placeholder='Enter Enquiry For'
                InputProps={{
                  sx: {
                    height: "41px",
                    mb: 1,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Category</Typography>
              <Typography {...dataStyle}>
                {formik?.values?.opportunityId?.enquiryCategory || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Category Type</Typography>
              <Typography {...dataStyle}>
                {formik?.values?.opportunityId?.enquiryFor || "-"}
              </Typography>
            </Grid>
          </InformationCard>
          <InformationCard title='Scope' color='green'>
            <Grid item xs={12} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Scope</Typography>

              <JoditEditor
                ref={editor}
                value={localContent}
                onBlur={() => formik.setFieldTouched("scope", true)}
                onChange={(content) => formik.setFieldValue("scope", content)}
              />
            </Grid>
          </InformationCard>
          <InformationCard title='Other Details' color='#EDAD4D'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Subcontractor</Typography>
              <TextField
                name='subContractor'
                value={formik?.values?.subContractor}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Subcontractor'
                InputProps={{
                  sx: {
                    height: "41px",
                    mb: 1,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Out Source Amount</Typography>
              <TextField
                name='outSourceAmount'
                type='number'
                value={formik?.values?.outSourceAmount}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Out Source Amount'
                onWheel={(e) => e.target.blur()}
                InputProps={{
                  sx: {
                    height: "41px",
                    mb: 1,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Notes</Typography>
              <TextField
                name='notes'
                value={formik?.values?.notes}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Notes'
                InputProps={{
                  sx: {
                    height: "41px",
                    mb: 1,
                  },
                }}
              />
            </Grid>
          </InformationCard>

          {/* <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">Created By</Typography>
          <TextField
            name="createdBy.fullName"
            value={
              formik?.values?.createdBy?.fullName +
              "," +
              formik?.values?.createdAt
            }
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="body1">Last Modified By</Typography>
          <TextField
            name="lastModifiedBy.fullName"
            value={
              formik?.values?.lastModifiedBy?.fullName ||
              formik?.values?.createdBy?.fullName +
                "," +
                formik?.values?.updatedAt
            }
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
      </Grid> */}
        </form>
      </Box>
    </>
  );
};
export default AddEditProposal;

import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Box,
  IconButton,
} from "@mui/material";
import { Toastr } from "components/Toastr";
import { useFormik } from "formik";
import { ApiServices } from "services/apiServices";
import * as url from "services/url_helper";
import { Okb } from "iconsax-react";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import { ReactComponent as CrossIcon } from "../../../assets/button_svg/Vector.svg";
import { BookmarkBorderOutlined } from "@mui/icons-material";
import InformationCard from "components/InformationCard";
import {
  boxStyle,
  typographyStyle,
  titleStyle,
  spaceStyle,
  mainBoxStyle,
} from "services/commonStyles";
import { Constants } from "services/keyConstants";
import { getObjectPermissions, getSecureToken } from "services/utils";
import { helperFunction } from "services/helperFunction";
import DeleteModal from "components/DeleteModal";
import * as Yup from "yup";
import { Colors } from "services/colorConstant";

const productCategoryOption = [
  "Active Device",
  "Medical Devices",
  "In Vitro Diagnostics Devices",
  "Non - Medical Devices",
  "Personal Protective Equipment",
  "Others",
];
const typeOfCustomerOption = ["B2C", "SEZWOP", "NA"];
const accountTypeOption = [
  "Customer",
  "Prospective Data",
  "Notified Body",
  "Suppliers",
  "Business Agent",
  "EAR",
  "CRO",
  "UKRP",
  "Test Lab",
  "Country Agents",
  "Certification Body",
  "Other",
];

const AddEditAccount = ({
  id = null,
  data,
  setData,
  setAddEditOpen,
  setId,
  setRecallState,
}) => {
  const [user, setUser] = useState([]);
  const [deleteData, setDeleteData] = useState(null);
  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Account Name is required"),
  });
  const formik = useFormik({
    initialValues: {
      ...data,
      name: data?.name || "",
      ownerId: data?.ownerId
        ? data.ownerId
        : getSecureToken(Constants.KEY_USER_DATA),
    },
    validationSchema,

    onSubmit: (values) => {
      if (id) {
        const updatedValues = helperFunction.getUpdatedValues(values, data);
        update(updatedValues);
      } else {
        create(values);
      }
    },
  });

  const userData = () => {
    try {
      let uri = url.USERS;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setUser(response?.result?.users);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };
  const accountData = () => {
    try {
      let uri = url.ACCOUNT + id;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.data);
            setData(response?.result?.data);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const update = (values) => {
    try {
      let uri = url.ACCOUNT + id;
      let body = {
        ...values,
      };

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("Account Updated Successfully", Constants.SUCCESS);
            if (setRecallState) {
              setRecallState(true);
            }
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };
  const create = (values) => {
    try {
      let uri = url.ACCOUNT;
      let body = {
        ...values,
      };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("Account Added Successfully", Constants.SUCCESS);
            if (setRecallState) {
              setRecallState(true);
            }
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: data?.name });
  };

  const permission = getObjectPermissions().account;
  useEffect(() => {
    if (id && !data) {
      accountData();
    }
    userData();
  }, []);

  return (
    <>
      <DeleteModal
        dataUrl={url.ACCOUNT}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        //    callFunc={addFilter}
        navLink={"/account-list"}
      />
      <Box sx={boxStyle}>
        <Typography
          component='div'
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}>
          Account Information
        </Typography>
        {/* {permission !== undefined && permission[0] >= 1 && ( */}
        <>
          <Typography {...typographyStyle}>
            <IconButton
              size='small'
              onClick={() => {
                if (location?.pathname === "/account-list") {
                  setData(null);
                  setId(null);
                }
                setAddEditOpen((prev) => !prev);
              }}
              sx={{
                p: 0.95,
                borderRadius: "5px",
                border: `1px solid ${Colors.DANGER_COLOR}`,
              }}>
              <CrossIcon width='2' height='2' />
            </IconButton>

            <IconButton
              size='small'
              color={Colors.SUCCESS_COLOR}
              onClick={() => formik.handleSubmit()}
              sx={{
                p: 0,
                borderRadius: "5px",
                border: `1px solid ${Colors.SUCCESS_COLOR}`,
              }}>
              <BookmarkBorderOutlined
                sx={{ color: Colors.SUCCESS_COLOR, height: "16px" }}
              />{" "}
            </IconButton>

            {id && permission !== undefined && permission[0] >= 3 && (
              <IconButton
                size='small'
                // color="primary"
                onClick={handleOpenDelete}
                sx={{
                  p: 0,
                  borderRadius: "5px",
                  border: `1px solid ${Colors.PRIMARY_COLOR} `,
                }}>
                <DeleteIcon />
              </IconButton>
            )}
          </Typography>
        </>
        {/* )} */}
      </Box>
      <Box sx={mainBoxStyle}>
        <form onSubmit={formik.handleSubmit}>
          <InformationCard title='Core Details' color='red'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Account Name
                <Typography variant='span' color='red'>
                  *
                </Typography>
              </Typography>

              <TextField
                name='name'
                value={formik?.values?.name}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Account Name'
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />

              <Typography {...titleStyle}>Product</Typography>
              <TextField
                name='product'
                value={formik?.values?.product}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Product'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Account Owner</Typography>

              <Autocomplete
                id='ownerId'
                name='ownerId'
                options={user}
                getOptionLabel={(option) => option?.fullName}
                value={
                  typeof formik.values.ownerId === "object"
                    ? {
                        fullName: formik.values.ownerId?.fullName || "",
                        _id: formik.values.ownerId?._id || "",
                      }
                    : user.find(
                        (option) => option._id === formik.values?.ownerId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "ownerId",
                    newValue ? newValue?._id : ""
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select customer type'
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
              {formik.touched.name && formik.errors.name && (
                <Typography variant='span' {...spaceStyle}>
                  &nbsp;
                </Typography>
              )}
              <Typography {...titleStyle}>Type of Customer</Typography>

              <Autocomplete
                id='typeOfCustomer'
                name='typeOfCustomer'
                placeholder=''
                options={typeOfCustomerOption}
                value={
                  typeof formik.values.typeOfCustomer === "string"
                    ? {
                        label: formik.values?.typeOfCustomer || "",
                        value: formik.values?.typeOfCustomer || "",
                      }
                    : typeOfCustomerOption.find(
                        (option) => option === formik.values?.typeOfCustomer
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "typeOfCustomer",
                    newValue ? newValue : ""
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select Type of Customer'
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </InformationCard>

          <InformationCard title='General Details' color='#0000FF'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Website</Typography>
              <TextField
                name='website'
                value={formik?.values?.website}
                onChange={formik.handleChange}
                placeholder='Enter Website'
                variant='outlined'
                fullWidth
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />

              <Typography {...titleStyle}>GST</Typography>
              <TextField
                name='gst'
                value={formik?.values?.gst}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter GST'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Product Category</Typography>

              <Autocomplete
                id='productCategory'
                name='productCategory'
                options={productCategoryOption}
                value={
                  typeof formik.values.productCategory === "string"
                    ? {
                        label: formik.values?.productCategory || "",
                        value: formik.values?.productCategory || "",
                      }
                    : productCategoryOption.find(
                        (option) => option === formik.values?.productCategory
                      ) || null
                }
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "productCategory",
                    newValue ? newValue : ""
                  );
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select Product Category'
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />

              <Typography {...titleStyle}> Account Type</Typography>

              <Autocomplete
                id='accountType'
                name='accountType'
                options={accountTypeOption}
                value={
                  typeof formik.values.accountType === "string"
                    ? {
                        label: formik.values?.accountType || "",
                        value: formik.values?.accountType || "",
                      }
                    : accountTypeOption.find(
                        (option) => option === formik.values?.accountType
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue("accountType", newValue ? newValue : "");
                }}
                onBlur={formik.handleBlur}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select Account Type'
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                    fullWidth
                    variant='outlined'
                  />
                )}
              />
            </Grid>
          </InformationCard>

          <InformationCard title='Other Details' color='#EDAD4D'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Contact Email</Typography>
              <TextField
                name='email'
                value={formik?.values?.email}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter email'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />

              <Typography {...titleStyle}>Account Source</Typography>
              <TextField
                name='accountSource'
                value={formik?.values?.accountSource}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Account Source'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />

              <Typography {...titleStyle}> Services</Typography>
              <Typography
                color='secondary'
                sx={{
                  p: 0,
                  fontSize: "12px",
                  mt: 1,
                  marginBottom: "22px",
                }}>
                {accountData?.services?.length > 0
                  ? accountData?.services?.join(", ")
                  : "-"}
              </Typography>
              <Typography {...titleStyle}> Address Street</Typography>
              <TextField
                name='billingStreet'
                value={formik?.values?.billingStreet}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Address Street'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />

              <Typography {...titleStyle}> Address State</Typography>
              <TextField
                name='billingState'
                value={formik?.values?.billingState}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Address State'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />

              <Typography {...titleStyle}> Address PostalCode</Typography>
              <TextField
                name='billingPostalCode'
                value={formik?.values?.billingPostalCode}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Address PostalCode'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Contact Number</Typography>
              <TextField
                name='contactNumber'
                value={formik?.values?.contactNumber}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Contact Number'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />

              <Typography {...titleStyle}> Source Detail</Typography>
              <TextField
                name='sourceDetails'
                value={formik?.values?.sourceDetails}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Source Detail'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />

              <Typography {...titleStyle}> Description</Typography>
              <TextField
                fullWidth
                variant='outlined'
                placeholder='Enter Description'
                name='description'
                value={formik?.values?.description}
                onChange={formik.handleChange}
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />

              <Typography {...titleStyle}> Address City</Typography>
              <TextField
                name='billingCity'
                value={formik?.values?.billingCity}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Address City'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />

              <Typography {...titleStyle}> Address Country</Typography>
              <TextField
                name='billingCountry'
                value={formik?.values?.billingCountry}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Address Country'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
          </InformationCard>
        </form>
      </Box>
    </>
  );
};
export default AddEditAccount;

export const Colors = {
  DEFAULT_COLOR: "#FFFFFF",
  PRIMARY_LIGHT_COLOR: "#E9F0FF",
  PRIMARY_MEDIUM_COLOR: "#007BFF",
  PRIMARY_COLOR: "#4680FF",
  SUCCESS_COLOR: "#2CA87F",
  SUCCESS_LIGHT_COLOR: "#E3EEEA",
  DANGER_COLOR: "#FF0000",
  DANGER_LIGHT_COLOR: "#F6E9E9",
  CARD_ICONCOLOR: "#7EA6FF",
  CARD_BORDERCOLOR: "#7EA7FF",
};

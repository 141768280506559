//auth
export const LOGIN = "/auth/login";
export const GET_USER_LOGIN_DETAILS = "/crm/permission";

// Permissions

export const ADD_USER_WITH_PERMISSION = "/crm/permission/add-update/";
export const GET_USER_WITH_PERMISSION_BY_ID = "/crm/permission/user/";


//All Permission
export const GIVE_ALL_PERMISSION = "/allPermissions";
export const GET_ALL_PERMISSION = "/allPermissions/by-userId";

//User Profile
export const UPDATE_PROFILE = "/users/master-update-user/";
export const GET_PROFILE = "/users/";

//forgot password
export const RESET_PASSWORD = "/users/master-reset/";
export const SEND_MAIL = "/users/master-sendmail";

//Category
export const GET_ALL_CATEGORIES = "/category";
export const GET_CATEGORY_BY_ID = "/category/";
export const GET_CATEGORIES_TYPE = "/category/type";
export const CREATE_CATEGORY = "/category";
export const UPDATE_CATEGORY = "/category/";
export const DELETE_CATEGORY = "/category/";

//questions
export const CREATE_QUESTION = "/question";
export const UPDATE_QUESTION = "/question/";
export const CREATE_QUESTION_MCMC = "/question/mcmc";
export const GET_ALL_QUESTION = "/question";
export const GET_QUESTIONS_BY_CATEGORYID = "/question/parent";
export const GET_PARENT_QUESTION = "/question/list";
export const GET_SUB_QUESTION = "/question/sub";
export const GET_QUESTION_LIST = "/question/get-list";
export const GET_QUESTION_BY_ID = "/question/";

//choices
export const DELETE_CHOICE = "/choice/";
export const GET_CHOICES = "/choice/question";
export const GET_CHOICES_BY_QUESTION_ID = "/choice/question";

//product
export const GET_ALL_PRODUCTS = "/product";
export const CREATE_PRODUCT = "/product";
export const GET_PRODUCT_BY_ID = "/product/";
export const UPDATE_PRODUCT = "/product/";
export const DELETE_PRODUCT = "/product/";

//productCategory
export const GET_ALL_CATEGORIES_BY_PRODUCT_ID = "/product-category/list";

//productCategory

//template
export const CREATE_TEMPLATE = "/template";
export const GET_ALL_TEMPLATE = "/template";
export const GET_TEMPLATE_BY_ID = "/template/";
export const UPDATE_TEMPLATE = "/template/";
export const DELETE_TEMPLATE = "/template/";
// export const SUBMIT_ANSWER = "/template/demo";
export const SUBMIT_ANSWER = "/client/document/generate";

//client document
// export const GET_OUTPUT = "/client/document/demo";
export const GET_OUTPUT = "/client/document/view";

//constants
export const CONSTANT_CHECK = "/constant/check";
export const GET_CONSTANTS_BY_CATEGORY = "/constant/list";

//Clients
export const ADD_CLIENT = "/client/organization";
export const GET_ALL_CLIENT = "/client/organization";
export const GET_CLIENT_BY_ID = "/client/organization/";
export const UPDATE_CLIENT = "/client/organization/";
export const DELETE_CLIENT = "/client/organization/";

//Check Email
export const CHECK_EMAIL = "/client/users/duplicate";

// Assign product to client
export const ASSIGN_PRODUCT = "/client/product";
export const GET_ALL_ASSIGNED_PRODCUTS = "/client/product/view-all/";
export const CHANGE_PRODCUT_STATUS = "/client/product/edit/";

export const CREATE_USER = "/users/";

// Quality Manual
export const ADD_MANUAL = "/qmsSet/qualityManual";
export const UPDATE_MANUAL = "/qmsSet/qualityManual/update";
export const GET_ALL_MANUAL = "/qmsSet/qualityManual/get";
export const MANUAL_LIST = "/qmsSet/qualityManual";
export const ADD_STANDARD = "/standard/";
export const SAVE_MANUAL_HEADER = "/qmsSet/qualityManual/header";
export const GET_MANUAL_HEADER = "/qmsSet/qualityManual/header/";
export const UPDATE_QM = "/qmsSet/qualityManual/";
export const DOWNLOAD_MANUAL = "/qmsSet/qualityManual/download";

//Short Question
export const ADD_SHORT_QUESTION = "/docQuestions/";
export const UPDATE_SHORT_QUESTION = "/docQuestions/";
export const GET_ALL_NEW_QUESTIONS = "/docQuestions/";
export const DELETE_QUESTION = "/docQuestions/";

//Standard Question
export const GET_STD_QUESTION = "/docQuestions/by-standard";

//PROCEDURE NAME
export const GET_PROCEDURE_NAME = "/master-doc-module/get-procedures";

//users
export const USERS = "/users/";
export const GET_COMPANY_PRODUCT_NAME = "/auth/get-name";

//filters
export const FILTER_MODEL = "/crm/filter/model/";
export const SHARE_FILTER = "/crm/filter/share/";
export const Delete_FILTER = "/crm/filter/";
export const CRM = "/crm/";
export const FILTER = "/filter";
export const RELATED = "/related/";

//History
export const HISTORY = "/crm/history/";

//Accounts
export const ACCOUNT = "/crm/account/";

//Opportunity
export const OPPORTUNITY = "/crm/opportunity/";

//Payment Details
export const PAYMENT_DETAILS = "/crm/payment-detail/";

//Payment Distribution
export const PAYMENT_DISTRIBUTION = "/crm/payment-distribution/";

//currency
export const CURRENCY = "/crm/currency/";

//Proposal
export const PROPOSAL = "/crm/proposal/";
export const ADDPROPOSAL = "/crm/proposal";

//Pinvoice
export const PINVOICE = "/crm/p-invoice/";

//finvoice Line
export const FINVOICE_LINE = "/crm/f-invoice-line/";
//proposalLine
export const PROPOSALLINE = "/crm/proposal-line";
export const ADD_PROPOSALLINE = "/crm/proposal-line";
export const UPDATE_PROPOSALLINE = "/crm/proposal-line/";
export const DELETE_PROPOSALLINE = "/crm/proposal-line/";

//payment-term
export const PAYMENT_TERM = "/crm/payment-terms";
export const ADD_PROPOSAL_PAYMENT_TERM = "/crm/payment-terms/";
export const UPDATE_PROPOSAL_PAYMENT_TERM = "/crm/payment-terms/";
export const DELETE_PROPOSAL_PAYMENT_TERM = "/crm/payment-terms/";

//Scope Of Work
export const SCOPE_OF_WORK = "/crm/scope-of-work/";

//Other Estimate
export const OTHER_PROPOSAL = "/crm/other-proposal/";

//Accounts
export const CONTACT = "/crm/contact/";

//Finvoice
export const FINVOICE = "/crm/f-invoice/";

//TermCondition
export const TERM_CONDITION = "/crm/term-condition/";

//Service Brief
export const SERVICE_BRIEF = "/crm/service-brief/";

//RegTech Brief
export const REGTECH = "/crm/regtech";

//Follow up task
export const FOLLOW_UP_TASK = "/crm/follow-up-task/";
export const DELETE_RECURRING_FOLLOW_UP_TASK =
  "/crm/follow-up-task/all-recurring/";

export const PARENT_RELATED_DATA = "/crm/follow-up-task/related-to/";
export const DELETE_FOLLOW_UP_TASK = "/crm/follow-up-task/";
export const CUSTOM_FOLLOW_UP_DATA = "/crm/follow-up-task/user-custom/";
export const GET_RELATED_LIST_DATA = "/crm/follow-up-task/list-coll/";

//PInvoice Line

export const PINVOICE_LINE = "/crm/p-invoice-line/";


//Notes

export const NOTES = "/crm/note/";
export const NOTES_PARENT_RELATED_DATA = "/crm/note/related-to/";

// Bank
export const BANK = "/crm/bank/";

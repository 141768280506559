import { Card, CardContent, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Edit, Edit2, Eye, Polkadot } from "iconsax-react";
import React, { useEffect, useState } from "react";
import CustomLink from "../CustomLink";
import bg from "../../assets/bg-images/bg.svg";
import { Colors } from "services/colorConstant";
import * as url from "services/url_helper";
import { ApiServices } from "services/apiServices";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
} from "@mui/lab";
import { helperFunction } from "services/helperFunction";
import AddEditFollowUp from "pages/CRM/FollowUpTask/AddEditFollowUp";
const FollowUpCard = ({ setAll, parentData, parentModel }) => {
  const [followUps, setFollowUps] = useState([]);
  const [data, setData] = useState(null);
  const [addEditOpen, setAddEditOpen] = useState(false);
  const [recallState, setRecallState] = useState(false);
  const [id, setId] = useState(null);

  const getParentData = () => {
    try {
      let uri = url.PARENT_RELATED_DATA + parentData?._id;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            // setIsLoading(false);
            setFollowUps(response?.result?.data);
          } else {
            setIsLoading(false);

            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" Function Error", error);
    }
  };

  useEffect(() => {
    getParentData();
  }, []);
  useEffect(() => {
    if (recallState) {
      getParentData();
      setRecallState(false);
    }
  }, [recallState]);

  const CustomTimelineDot = ({ index }) => (
    <Box
      sx={{
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset",
        backgroundColor: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}>
      <Box
        sx={{
          width: "60%",
          height: "60%",
          borderRadius: "50%",
          backgroundColor: "primary.main",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}>
        {index != 0 && (
          <Box
            sx={{
              width: "60%",
              height: "60%",
              borderRadius: "50%",
              backgroundColor: "white",
              position: "absolute",
            }}
          />
        )}
      </Box>
    </Box>
  );
  return (
    <Card
      sx={{
        maxWidth: 345,
        // marginBottom: 2,
        minHeight: "210px",
        minWidth: "100%",
        boxSizing: "content-box",
        border: "3px solid #D0DFFF",
        borderRadius: "16px",
        backgroundRepeat: "no-repeat",
        padding: "0px",
        margin: "0px",
      }}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          textAlign: "center",
          height: "60px",
          px: "20px",
          backgroundColor: "inherit",
          background: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "110%",
          backgroundPositionX: "-9px,0px",
          border: "none",
        }}>
        <Box display='flex' alignItems='center'>
          <Typography variant='h5' fontWeight='500'>
            Follow Up Task
          </Typography>

          <Typography
            sx={{
              marginLeft: 1,
              fontWeight: "bold",
              backgroundColor: Colors.CARD_ICONCOLOR,
              borderRadius: "3.27px",
              height: "18px",
              width: "18px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // fontSize: "12px",
              // color: "#353535",
            }}>
            {followUps?.count}
          </Typography>
        </Box>

        <Box display='flex' alignItems='center'>
          <Typography
            sx={{
              px: 0.5,
              // marginLeft: 1,
              fontWeight: "bold",
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              // border: "0.5px solid #C8D9FF",
              height: "20px",
              // width: "48px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "10px",
              fontWeight: 400,
              color: "#4680FF",
              cursor: "pointer",
            }}
            onClick={() => {
              setAll((prev) => ({ ...prev, followUp: false }));
            }}>
            View Less
          </Typography>
          <Typography
            sx={{
              marginLeft: 1,
              fontWeight: "bold",
              backgroundColor: Colors.CARD_ICONCOLOR,
              borderRadius: "50%",
              height: "18px",
              width: "18px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // fontSize: "16px",
              // color: "#353535",
              cursor: "pointer",
            }}
            onClick={() => {
              setId(null);
              setData(null);
              setAddEditOpen(true);
            }}>
            +
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          maxHeight: "68vh",
          overflowY: "scroll",
          scrollbarWidth: "none",
        }}>
        {followUps?.data?.length > 0 &&
          followUps?.data?.map((followUp, index) => {
            return (
              <CardContent
                sx={{
                  //   p: 0,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  px: "20px",
                  py: "7px",
                  backgroundColor: "white",

                  //   height: "auto",
                }}
                key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "30px",
                    borderBottom: "1px solid rgb(173, 198, 252)",
                  }}>
                  <Typography
                    variant='body2'
                    sx={{
                      fontWeight: 400,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "250px",
                    }}>
                    {followUp?.month}
                  </Typography>

                  {/* <Box sx={{ display: "flex", gap: 1 }}>
            <IconButton
              variant="outlined"
              color="primary"
              sx={{
                minWidth: "28px",
                width: "30px",
                height: "24px",
                padding: "10px",
                borderRadius: "4px",
                border: "0.3px solid rgb(169, 195, 251)",
              }}
              //   onClick={() => {
              //     setAddEditOpen(true);
              //     setRelatedModel(d?.for);
              //     setRelatedId(item?._id);
              //   }}
            >
              <Eye color="black" variant="Bold" />
            </IconButton>
          </Box> */}
                </Box>
                <Timeline
                  sx={{
                    padding: 0,
                    margin: 0,
                    "& .MuiTimelineItem-root": {
                      paddingLeft: 0,
                    },
                    "& .MuiTimelineContent-root": {
                      padding: 0,
                      marginLeft: "8px",
                    },
                    "& .MuiTimelineItem-missingOppositeContent:before": {
                      display: "none",
                    },
                  }}>
                  {followUp?.value?.map((follow, index) => (
                    <TimelineItem key={index} position='right'>
                      <TimelineSeparator>
                        <CustomTimelineDot index={index} />
                        {index !== followUp?.value?.length - 1 && (
                          <TimelineConnector
                            sx={{
                              backgroundColor: "primary.main",
                              width: 4,
                              boxShadow:
                                "0.5px 0px 2px 0px rgba(0, 0, 0, 0.25) inset",
                            }}
                          />
                        )}
                      </TimelineSeparator>
                      <TimelineContent
                        sx={{
                          mt: "0",
                          display: "flex",
                          alignSelf: "flex-start",
                          flexDirection: "column",
                        }}>
                        <Box
                          display='flex'
                          alignItems='center'
                          justifyContent='space-between'>
                          <CustomLink
                            to={`/followUpTask?relatedTo=${parentData?._id}&followupId=${follow?._id}`}>
                            <Typography color='primary'>
                              {follow?.subject}-{" "}
                              {helperFunction.dateFormat(follow?.activityDate)}
                            </Typography>
                          </CustomLink>
                          <IconButton
                            size='small'
                            variant='outlined'
                            color='primary'
                            sx={{
                              minWidth: "28px",
                              width: "30px",
                              height: "24px",
                              padding: "10px",
                              borderRadius: "4px",
                              border: "0.3px solid rgb(169, 195, 251)",
                            }}
                            onClick={() => {
                              setData(follow);
                              setId(follow?._id);
                              setAddEditOpen(true);
                            }}>
                            <Edit2 color='gray' variant='Bold' size={16} />
                          </IconButton>
                        </Box>
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          dangerouslySetInnerHTML={helperFunction.createMarkup(
                            follow.comment || "-"
                          )}></Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </CardContent>
            );
          })}
      </Box>
      {/* ))
      ) : (
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            px: "20px",
            py: "10px",
            backgroundColor: "white",
            height: "auto",
          }}
          // key={itemIndex}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height: "30px",
              borderBottom: "1px solid rgb(173, 198, 252)",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: 400,
                height: "20px",
                fontSize: "12px",
                color: "#1E1E1E",
              }}
            >
              No data available
            </Typography>
          </Box>
        </CardContent> */}
      {addEditOpen && (
        <AddEditFollowUp
          id={id}
          setId={setId}
          data={data}
          setData={setData}
          addEditOpen={addEditOpen}
          setAddEditOpen={setAddEditOpen}
          setRecallState={setRecallState}
          parentData={parentData}
          parentModel={parentModel}
        />
      )}
    </Card>
  );
};

export default FollowUpCard;

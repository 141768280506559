import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import * as url from "../../../services/url_helper";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import { Box, Container } from "@mui/system";
import InformationCard from "components/InformationCard";
import BreadCrumb from "components/BreadCrumb";
import { helperFunction } from "services/helperFunction";
import DeleteModal from "components/DeleteModal";
import { Clock, Edit } from "iconsax-react";
import { PictureAsPdf } from "@mui/icons-material";
import { ApiServices } from "services/apiServices";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import CustomLink from "components/CustomLink";
import AddEditServiceBrif from "./AddEditServiceBrif";
import Loaders from "components/Loaders/Loaders";
import { cardStyle } from "services/commonStyles";
import { getObjectPermissions } from "services/utils";

const ServiceBriefView = () => {
  const [serviceBriefData, setServiceBriefData] = useState({});
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [deleteData, setDeleteData] = useState(null);
  const [addEditOpen, setAddEditOpen] = useState(false);
  const permission = getObjectPermissions()?.["service-brief"];

  const handleDoubleClick = () => {
    if (permission && permission[0] >= 1) {
      setAddEditOpen(true);
    } else {
      Toastr("No Authorization");
    }
  };

  const getServiceData = () => {
    try {
      let uri = url.SERVICE_BRIEF + id;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setServiceBriefData(response?.result?.data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" Function Error", error);
    }
  };

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: serviceBriefData?.name });
  };

  useEffect(() => {
    getServiceData();
  }, []);

  document.title = "ServiceBrief | RegTech";

  return (
    <React.Fragment>
      <div className='page-content' style={{ height: "100%" }}>
        <BreadCrumb title='Service Brief' currentPage='RegTech' />
        {isLoading ? (
          <Loaders />
        ) : serviceBriefData && Object.keys(serviceBriefData).length > 0 ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={6} sx={{ height: "99%" }}>
              <Card sx={cardStyle}>
                <DeleteModal
                  dataUrl={url.SERVICE_BRIEF}
                  deleteData={deleteData}
                  setDeleteData={setDeleteData}
                  //    callFunc={addFilter}
                  navLink={"/serviceBrief-list"}
                />
                <Container
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                  onDoubleClick={() => handleDoubleClick()}>
                  <CardContent sx={{ p: 0, height: "100%" }}>
                    {addEditOpen ? (
                      <>
                        <AddEditServiceBrif
                          id={id}
                          data={serviceBriefData}
                          setData={setServiceBriefData}
                          setAddEditOpen={setAddEditOpen}
                        />
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            py: 2,
                          }}>
                          <Typography
                            component='div'
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                            }}>
                            Service Brief
                          </Typography>
                          {/* <Typography variant="h6">Account Information</Typography> */}
                          {permission !== undefined && permission[0] >= 3 && (
                            <>
                              <Typography
                                component='span'
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "5px",
                                }}>
                                {/* <Button
                          variant="contained"
                          startIcon={<Clock />}
                          size="small"
                        >
                          Tabels
                        </Button> */}
                                {/* <Button
                                  variant="contained"
                                  startIcon={<Clock />}
                                  color="success"
                                  // sx={{
                                  //   color: `${Colors?.SUCCESS_COLOR}`,
                                  // }}
                                  size="small"
                                >
                                  History
                                </Button> */}

                                {/* <IconButton
                                  size="small"
                                  color="error"
                                  sx={{
                                    p: 0,
                                    borderRadius: "5px",
                                    border: "1px solid red",
                                  }}
                                >
                                  <PictureAsPdf color="red" />
                                </IconButton> */}

                                <IconButton
                                  size='small'
                                  color='green'
                                  onClick={() => setAddEditOpen(!addEditOpen)}
                                  sx={{
                                    p: 0,
                                    borderRadius: "5px",
                                    border: "1px solid green",
                                  }}>
                                  <Edit size='small' color='green' />
                                </IconButton>

                                <IconButton
                                  size='small'
                                  // color="primary"
                                  onClick={handleOpenDelete}
                                  sx={{
                                    p: 0,
                                    borderRadius: "5px",
                                    border: "1px solid #4680FF ",
                                  }}>
                                  <DeleteIcon />
                                </IconButton>
                              </Typography>
                            </>
                          )}
                        </Box>
                        <Box
                          sx={{
                            m: 0,
                            p: 0,
                            maxHeight: "65vh",
                            overflowY: "auto",
                            scrollbarWidth: "none",
                          }}>
                          <InformationCard title='Core Details' color='red'>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              sx={{ pl: 2, pt: 1.5, m: 0 }}>
                              <Typography
                                // component="div"
                                color='secondary'
                                sx={{
                                  p: 0,
                                  fontSize: "12px",
                                }}>
                                Service Name
                              </Typography>

                              <Typography
                                variant='body2'
                                component='div'
                                sx={{
                                  p: 0,
                                  color: "#1E1E1E",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}>
                                {serviceBriefData?.name || "-"}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              sx={{ pl: 2, pt: 1.5, m: 0 }}>
                              <Typography
                                // component="div"
                                color='secondary'
                                sx={{
                                  p: 0,
                                  fontSize: "12px",
                                }}>
                                Owner Name
                              </Typography>

                              <Typography
                                variant='body2'
                                component='div'
                                sx={{
                                  p: 0,
                                  color: "#1E1E1E",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}>
                                {serviceBriefData?.ownerId?.fullName || "-"}
                              </Typography>
                            </Grid>
                          </InformationCard>
                          <InformationCard
                            title='General Details'
                            color='green'>
                            <Grid item xs={12} sx={{ pl: 2, pt: 1.5, m: 0 }}>
                              <Typography
                                // component="div"
                                color='secondary'
                                sx={{
                                  p: 0,
                                  fontSize: "12px",
                                }}>
                                serviceBrief
                              </Typography>
                              <Typography
                                variant='body2'
                                component='div'
                                sx={{
                                  p: 0,
                                  color: "#1E1E1E",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: serviceBriefData?.serviceBrief || "",
                                }}
                              />
                            </Grid>
                          </InformationCard>

                          <InformationCard title='Author Detail' color='orange'>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              sx={{ px: 2, pt: 1.5, m: 0 }}>
                              <Typography
                                color='secondary'
                                sx={{
                                  p: 0,
                                  fontSize: "12px",
                                }}>
                                Created By
                              </Typography>
                              <Typography
                                variant='body2'
                                component='div'
                                sx={{
                                  p: 0,
                                  color: "#1E1E1E",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}>
                                <span style={{ display: "block" }}>
                                  {serviceBriefData?.createdBy?.fullName},
                                </span>
                                {helperFunction.dateFormat(
                                  serviceBriefData?.createdAt,
                                  true
                                )}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              sx={{ px: 2, pt: 1.5, m: 0 }}>
                              <Typography
                                color='secondary'
                                sx={{
                                  p: 0,
                                  fontSize: "12px",
                                }}>
                                Last Modified By
                              </Typography>
                              <Typography
                                variant='body2'
                                component='div'
                                sx={{
                                  p: 0,
                                  color: "#1E1E1E",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}>
                                <span style={{ display: "block" }}>
                                  {serviceBriefData?.lastModifiedBy?.fullName ||
                                    serviceBriefData?.createdBy?.fullName}
                                  ,
                                </span>
                                {helperFunction.dateFormat(
                                  serviceBriefData?.updatedAt,
                                  true
                                )}
                              </Typography>
                            </Grid>
                          </InformationCard>
                        </Box>
                      </>
                    )}
                  </CardContent>
                </Container>
              </Card>
            </Grid>
            {/* <Grid item xs={12} sm={4} md={6} sx={{ height: "100%" }}>
            <RelatedList relatedName={"payment-detail"} />
          </Grid> */}
          </Grid>
        ) : (
          <tr>
            <td colSpan='3'>
              <h5
                className='text-primary text-center'
                style={{ fontWeight: "bold" }}>
                No Data Available / Account Deleted
              </h5>
            </td>
          </tr>
        )}
      </div>
    </React.Fragment>
  );
};

export default ServiceBriefView;

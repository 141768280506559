import React, { useEffect, useState, useMemo, useRef } from "react";

import { useLocation, useNavigate, useParams } from "react-router";
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  Table,
  CardHeader,
  Input,
  Button,
  Modal,
  Label,
  Box,
  Paper,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";

import { Edit as EditIcon } from "@mui/icons-material";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

//import COmponents
import * as url from "../../../services/url_helper";

import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/deleteRed.svg";
import BreadCrumb from "components/BreadCrumb";
import { Toastr } from "components/Toastr";
import CustomLink from "components/CustomLink";
import { helperFunction } from "services/helperFunction";
import TablePagination from "components/TablePagination/TablePagination";
import { getObjectPermissions } from "services/utils";

const Opportuntiy = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [addEditOpen, setAddEditOpen] = useState(false);
  const [id, setId] = useState(null);

  const [deleteData, setDeleteData] = useState(null);

  const location = useLocation();

  const handleOpenDelete = (id, name) => {
    setDeleteData({ open: true, id: id, title: name });
    setOpen(true);
  };

  const permission = getObjectPermissions()?.opportunity;

  const ownerId = localStorage.getItem("userId");

  const defaulFilter = [
    {
      _id: "My Opportunity",
      filterName: "My Opportunity",
      filters: [
        {
          ownerId: {
            operation: "equals",
            value: ownerId,
          },
        },
      ],

      limit: 10,
    },
  ];

  const column = useMemo(
    () =>
      [
        {
          Header: "Opportunity Name",
          accessor: "name",
          type: "string",
          Cell: (cellProps) => (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0"></div>

                <CustomLink
                  to={
                    cellProps.row.original?._id
                      ? `/opportunity/view/${cellProps.row.original?._id}`
                      : "#"
                  }
                >
                  <div className="flex-grow-1 ms-2 name">
                    {cellProps.row.original?.name}
                  </div>
                </CustomLink>
              </div>
            </>
          ),
        },
        {
          Header: "Account Name",
          accessor: "accountId.name",
          type: "string",
          Cell: (cellProps) => (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0"></div>

                <CustomLink
                  to={
                    cellProps.row.original?.accountId?._id
                      ? `/account/view/${cellProps.row.original?.accountId?._id}`
                      : "#"
                  }
                >
                  <div className="flex-grow-1 ms-2 name">
                    {cellProps.row.original.accountId?.name}
                  </div>
                </CustomLink>
              </div>
            </>
          ),
        },
        {
          Header: "Opportunity Owner",
          accessor: "ownerId.fullName",
          type: "string",
          Cell: (cellProps) => (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-2 name">
                  {cellProps.row.original.ownerId?.fullName}
                </div>
              </div>
            </>
          ),
        },
        {
          Header: "Stage",
          accessor: "stage",
          type: "string",
          Cell: (cellProps) => (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-2 name">
                  {cellProps.row.original?.stage}
                </div>
              </div>
            </>
          ),
        },
        {
          Header: "Probability",
          accessor: "probabilityHWC",
          type: "string",

          Cell: (cellProps) => (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-2 name">
                  {cellProps.row.original?.probabilityHWC}
                </div>
              </div>
            </>
          ),
        },
        {
          Header: "Currency",
          accessor: "currencyId.isoCode",
          type: "string",

          Cell: (cellProps) => (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-2 name">
                  {cellProps.row.original?.currencyId?.isoCode}
                </div>
              </div>
            </>
          ),
        },
        {
          Header: "Amount",
          accessor: "amount",
          type: "number",

          Cell: (cellProps) => (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-2 name">
                  {helperFunction.amountFormat(cellProps.row.original?.amount)}
                </div>
              </div>
            </>
          ),
        },
        {
          Header: "Start Date",
          accessor: "startDate",
          type: "date",

          Cell: (cellProps) => (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-2 name">
                  {helperFunction.dateFormat(cellProps.row.original?.startDate)}
                </div>
              </div>
            </>
          ),
        },

        {
          Header: "Action",
          accessor: "action",
          Cell: (cellProps) => {
            return (
              <Stack direction="row" spacing={1}>
                <CustomLink
                  to={
                    cellProps.row.original?._id
                      ? `/opportunity/view/${cellProps.row.original?._id}`
                      : "#"
                  }
                  newTab={true}
                >
                  <Tooltip title="new tab">
                    <IconButton color="primary">
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>
                </CustomLink>

                {permission !== undefined && permission[0] >= 1 && (
                  <Tooltip title="Edit">
                    <IconButton
                      color="success"
                      onClick={(e) => {
                        setAddEditOpen(true);
                        setId(cellProps.row.original._id);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {permission !== undefined && permission[0] >= 3 && (
                  <Tooltip title="Delete">
                    <IconButton
                      color="error"
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenDelete(
                          cellProps.row.original?._id,
                          cellProps.row.original?.name
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    {/* </Link> */}
                  </Tooltip>
                )}
              </Stack>
            );
          },
        },
      ].filter(Boolean),
    [permission]
  );

  // Export Modal
  // const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = "Opportunity List | RegTech";
  return (
    <React.Fragment>
      <div className="page-content" style={{ height: "100%" }}>
        <BreadCrumb
          title="Opportunity"
          currentPage="Account"
          currentLink="account-list"
          secondPage="Opportunity"
        />
        {isLoading ? (
          // <Loader />
          "Loader"
        ) : (
          <Box id="opportunityList">
            {/* <Paper elevation={3} style={{ marginBottom: "15px" }}>
              <Box
                padding="0px 10px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <h2>Project Task</h2>
                {permission !== undefined && permission[0] >= 2 && (
                  <Button
                    variant="outlined"
                    color="success"
                    id="create-btn"
                    // onClick={() => {
                    //   props.history.push("/add-account");
                    // }}
                  >
                    + ADD Account
                  </Button>
                )}
              </Box>
            </Paper> */}
            {/* <Paper elevation={3}>
                <Box id="accountList"> */}
            {/* <CardHeader className="border-0"></CardHeader> */}

            {permission !== undefined ? (
              location.pathname === "/opportunity-list" ? (
                <TablePagination
                  column={column}
                  filterFor={"opportunity"}
                  model={"opportunity"}
                  ByDefaultAll={false}
                  dataUrl={url.OPPORTUNITY}
                  setDeleteData={setDeleteData}
                  deleteData={deleteData}
                  addEditOpen={addEditOpen}
                  setAddEditOpen={setAddEditOpen}
                  id={id}
                  setId={setId}
                  defaulFilter={defaulFilter}
                  showAddButton={permission[0] >= 2}
                  // callFunc={addFilter}
                  // navLink={"/dashboard"}
                />
              ) : (
                <tr>
                  <td colSpan="3">
                    <h5
                      className="text-primary text-center"
                      style={{ fontWeight: "bold" }}
                    >
                      No Data Available
                    </h5>
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="3">
                  <h5
                    className="text-primary text-center"
                    style={{ fontWeight: "bold" }}
                  >
                    No Access
                  </h5>
                </td>
              </tr>
            )}
          </Box>
        )}
      </div>
    </React.Fragment>
  );
};

export default Opportuntiy;

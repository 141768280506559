import React from "react";
import CustomDatePicker from "components/CustomDatePicker";
import InformationCard from "components/InformationCard";
import { Toastr } from "components/Toastr";
import { useFormik } from "formik";
// import * as Constants from "services/keyConstants";
import { useEffect, useState } from "react";
import { ApiServices } from "services/apiServices";
import * as url from "services/url_helper";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import { ReactComponent as CrossIcon } from "../../../assets/button_svg/Vector.svg";
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { BookmarkBorderOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import { helperFunction } from "services/helperFunction";
import { dataStyle, titleStyle } from "services/commonStyles";
import { Constants } from "services/keyConstants";
import { getObjectPermissions, getSecureToken } from "services/utils";
import DeleteModal from "components/DeleteModal";
import * as Yup from "yup";

const AddEditContact = ({
  id = null,
  data,
  setData,
  setAddEditOpen,
  setId,
  parentData,
  parentModel,
  setRecallState,
}) => {
  const [options, setOptions] = useState({
    accounts: [],
    users: [],
    salutation: ["Mr.", "Mrs.", "Miss"],
  });
  const [deleteData, setDeleteData] = useState(null);

  const validationSchema = Yup.object({
    accountId: Yup.mixed().test(
      "accountId-validation",
      "Account is required",
      (value) => {
        if (!value) return false;
        if (typeof value === "string" && value.trim() !== "") return true;
        if (typeof value === "object" && value._id) return true;
        return false;
      }
    ),
    lastName: Yup.string().trim().required("Last Name is required"),
    email: Yup.string()
      .trim()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      ...data,
      accountId: data?.accountId || "",
      lastName: data?.lastName || "",
      email: data?.email || "",
      ownerId: data?.ownerId
        ? data.ownerId
        : getSecureToken(Constants.KEY_USER_DATA),
    },
    validationSchema,
    onSubmit: (values) => {
      if (id) {
        const updatedValues = helperFunction.getUpdatedValues(values, data);
        update(updatedValues);
      } else {
        create(values);
      }
    },
  });

  const getContactData = () => {
    try {
      let uri = url.CONTACT + id;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.data);
            setData(response?.result?.data);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const getOwner = () => {
    try {
      let uri = url.USERS;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({ ...prev, users: response?.result?.users }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };
  const getAccount = (query) => {
    try {
      let uri = url.ACCOUNT + `/name/${query}`;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              accounts: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };
  const handleInputChange = (event, newInputValue, reason) => {
    if (reason === "input") {
      if (newInputValue?.length >= 3) {
        getAccount(newInputValue);
      } else {
        setOptions((prev) => ({ ...prev, accounts: [] }));
      }
    }
  };

  const update = (values) => {
    try {
      let uri = url.CONTACT + id;
      let body = {
        ...values,
      };

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);
            if (setRecallState) {
              setRecallState(true);
            }
            setAddEditOpen(false);
            Toastr("Contact Updated Successfully", Constants.SUCCESS);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };
  const create = (values) => {
    try {
      let uri = url.CONTACT;
      let body = {
        ...values,
      };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("Contact Added Successfully", Constants.SUCCESS);
            if (setRecallState) {
              setRecallState(true);
            }
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };


  // const formatNumberByPrefix = (number) => {

  //   number = number.replace(/[^\d+]/g, "");

  //   const cleanNumber = number.startsWith("+") ? number.slice(1) : number;

  //   if (cleanNumber.startsWith("91")) {
  //     return number.replace(/(\+?91)(\d{5})(\d{5})/, "$1-$2-$3");
  //   } else if (cleanNumber.startsWith("1")) {
  //     return number.replace(/(\+?1)(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
  //   } else if (cleanNumber.startsWith("44")) {
  //     return number.replace(/(\+?44)(\d{4})(\d{3})(\d{3})/, "$1 $2 $3 $4");
  //   } else {
  //     return number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  //   }
  // };

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: data?.fullName });
  };
  useEffect(() => {
    if (id && !data) {
      getContactData();
    }
    getOwner();

    if (parentModel) {
      if (parentModel == "account") {
        formik.setFieldValue("accountId", parentData);
      } else if (parentData?.proposalId) {
        formik.setFieldValue("accountId", parentData?.accountId);
      } else {
        getAccount(`getallvalues?${parentModel}Id=${parentData?._id}`);
      }
    }
  }, []);
  const permission = getObjectPermissions().contact;

  return (
    <>
      <DeleteModal
        dataUrl={url.CONTACT}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        //    callFunc={addFilter}
        navLink={"/contact-list"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          py: 2,
        }}
      >
        <Typography
          component="div"
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          Contact
        </Typography>
        {/* <Typography variant="h6">Account Information</Typography> */}
        {permission !== undefined && permission[0] >= 3 && (
          <>
            <Typography
              component="span"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <IconButton
                size="small"
                onClick={() => {
                  setAddEditOpen((prev) => !prev);
                  if (location?.pathname === "/contact-list") {
                    setData(null);
                    setId(null);
                  }
                }}
                sx={{
                  p: 0.95,
                  borderRadius: "5px",
                  border: "1px solid red",
                }}
              >
                <CrossIcon width="2" height="2" />
              </IconButton>

              <IconButton
                size="small"
                color="green"
                onClick={() => {
                  formik.handleSubmit();
                }}
                sx={{
                  p: 0,
                  borderRadius: "5px",
                  border: "1px solid green",
                }}
              >
                <BookmarkBorderOutlined
                  sx={{ color: "green", height: "16px" }}
                />
              </IconButton>

              {id && (
                <IconButton
                  size="small"
                  // color="primary"
                  onClick={handleOpenDelete}
                  sx={{
                    p: 0,
                    borderRadius: "5px",
                    border: "1px solid #4680FF ",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          m: 0,
          p: 0,
          maxHeight: "65vh",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <InformationCard title="Core Details" color="red">
            <Grid item xs={4} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Salutation </Typography>
              <Autocomplete
                id="salutation"
                name="salutation"
                options={options?.salutation}
                getOptionLabel={(option) => option}
                value={formik.values?.salutation || null}
                onChange={(event, newValue) => {
                  formik.setFieldValue("salutation", newValue || null);
                }}
                isOptionEqualToValue={(option, value) => option === value}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Salutation"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>First Name </Typography>
              <TextField
                name="firstName"
                value={formik?.values?.firstName || ""}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="First Name"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={4} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Last Name
                <Typography variant="span" color="red">
                  *
                </Typography>
              </Typography>
              <TextField
                name="lastName"
                value={formik?.values?.lastName || ""}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Last Name"
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Account Name{" "}
                <Typography variant="span" color="red">
                  *
                </Typography>
              </Typography>
              <Autocomplete
                id="accountId"
                name="accountId"
                options={options?.accounts || []}
                getOptionLabel={(option) => option?.name}
                value={
                  typeof formik.values?.accountId === "object"
                    ? formik?.values?.accountId
                    : options?.accounts?.find(
                        (option) => option._id === formik.values?.accountId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "accountId",
                    newValue ? newValue?._id : ""
                  );
                }}
                onInputChange={handleInputChange}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Account Name"
                    error={
                      formik.touched.accountId &&
                      Boolean(formik.errors.accountId)
                    }
                    helperText={
                      formik.touched.accountId && formik.errors.accountId
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Owner Name</Typography>
              <Autocomplete
                id="ownerId"
                name="ownerId"
                options={options?.users || []}
                getOptionLabel={(option) => option?.fullName || ""}
                value={
                  typeof formik.values?.ownerId === "object"
                    ? formik.values?.ownerId
                    : options.users?.find(
                        (option) => option._id === formik.values?.ownerId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "ownerId",
                    newValue ? newValue?._id : ""
                  );
                }}
                onBlur={formik.handleBlur}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Owner Name"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Email
                <Typography variant="span" color="red">
                  *
                </Typography>
              </Typography>
              <TextField
                name="email"
                value={formik?.values?.email || ""}
                onChange={formik.handleChange}
                fullWidth
                type="email"
                variant="outlined"
                placeholder="Enter Email"
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
          </InformationCard>
          <InformationCard title="General Detail" color="blue">
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography
                // component="div"
                {...titleStyle}
              >
                Description
              </Typography>
              <TextField
                name="description"
                value={formik?.values?.description || ""}
                onChange={formik.handleChange}
                fullWidth
                type="textarea"
                variant="outlined"
                placeholder="Enter Description"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Designation</Typography>
              <TextField
                name="designation"
                value={formik?.values?.designation || ""}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter Designation"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
          </InformationCard>
          <InformationCard title="Other Details" color="green">
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>LinkedIn</Typography>
              <TextField
                name="linkedIn"
                value={formik?.values?.linkedIn || ""}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter LinkedIn"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Mobile No.</Typography>
              <TextField
                name="contactNumber"
                value={formik?.values?.contactNumber || ""}
                onChange={formik.handleChange}
                // onChange={(e) => {
                //   const formattedNumber = formatNumberByPrefix(e.target.value);
                //   formik.setFieldValue("contactNumber", formattedNumber);
                // }}
                fullWidth
                type="string"
                variant="outlined"
                placeholder="Enter Mobile No."
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
          </InformationCard>
        </form>
      </Box>
    </>
  );
};

export default AddEditContact;

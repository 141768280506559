import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";

import bg from "../../assets/bg-images/bg.svg";
import {
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Grid,
} from "@mui/material";
import { Edit2, Eye, EyeSlash } from "iconsax-react";

import CustomLink from "../CustomLink";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import { ApiServices } from "services/apiServices";
import * as url from "services/url_helper";
import RelatedAddEdit from "./RelatedAddEdit";
import { Colors } from "services/colorConstant";
import FollowUpCard from "./FollowUpCard";
import NoteCard from "./NoteCard";
import NotesPopup from "./NotesPopup";
import { getObjectPermissions } from "services/utils";
import { CookieSharp } from "@mui/icons-material";

const RelatedList = ({ relatedName, parentData, expanded }) => {
  const [collapsedRows, setCollapsedRows] = useState({});
  const [data, setData] = useState([]);
  const [addEditOpen, setAddEditOpen] = useState(false);
  const [relatedId, setRelatedId] = useState(null);
  const [relatedModel, setRelatedModel] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [hide, setHide] = useState({
    followUp: false,
    notes: false,
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const permission = getObjectPermissions();

  const getRelatedData = () => {
    try {
      let name =
        relatedName === "pInvoice"
          ? "p-invoice"
          : relatedName === "fInvoice"
          ? "f-invoice"
          : relatedName;

      let uri = url.CRM + name + url.RELATED + id;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.error("Related API error", error);
        });
    } catch (error) {
      console.error("Related function error", error);
    }
  };

  // const toggleCollapse = (id) => {
  //   setCollapsedRows((prev) => ({
  //     ...prev,
  //     [id]: !prev[id],
  //   }));
  // };
  useEffect(() => {
    getRelatedData();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return data && data.length > 0 ? (
    hide.followUp || hide.notes ? (
      hide.followUp ? (
        <>
          <Grid container>
            <Grid
              item
              xs={windowWidth > 430 ? 6 : 12}
              sm={windowWidth > 1024 ? 6 : 12}
            >
              <FollowUpCard
                setAll={setHide}
                parentData={parentData}
                parentModel={relatedName}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container>
          <Grid
            item
            xs={windowWidth > 430 ? 6 : 12}
            sm={windowWidth > 1024 ? 6 : 12}
          >
            <NoteCard
              setAll={setHide}
              parentData={parentData}
              parentModel={relatedName}
            />
          </Grid>
        </Grid>
      )
    ) : (
      <Grid
        sx={{
          maxHeight: "100%",
          overflowY: "auto",
          scrollbarWidth: "none",
          marginTop: expanded ? "30px" : "none",
        }}
        container
        spacing={1}
      >
        {data.map((d, index) => (
          <Grid
            item
            xs={windowWidth > 430 ? 6 : 12}
            sm={windowWidth > 1024 ? 6 : 12}
            key={index}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Card
              sx={{
                maxWidth: 345,
                // marginBottom: 2,
                height: "210px",
                width: "100%",
                maxHeight: "210px",
                boxSizing: "content-box",
                border: "3px solid #D0DFFF",
                borderRadius: "16px",
                backgroundRepeat: "no-repeat",
                padding: "0px",
                margin: "0px",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  textAlign: "center",
                  height: "60px",
                  px: "20px",
                  backgroundColor: "inherit",
                  background: `url(${bg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "110%",
                  border: "none",
                }}
              >
                <Box display="flex" alignItems="center">
                  <Typography variant="h5" fontWeight="500">
                    {d?.for
                      ?.replace(/^./, (char) => char.toUpperCase())
                      ?.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  </Typography>
                  <Typography
                    sx={{
                      marginLeft: 1,
                      fontWeight: "bold",
                      backgroundColor: Colors.CARD_ICONCOLOR,
                      borderRadius: "3.27px",
                      height: "18px",
                      width: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // fontSize: "12px",
                      // color: "#353535",
                    }}
                  >
                    {d?.count}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center">
                  {d?.type === "child" && (
                    <Typography
                      sx={{
                        marginLeft: 1,
                        fontWeight: "bold",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "5px",
                        // border: "0.5px solid #C8D9FF",
                        height: "20px",
                        width: "48px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "10px",
                        fontWeight: 400,
                        color: "#4680FF",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        d?.for === "followUpTask" || d?.for === "notes"
                          ? d?.for === "followUpTask"
                            ? setHide((prev) => ({ ...prev, followUp: true }))
                            : setHide((prev) => ({ ...prev, notes: true }))
                          : navigate(`/${d?.for}-list?${relatedName}Id=${id}`, {
                              state: { parentData, relatedName },
                            });
                      }}
                    >
                      View All
                    </Typography>
                  )}
                  {(d.for === "notes" || d.for === "followUpTask"
                    ? true
                    : permission?.[
                        d?.for
                          ?.replace(/([a-z])([A-Z])/g, "$1-$2")
                          .toLowerCase()
                      ] !== undefined &&
                      permission?.[
                        d?.for
                          ?.replace(/([a-z])([A-Z])/g, "$1-$2")
                          .toLowerCase()
                      ][0] >= 2) && (
                    <>
                      <Typography
                        sx={{
                          marginLeft: 1,
                          fontWeight: "bold",
                          backgroundColor: Colors.CARD_ICONCOLOR,
                          borderRadius: "50%",
                          height: "18px",
                          width: "18px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // fontSize: "16px",
                          // color: "#353535",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setAddEditOpen(true);
                          setRelatedModel(d?.for);
                        }}
                      >
                        +
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
              {d?.count > 0 ? (
                d?.data.map((item, itemIndex) => (
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      px: "20px",
                      py: "7px",
                      backgroundColor: "white",
                      height: "auto",
                    }}
                    key={itemIndex}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "30px",
                        borderBottom: "1px solid rgb(173, 198, 252)",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 400,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "250px",
                        }}
                      >
                        <CustomLink
                          to={
                            d?.for === "notes"
                              ? `#`
                              : d?.for === "followUpTask"
                              ? `/${d?.for}?relatedTo=${id}&followupId=${item?._id}`
                              : `/${d?.for}/view/${item?._id}`
                          }
                          onClick={() => {
                            if (d.for === "notes") {
                              setAddEditOpen(true);
                              setRelatedModel(d?.for);
                              setRelatedId(item?._id);
                            }
                          }}
                        >
                          {d?.for === "contact"
                            ? item?.fullName
                            : d?.for === "followUpTask"
                            ? item?.subject
                            : d?.for === "notes"
                            ? item.heading
                            : item?.name}
                        </CustomLink>
                      </Typography>

                      <Box sx={{ display: "flex", gap: 1 }}>
                        {(d.for === "notes" || d.for === "followUpTask"
                          ? true
                          : permission?.[
                              d?.for
                                ?.replace(/([a-z])([A-Z])/g, "$1-$2")
                                ?.toLowerCase()
                            ] !== undefined &&
                            permission?.[
                              d?.for
                                ?.replace(/([a-z])([A-Z])/g, "$1-$2")
                                ?.toLowerCase()
                            ][0] >= 1) && (
                          <IconButton
                            variant="outlined"
                            color="primary"
                            sx={{
                              minWidth: "28px",
                              width: "30px",
                              height: "24px",
                              padding: "10px",
                              borderRadius: "4px",
                              border: "0.3px solid rgb(169, 195, 251)",
                            }}
                            onClick={() => {
                              setAddEditOpen(true);
                              setRelatedModel(d?.for);
                              setRelatedId(item?._id);
                            }}
                          >
                            <Edit2 color="gray" variant="Bold" size={16} />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </CardContent>
                ))
              ) : (
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    px: "20px",
                    py: "10px",
                    backgroundColor: "white",
                    height: "auto",
                  }}
                  // key={itemIndex}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "30px",
                      borderBottom: "1px solid rgb(173, 198, 252)",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 400,
                        height: "20px",
                        fontSize: "12px",
                        color: "#1E1E1E",
                      }}
                    >
                      No data available
                    </Typography>
                  </Box>
                </CardContent>
              )}
            </Card>
          </Grid>
        ))}
        {addEditOpen && (
          <RelatedAddEdit
            id={relatedId}
            model={relatedModel}
            addEditModal={addEditOpen}
            setAddEditModal={setAddEditOpen}
            setId={setRelatedId}
            parentData={parentData}
            parentModel={relatedName}
            callFunc={getRelatedData}
            // nav={nav}
          />
        )}
      </Grid>
    )
  ) : (
    <Typography>No data available</Typography>
  );
};
export default RelatedList;

import React, { useState, useEffect, useRef, useMemo } from "react";
import { useLocation } from "react-router";

import {
  Button,
  Table,
  Box,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Typography,
  Stack,
  Pagination,
  PaginationItem,
  InputAdornment,
  ButtonGroup,
  Autocomplete,
} from "@mui/material";

import { Filter as FilterIcon } from "iconsax-react";
import DocumentIcon from "../../assets/images/icons/document-text.svg";
import DefaultIcon from "../../assets/images/icons/Property 1=Default.svg";
import AscIcon from "../../assets/images/icons/Property 1=Up.svg";
import DescIcon from "../../assets/images/icons/Property 1=Down.svg";
// import Loader from "./Loader";
import SearchIcon from "@mui/icons-material/Search";
import * as url from "../../services/url_helper";
import { ApiServices } from "services/apiServices";
import { Toastr } from "../Toastr";
import { Constants } from "services/keyConstants";
import DeleteModal from "../DeleteModal";
import Filter from "./Filter";
import RelatedAddEdit from "../RelatedList/RelatedAddEdit";
import { Colors } from "services/colorConstant";
import Loaders from "components/Loaders/Loaders";
import KanbanTable from "components/KanbanTable/KanbanTable";
import { ViewKanbanRounded, WindowRounded } from "@mui/icons-material";
// import "./TablePagination.css";

// import toastr from "toastr";

const TablePagination = ({
  column,
  filterFor,
  model,
  ByDefaultAll = false,
  dataUrl,
  deleteData,
  setDeleteData,
  addEditOpen,
  setAddEditOpen,
  id,
  setId,
  // navLink,
  // callFunc,
  nav,
  defaulFilter = [],
  showAddButton,
  recallState = false,
  setRecallState,
}) => {
  // const [openModalIndex, setOpenModalIndex] = useState(null);
  // const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  // const [selectedFilterOperation, setSelectedFilterOperation] = useState(null);
  // const [inputFilterValue, setInputFilterValue] = useState();
  // const [selectedField, setSelectedField] = useState();

  // const [openAdd, setOpenAdd] = useState(false);
  // const [openShare, setOpenShare] = useState(false);
  // const [shareId, setShareId] = useState(null);
  // const [selectFiterName, setSelectFiterName] = useState("");
  // const [addUpdateFilter, setAddUpdateFilter] = useState("");
  // const [isOn, setIsOn] = useState(false);
  // const [dropdownOpen, setDropdownOpen] = useState(null);
  // const [isFocused, setIsFocused] = useState(false);
  // const [selectUserId, setSelectUserId] = useState();
  // const [selectUserIdsArray, setSelectUserIdsArray] = useState();

  const [bodyData, setBodyData] = useState({
    openShare: false,
    isOn: false,
    isFocused: false,
    openAdd: false,
  });
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [activeButton, setActiveButton] = useState("All");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 720);

  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 570);
  const [isSMobileScreen, setIsSMobileScreen] = useState(
    window.innerWidth < 420
  );

  const [hoveredButton, setHoveredButton] = useState(null);
  const [isOpenFilterModal, setOpenFilterModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const hasMounted = useRef(false);

  const [data, setData] = useState([]);
  // const [fiterData, setFiterData] = useState(null);
  const [fiterData, setFiterData] = useState({
    ...defaulFilter[0],
    defaulFilter: defaulFilter,
  });
  const [paginationData, setPaginationData] = useState({
    limit: defaulFilter[0]?.limit || 10,
    currentPage: 1,
  });
  const [user, setUser] = useState([]);
  const [search, setSearch] = useState("");
  const [inputValue, setInputValue] = useState(paginationData?.limit);
  const [isFocused, setIsFocused] = useState(false);
  const [view, setView] = useState("Kanban");

  const location = useLocation();
  const { parentData, relatedName } = location.state || {};

  const textStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "63%",
    color: "#4680FF",
    // transform: view === "Kanban" ? "translateX(0%)" : "translateX(-50%)",
    transition: "transform 0.3s ease-in-out",
  };

  const iconStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "37%",
    backgroundColor: "#4680FF",
    color: "white",
    borderRadius: "3px",
    // transform: view === "Kanban" ? "translateX(0%)" : "translateX(172%)",
    transition: "transform 0.3s ease-in-out",
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 720);
      setIsMobileScreen(window.innerWidth < 570);
      setIsSMobileScreen(window.innerWidth < 420);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!location?.search || location?.search === "") {
      getAllFilterData();
    } else {
      addFilter();
    }
    // userData();
  }, []);
  useEffect(() => {
    if (recallState) {
      addFilter();
      setRecallState(false);
    }
  }, [recallState]);

  useEffect(() => {
    if (hasMounted.current) {
      addFilter();
    } else {
      hasMounted.current = true;
    }
  }, [fiterData, paginationData?.limit, paginationData?.currentPage]);
  // }, [fiterData]);

  const handleToggle = () => {
    // const newValue = !isOn;
    setBodyData((prevState) => ({
      ...prevState,
      isOn: !bodyData?.isOn,
    }));
    // setIsOn(newValue);
    setFiterData({ ...fiterData, all: newValue });

    setPaginationData((prevData) => ({
      ...prevData,
      currentPage: 1,
    }));
  };
  const getAllFilterData = async () => {
    try {
      let uri = url.FILTER_MODEL + model;
      const response = await ApiServices.callServiceGet(uri);

      if (response?.result?.type === "success") {
        setIsLoading(false);
        setOpenFilterModal(false);

        let data = response?.result?.data;

        // setFiterData((prevData) => ({
        //   ...prevData,
        //   allFiterData: data,
        //   ...defaulFilter[0],
        //   defaulFilter: defaulFilter,
        // }));
        setFiterData((prevData) => ({
          ...prevData,
          allFiterData: data,
          // ...defaulFilter[0],
          // defaulFilter: defaulFilter,
        }));
        let pinnedData = data.filter((t) => t.pinned === true)[0];

        if (!pinnedData) {
          setFiterData((prevData) => ({
            ...prevData,
            ...pinnedData,
            all: ByDefaultAll,
          }));
        } else {
          setFiterData((prevData) => ({
            ...prevData,
            ...pinnedData,
            all: false,
          }));
        }
        setIsLoading(true);
        // addFilter();
      } else {
        setIsLoading(false);
        Toastr(
          response?.result?.message
            ? response?.result?.message
            : "Error Occurred"
        );
      }
    } catch (error) {
      console.warn(" Function Error", error);
    }
  };

  const addFilter = async () => {
    try {
      setIsLoading(true);
      let uri;
      filterFor === "users"
        ? (uri = "/" + filterFor + url.FILTER)
        : (uri = url.CRM + filterFor + url.FILTER);
      // let uri = url.CRM + filterFor + url.FILTER;
      let body = {};
      let followupId = null;
      const queryString = location?.search.substring(1);
      const queryParams = new URLSearchParams(queryString);
      let parameters = {};
      queryParams.forEach((value, key) => {
        parameters = { relatedName: key, id: value };
      });

      if (!location?.search || location?.search === "") {
        body = {
          name: fiterData?.filterName,
          filters: fiterData?.filters || [],
          sortFields: fiterData?.sortFields || null,
          search: fiterData?.search || null,
          pin: fiterData?.pinned || false,
          page: paginationData?.currentPage,
          limit: paginationData?.limit || 10,
          save: fiterData?.save || null,
          kanban: fiterData?.kanban || false,
          all:
            fiterData?.all !== undefined && fiterData?.all !== null
              ? fiterData?.all
              : true,

          filterId:
            (fiterData?.filterName === fiterData?._id
              ? null
              : fiterData?._id) || null,
        };
      } else {
        const queryString = location?.search.substring(1);
        const queryParams = new URLSearchParams(queryString);
        let parameters = {};

        queryParams.forEach((value, key) => {
          if (key !== "followupId") {
            parameters = { relatedName: key, id: value };
          }
        });

        let relatedName = parameters?.relatedName;
        let relatedId = parameters?.id;
        followupId = queryParams.get("followupId");

        body = {
          name: fiterData?.filterName,
          filters: [
            {
              [relatedName]: {
                operation: "equals",
                value: relatedId,
              },
            },
          ],
          // filters: fiterData?.filters || [],
          sortFields: fiterData?.sortFields || null,
          search: fiterData?.search || null,
          pin: false,
          page: paginationData?.currentPage,
          limit: paginationData?.limit || 10,
          kanban: fiterData?.kanban || false,
          save: null,
          all: false,
          filterId: null,
        };
      }

      const response = await ApiServices.callServicePostWithBodyData(uri, body);

      if (response?.result?.type === "success") {
        setIsLoading(false);
        setData(response?.result?.data);
        if (model === "followuptask") {
          setId(followupId ? followupId : response?.result?.data[0]?._id);
        }
        setPaginationData((prevData) => ({
          // ...prevData,
          ...response?.result?.pagination,
        }));
        // toastr.success("Account data loaded successfully.");
      } else {
        setIsLoading(false);

        Toastr(
          response?.result?.message
            ? response?.result?.message
            : "Error Occurred"
        );
      }
    } catch (error) {
      console.warn("Error in add Filter:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleShare = () => {
    let uri = url.SHARE_FILTER + bodyData?.shareId;
    const body = {
      shareWith: bodyData?.selectUserIdsArray
        ? bodyData?.selectUserIdsArray
        : [],
    };
    ApiServices.callServicePostWithBodyData(uri, body)
      .then((response) => {
        if (response?.result?.type === "success") {
          Toastr("Filter shared successfully", Constants.SUCCESS);
          handleCloseShare();
        } else {
          Toastr(
            response?.result?.message
              ? response?.result?.message
              : "Error Occurred"
          );
        }
      })
      .catch((error) => {
        console.warn("Call Error", error);
      });
  };

  const handleDelete = () => {
    let uri = url.Delete_FILTER + deleteId;
    ApiServices.callServiceDelete(uri)
      .then((response) => {
        if (response?.result?.type === "success") {
          Toastr("Filter deleted successfully", Constants.SUCCESS);
          handleCloseDelete();
          // getOpportunityData();
          getAllFilterData();
        } else {
          Toastr(
            response?.result?.message
              ? response?.result?.message
              : "Error Occurred"
          );
        }
      })
      .catch((error) => {
        console.warn(" call Error", error);
      });
  };
  const userData = async () => {
    try {
      let uri = url.USERS;
      const response = await ApiServices.callServiceGet(uri);

      if (response?.result?.type === "success") {
        setUser(response?.result?.users);
      } else {
        Toastr(
          response?.result?.message
            ? response?.result?.message
            : "Error Occurred"
        );
      }
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  // const toggleDropdown = () => setBodyData((prevState)=>({...prevState,
  //   dropdownOpen:!bodyData?.dropdownOpen}));
  const handleMenuClick = (event) => {
    setBodyData((prevData) => ({
      ...prevData,
      dropdownOpen: event.currentTarget,
    }));
  };
  const handlePageChange = (pageNumber) => {
    setIsLoading(true);

    setPaginationData((prevData) => ({ ...prevData, currentPage: pageNumber }));
  };

  const handleOpenDelete = (id) => {
    setDeleteId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setDeleteId(null);
    setOpenDelete(false);
  };
  const handleOpenShare = (id) => {
    setBodyData((prevState) => ({
      ...prevState,
      shareId: id,
      openShare: true,
    }));
    // setShareId(id);
    // setOpenShare(true);
  };
  const handleClose = () => {
    setFiterData((prevData) => ({
      ...prevData,
      addUpdateFilter: null,
    }));
    setBodyData((prevState) => ({
      ...prevState,
      dropdownOpen: null,
      openAdd: false,
    }));
    // setDropdownOpen(null);
    // setAddUpdateFilter(null);
    // handleOpenAdd(false);
  };
  const handleCloseShare = () => {
    // setShareId(null);
    // setOpenShare(false);
    // setSelectUserId([]);
    // setSelectUserIdsArray([]);
    setBodyData((prevState) => ({
      ...prevState,
      selectUserIdsArray: [],
      shareId: null,
      openShare: false,
    }));
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.name === "search") {
      setSearch(event.target.value);
      setFiterData({
        ...fiterData,
        search: search,
      });
      setPaginationData((prevData) => ({ ...prevData, currentPage: 1 }));
    } else if (event.key === "Enter" && event.target.name === "currentPage") {
      if (Number(event?.target?.value) === paginationData?.currentPage) return;
      setIsLoading(true);
      setPaginationData((prevData) => ({
        ...prevData,
        currentPage: Number(event.target.value) || 1,
      }));
    }
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSort = (accessor) => {
    const newOrder =
      fiterData?.sortFd === accessor && fiterData?.sortOrder === "asc"
        ? "desc"
        : "asc";

    setPaginationData((prevData) => ({
      ...prevData,
      currentPage: 1,
    }));
    setFiterData((prevData) => ({
      ...prevData,
      sortFields: accessor ? [{ field: accessor, order: newOrder }] : null,
      sortOrder: newOrder,
      sortFd: accessor,
    }));
  };

  const getSortIcon = (accessor) => {
    if (fiterData?.sortFd === accessor) {
      if (fiterData?.sortOrder === "asc") return AscIcon;
      if (fiterData?.sortOrder === "desc") return DescIcon;
    }
    return DefaultIcon;
  };
  const operationStringOption = [
    { value: "equals", label: "equals" },
    { value: "not equal to", label: "not equal to" },
    { value: "contains", label: "contains" },
    { value: "does not contain", label: "does not contain" },
    { value: "starts with", label: "starts with" },
  ];

  const allDataOption = [
    { value: true, label: "All Data" },
    { value: false, label: "Filtered Data" },
  ];

  const filteredDataOption = [{ value: false, label: "Filtered Data" }];

  const operationNumberOption = [
    { value: "equals", label: "equals" },
    { value: "not equal to", label: "not equal to" },
    { value: "less than", label: "less than" },
    { value: "greater than", label: "greater than" },
    { value: "less or equal", label: "less or equal" },
    { value: "greater or equal", label: "greater or equal" },
  ];

  const operationDateOption = [
    { value: "less or equal", label: "less or equal" },
    { value: "greater or equal", label: "greater or equal" },
  ];
  const operationTimerDateOption = [
    { value: "less or equal", label: "less or equal" },
    { value: "greater or equal", label: "greater or equal" },
    { value: "today", label: "Today" },
    { value: "yesterday", label: "yesterday" },
    { value: "this month", label: "this month" },
    { value: "last month", label: "last month" },
  ];

  const noInputOptions = ["today", "yesterday", "this month", "last month"];
  // const toggleSidebar = () => {
  //   setSidebarData((prevState) => ({
  //     ...prevState,
  //     isOpenSidebar: !sidebarData?.isOpenSidebar,
  //   }));
  //   // setIsOpenSidebar(!isOpenSidebar);
  // };

  const handleInput = (value) => {
    let addEdit = {
      _id: fiterData?._id,
      // filterName: value,
      save: fiterData?.addUpdateFilter,
      // pinned: pinned,
      pinned: fiterData?.pinned,

      // limit: pagination?.limit,
    };

    setFiterData((prevData) => ({
      ...prevData,
      ...addEdit,
      addUpdateFilter: null,
    }));
    setBodyData((prevState) => ({
      ...prevState,
      openAdd: false,
    }));
    setPaginationData((prevData) => ({
      ...prevData,
      limit: Number(inputValue),
    }));
    // setAddUpdateFilter(null);
    // setOpenAdd(false);
  };

  const handleOpenAdd = () => {
    setBodyData((prevState) => ({
      ...prevState,
      openAdd: true,
    }));
    // setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setBodyData((prevState) => ({
      ...prevData,
      openAdd: false,
    }));
    // setOpenAdd(false);
  };

  // const handleApply = (key, type, index = null) => {
  //   let value = sidebarData?.selectedField?.accessor || key;

  //   const newFilter = {
  //     [value]: {
  //       operation: sidebarData?.selectedFilterOperation.value,
  //       value:
  //         sidebarData?.selectedField?.type === "number" || type === "number"
  //           ? Number(sidebarData?.inputFilterValue)
  //           : sidebarData?.inputFilterValue,
  //     },
  //   };

  //   if (index !== null) {
  //     // setSelectFilter(updatedFilters);
  //     const updatedFilters = [...fiterData?.filters];
  //     updatedFilters[index] = newFilter;
  //     setFiterData((prevData) => ({
  //       ...prevData,
  //       filters: updatedFilters,
  //     }));
  //   } else {
  //     // setSelectFilter([...selectFilter, newFilter]);
  //     setFiterData((prevData) => ({
  //       ...prevData,
  //       filters: [...prevData?.filters, newFilter],
  //     }));
  //   }

  //   setPaginationData((prevData) => ({
  //     ...prevData,
  //     currentPage: 1,
  //   }));
  //   setSidebarData({
  //     ...sidebarData,
  //     openModalIndex: null,
  //     selectedFilterOperation: null,
  //     inputFilterValue: "",
  //     selectedField: null,
  //   });
  //   // setSelectedFilterOperation(null);
  //   // setInputFilterValue("");
  //   // setSelectedField(null);
  //   // setOpenModalIndex(null);
  // };

  const handleSelectChange = (e, name) => {
    if (Array.isArray(e)) {
      const value = e.map((option) => option._id);
      setBodyData((prevData) => ({ ...prevData, [name]: value }));
    } else {
      const value = e.value !== undefined ? e.value : e._id;
      setBodyData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleRemove = (index) => {
    const updatedFilters = fiterData?.filters.filter((_, i) => i !== index);
    // setSelectFilter(updatedFilters);
    setFiterData((prevData) => ({
      ...prevData,
      filters: updatedFilters,
    }));
  };
  const columns = useMemo(() => [
    {
      Header: " ",
      accessor: "srno",
      Cell: (cellProps) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {cellProps.row.index +
              1 +
              (paginationData?.currentPage - 1) * paginationData?.limit}
          </div>
        </div>
      ),
    },
    ...column,
  ]);
  const accessorToHeaderMap = columns.reduce((acc, column) => {
    acc[column.accessor] = column.Header;
    return acc;
  }, {});

  const accessorToTypeMap = columns.reduce((acc, column) => {
    acc[column.accessor] = column?.type;
    return acc;
  }, {});

  const handleViewChange = () => {
    // setView((prev) => (prev === "Kanban" ? "Table" : "Kanban"));

    setPaginationData((prevData) => ({
      ...prevData,
      currentPage: fiterData?.kanban ? 1 : undefined,
      limit: fiterData?.kanban ? 10 : undefined,
    }));
    setFiterData((prevData) => ({
      ...prevData,

      kanban: !prevData?.kanban,
    }));
  };

  return (
    <>
      <Typography
        // className="page-content"
        variant="div"
        // style={{ backgroundColor: "#E9F0FF" }}
      >
        {/* <Paper elevation={3} style={{ marginBottom: "15px" }}>
        <Box padding="0px 10px">
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            {!isExtraSmallScreen || !bodyData?.isFocused ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginRight: "10px",
                  maxWidth: "40%",
                  width: "400px",
                  paddingRight: "5px",
                }}
              >
                {isSmallScreen && !fiterData?.all ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                      onClick={handleToggle}
                      // color="light"
                      style={{
                        width: "60px",
                        height: "30px",
                        borderRadius: "15px",
                        backgroundColor: bodyData?.isOn ? "#1E90FF" : "#ccc",
                        padding: 0,
                        position: "relative",
                        display: "inline-block",
                        border: "none",
                        transition: "background-color 0.3s ease",
                      }}
                    >
                      <span
                        style={{
                          display: "block",
                          width: "26px",
                          height: "26px",
                          borderRadius: "50%",
                          backgroundColor: "#fff",
                          position: "absolute",
                          top: "2px",
                          left: bodyData?.isOn ? "32px" : "2px",
                          transition: "left 0.3s ease",
                        }}
                      />
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{
                      flex: "1 1 120px",
                      minWidth: "100px",
                      maxWidth: "50%",
                    }}
                  >
                    <Select
                      id="all-data-select"
                      value={fiterData?.all ?? false}
                      onChange={(e) => {
                        setFiterData((prevData) => ({
                          ...prevData,
                          all: e.target.value,
                        }));
                        setPagination((prevData) => ({
                          ...prevData,
                          currentPage: 1,
                        }));
                      }}
                    >
                      {allDataOption.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
                <div
                  style={{
                    flex: "1 1 120px",
                    minWidth: "100px",
                    maxWidth: "50%",
                  }}
                >
                  <Autocomplete
                    id="filterName"
                    name="filterName"
                    getOptionLabel={(e) => e.filterName}
                    getOptionValue={(e) => e._id}
                    // value={fiterData?._id ?? null}
                    value={
                      fiterData?._id === undefined
                        ? { filterName: "DEFAULT", _id: null }
                        : fiterData
                    }
                    options={[
                      { filterName: "DEFAULT", _id: null },
                      ...(fiterData?.allFiterData ?? []),
                    ]}
                    onChange={(e, newValue) => {
                      // setSelectFiterName(e.filterName || null);
                      setFiterData((prevData) => ({
                        allFiterData: prevData.allFiterData,
                        ...newValue,
                      }));
                      // setPinned(e?.pinned);
                      // setSelectFilter(e?.filters || []);
                      setPagination((prevData) => ({
                        ...prevData,
                        currentPage: 1,
                      }));
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            opacity: 1,
                            color: "#4c4d4f !important",
                            WebkitTextFillColor: "#4c4d4f !important",
                          },
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            ) : null}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                flex: "1",
                justifyContent: "flex-end",
              }}
            >
              {isExtraSmallScreen ? (
                <>
                  {!bodyData?.isFocused ? (
                    <span
                      className="ri-search-line"
                      style={{ fontSize: "24px", cursor: "pointer" }}
                      onClick={() => setBodyData((prevState)=>({...prevState,
                      isFocused:true}))}
                      // setIsFocused(true)
                    >
                      search
                    </span>
                  ) : (
                    <div
                      style={{
                        flex: "1 1 120px",
                        minWidth: "120px",
                        maxWidth: bodyData?.isFocused ? "100%" : "30%",
                        transition: "max-width 0.3s ease-in-out",
                      }}
                    >
                      <Input
                        type="text"
                        name="search"
                        className="form-control"
                        placeholder={`${
                          pagination?.totalRecords
                            ? pagination?.totalRecords
                            : ""
                        } Search...`}
                        aria-label="Search input"
                        onFocus={() =>setBodyData((prevState)=>({...prevState,
                      isFocused:true}))}
                        onBlur={() => setBodyData((prevState)=>({...prevState,
                      isFocused:false}))}
                        onChange={(e) => handleChange(e)}
                        onKeyDown={handleKeyDown}
                        value={search}
                        autoFocus
                      />
                    </div>
                  )}
                </>
              ) : (
                <div
                  style={{
                    flex: "1 1 120px",
                    minWidth: "120px",
                    maxWidth: bodyData?.isFocused ? "100%" : "30%",
                    transition: "max-width 0.3s ease-in-out",
                  }}
                >
                  <Input
                    type="text"
                    name="search"
                    className="form-control"
                    placeholder={`${
                      pagination?.totalRecords ? pagination?.totalRecords : ""
                    } Search...`}
                    aria-label="Search input"
                    onFocus={() => 
                      setBodyData((prevState)=>({...prevState,
                      isFocused:true}))}
                      // setIsFocused(true)}
                    onBlur={() => 
                      setBodyData((prevState)=>({...prevState,
                      isFocused:true}))}
                      // setIsFocused(false)}
                    onChange={(e) => handleChange(e)}
                    onKeyDown={handleKeyDown}
                    value={search}
                  />
                </div>
              )}
              {isSmallScreen ? (
                <>
                  {fiterData?.all === false || fiterData?.all === undefined ? (
                    <div>
                      <IconButton
                        onClick={toggleDropdown}
                        style={{ backgroundColor: "#4b38b3" }}
                      >
                        <ArrowDropDownIcon style={{ color: "#fff" }} />
                      </IconButton>
                      <Menu
                        open={bodyData?.dropdownOpen}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onMouseEnter={(e) => {
                            e.target.style.color = "#fff";
                            e.target.style.backgroundColor = "#2a5fc1";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = "#000";
                            e.target.style.backgroundColor = "#fff";
                          }}
                          onClick={toggleSidebar}
                        >
                          Filter
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => {
                            e.target.style.color = "#fff";
                            e.target.style.backgroundColor = "#2a5fc1";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = "#000";
                            e.target.style.backgroundColor = "#fff";
                          }}
                          onClick={() => {
                            setfiterData((prevData)=>({
                              ...prevData,
                              addUpdateFilter:"new"}))
                            // setAddUpdateFilter("new");
                            handleOpenAdd();
                          }}
                        >
                          <span
                            style={{
                              // color: "#299cdb",
                              fontSize: 18,
                              marginRight: 8,
                            }}
                          >
                            +
                          </span>
                          Add Filter
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => {
                            e.target.style.color = "#fff";
                            e.target.style.backgroundColor = "#2a5fc1";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.color = "#000";
                            e.target.style.backgroundColor = "#fff";
                          }}
                          onClick={() => {
                               setfiterData((prevData)=>({
                              ...prevData,
                              addUpdateFilter:"update"}))
                            // setAddUpdateFilter("update");
                            handleOpenAdd();
                          }}
                        >
                          <EditIcon
                            style={{
                              color: "#45CB85",
                              fontSize: 16,
                              marginRight: 8,
                            }}
                          />
                          Update Filter
                        </MenuItem>
                        {fiterData?._id !== undefined && (
                          <>
                            <MenuItem
                              onMouseEnter={(e) => {
                                e.target.style.color = "#fff";
                                e.target.style.backgroundColor = "#2a5fc1";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.color = "#000";
                                e.target.style.backgroundColor = "#fff";
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpenShare(fiterData?._id);
                              }}
                            >
                              Share Filter
                            </MenuItem>
                            <MenuItem
                              onMouseEnter={(e) => {
                                e.target.style.color = "#fff";
                                e.target.style.backgroundColor = "#2a5fc1";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.color = "#000";
                                e.target.style.backgroundColor = "#fff";
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpenDelete(fiterData?._id);
                              }}
                            >
                              Delete Filter
                            </MenuItem>
                          </>
                        )}
                      </Menu>
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  {fiterData?.all === false || fiterData?.all === undefined ? (
                    <>
                      <Button onClick={toggleSidebar}>filter</Button>
                      <div>
                        <IconButton
                          style={{ backgroundColor: "#4b38b3" }}
                          onClick={handleMenuClick}
                        >
                          <ArrowDropDownIcon style={{ color: "white" }} />
                        </IconButton>

                        <Menu
                          anchorEl={bodyData?.dropdownOpen}
                          open={Boolean(bodyData?.dropdownOpen)}
                          onClose={() => handleClose()}
                        >
                          <MenuItem
                            onMouseEnter={(e) => {
                              e.target.style.color = "#fff";
                              e.target.style.backgroundColor = "#2a5fc1";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.color = "#000";
                              e.target.style.backgroundColor = "#fff";
                            }}
                            onClick={() => {
                              setFiterData((prevData) => ({
      ...prevData,
     addUpdateFilter:"new"
    }));
                              // setAddUpdateFilter("new");
                              handleOpenAdd();
                              // handleClose();
                            }}
                          >
                            <AddIcon
                              style={{ color: "#299cdb", fontSize: 18 }}
                            />
                            Add Filter
                          </MenuItem>

                          <MenuItem
                            onMouseEnter={(e) => {
                              e.target.style.color = "#fff";
                              e.target.style.backgroundColor = "#2a5fc1";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.color = "#000";
                              e.target.style.backgroundColor = "#fff";
                            }}
                            onClick={() => {
                              setFiterData((prevData) => ({
      ...prevData,
     addUpdateFilter:"update"
    }));
                              // setAddUpdateFilter("update");
                              handleOpenAdd();
                              // handleClose();
                            }}
                          >
                            <EditIcon
                              style={{ color: "#45CB85", fontSize: 13 }}
                            />
                            Update Filter
                          </MenuItem>

                          {fiterData?._id && (
                            <>
                              <MenuItem
                                onMouseEnter={(e) => {
                                  e.target.style.color = "#fff";
                                  e.target.style.backgroundColor = "#2a5fc1";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.color = "#000";
                                  e.target.style.backgroundColor = "#fff";
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenShare(fiterData?._id);
                                  handleClose();
                                }}
                              >
                                <ShareIcon
                                  style={{ color: "#3577f1", fontSize: 14 }}
                                />
                                Share Filter
                              </MenuItem>

                              <MenuItem
                                onMouseEnter={(e) => {
                                  e.target.style.color = "#fff";
                                  e.target.style.backgroundColor = "#2a5fc1";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.color = "#000";
                                  e.target.style.backgroundColor = "#fff";
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenDelete(fiterData?._id);
                                  handleClose();
                                }}
                              >
                                <DeleteIcon
                                  style={{ color: "#f06548", fontSize: 13 }}
                                />
                                Delete Filter
                              </MenuItem>
                            </>
                          )}
                        </Menu>
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
          <Modal
            isOpen={openDelete}
            onClose={handleCloseDelete}
            centered
            contentClassName="p-4"
          >
            <>
              <div className="text-center align-items-center">
                <h2>{fiterData?.filterName}</h2>
                <button
                  type="button"
                  class="btn btn-danger me-2"
                  onClick={handleDelete}
                >
                  Delete
                </button>
                <button
                  className="btn mt-2 mb-2 btn btn-primary"
                  type="button"
                  onClick={handleCloseDelete}
                >
                  Cancel
                </button>
              </div>
            </>
          </Modal>
          <Modal
            isOpen={openShare}
            onClose={handleCloseShare}
            centered
            contentClassName="p-4"
          >
            <>
              <InputLabel htmlFor="operation-field" className="form-label">
                Share Filter
              </InputLabel>
              <Select
                id="share-field"
                getOptionLabel={(e) => e?.fullName}
                getOptionValue={(e) => e?._id}
                options={user}
                value={selectUserId}
                value={
                                Array.isArray(bodyData?.selectUserIdsArray) &&
                                typeof bodyData?.selectUserIdsArray[0] === "object"
                                  ? user.filter((option) =>
                                      bodyData?.selectUserIdsArray?.some(
                                        (tra) => tra?._id === option._id
                                      )
                                    )
                                  : user.filter((option) =>
                                      bodyData?.selectUserIdsArray?.includes(
                                        option._id
                                      )
                                    )
                              }
                onChange={(e) => {
                  // const selectedIds = selectedOptions.map(
                  //   (option) => option._id
                  // );
                  // setSelectUserId(selectedOptions);
                  // setSelectUserIdsArray(selectedIds);
handleSelectChange(e,"user")
                }}
                isMulti
              />

              <div className="text-center align-items-center">
                <button
                  type="button"
                  className="btn btn-success me-2"
                  onClick={() => {
                    handleShare(fiterData?._id);
                  }}
                >
                  Share
                </button>
                <button
                  className="btn mt-2 mb-2 btn btn-danger"
                  type="button"
                  onClick={handleCloseShare}
                >
                  Cancel
                </button>
              </div>
            </>
          </Modal>

          <Dialog
            open={bodyData?.openAdd}
            onClose={handleCloseAdd}
            aria-labelledby="add-filter-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="add-filter-dialog-title">Filter Name</DialogTitle>
            <DialogContent>
              <TextField
                label="Filter Name"
                type="text"
                name="filterName"
                fullWidth
                variant="outlined"
                margin="normal"
                value={fiterData?.filterName}
                // onChange={(e) => setSelectFiterName(e.target.value)}
                onChange={(e) =>
                  setFiterData((prevData) => ({
                    ...prevData,
                    filterName: e.target.value,
                  }))
                }
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={fiterData?.pinned}
                    onChange={(e) =>
                      setFiterData((prevData) => ({
                        ...prevData,
                        pinned: e.target.checked,
                      }))
                    }
                    name="pinned"
                    color="primary"
                  />
                }
                label="Pinned Filter"
                style={{ marginTop: "10px" }}
              />
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  handleInput(selectFiterName);
                }}
              >
                {fiterData?.addUpdateFilter === "new" ? "Add New Filter" : "Update Filter"}
              </Button>
              <Button variant="outlined" color="error" onClick={handleCloseAdd}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Paper> */}

        <Box>
          {isLoading ? (
            <Loaders />
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "5px 0px 10px 0px",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: isFocused ? 1 : 0,
                    minWidth: "120px",
                    maxWidth: "60%",
                    transition: "flex-grow 0.3s ease-in-out",
                    backgroundColor: Colors.DEFAULT_COLOR,
                    borderRadius: "8px",
                  }}
                >
                  <TextField
                    type="text"
                    name="search"
                    variant="outlined"
                    placeholder={`${
                      paginationData?.totalRecords
                        ? paginationData.totalRecords
                        : ""
                    } Search...`}
                    size="small"
                    value={search}
                    onClick={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            sx={{ fontSize: 13, marginRight: "-10px" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {/* <h3>Project Task</h3> */}
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {(!location?.search || location?.search === "") && (
                    <ButtonGroup
                      variant="contained"
                      color="primary"
                      disableElevation
                      sx={{
                        borderRadius: "8px",
                        overflow: "hidden",
                        "& .MuiButton-root": {
                          textTransform: "none",
                          transition:
                            "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
                        },
                      }}
                    >
                      <Button
                        onClick={() => {
                          setActiveButton("All");
                          if (!fiterData?.all) {
                            setFiterData((prevData) => ({
                              ...prevData,
                              all: true,
                            }));
                          }
                        }}
                        onMouseEnter={() => setHoveredButton("All")}
                        onMouseLeave={() => setHoveredButton(null)}
                        sx={{
                          borderRadius: "10px",
                          backgroundColor:
                            activeButton === "All" && fiterData?.all
                              ? Colors.PRIMARY_COLOR
                              : Colors.DEFAULT_COLOR,
                          color:
                            activeButton === "All" && fiterData?.all
                              ? Colors.DEFAULT_COLOR
                              : Colors.PRIMARY_COLOR,
                          "&:hover": {
                            backgroundColor: Colors.PRIMARY_COLOR,
                            color: Colors.DEFAULT_COLOR,
                            "& img": {
                              filter: "none",
                            },
                          },
                          whiteSpace: "nowrap",
                          minWidth: 0,
                          fontSize: isMobileScreen ? "10px" : "inherit",
                        }}
                      >
                        {isMobileScreen ? (
                          <img
                            src={DocumentIcon}
                            alt="All Data"
                            width={18}
                            height={18}
                            style={{
                              filter:
                                (activeButton === "All" && fiterData?.all) ||
                                hoveredButton === "All"
                                  ? "none"
                                  : "invert(41%) sepia(91%) saturate(5324%) hue-rotate(188deg)",
                            }}
                          />
                        ) : (
                          <>
                            {" "}
                            <img
                              src={DocumentIcon}
                              alt="All Data"
                              width={12}
                              height={12}
                              style={{
                                margin: "0px 5px 0px -5px",
                                filter:
                                  (activeButton === "All" && fiterData?.all) ||
                                  hoveredButton === "All"
                                    ? "none"
                                    : "invert(41%) sepia(91%) saturate(5324%) hue-rotate(188deg)",
                              }}
                            />
                            All Data
                          </>
                        )}
                      </Button>
                      <Button
                        onClick={() => {
                          setActiveButton("filter");
                          setOpenFilterModal(true);
                          if (fiterData?.all) {
                            setFiterData((prevData) => ({
                              ...prevData,
                              all: false,
                            }));
                          }
                        }}
                        onMouseEnter={() => setHoveredButton("filter")}
                        onMouseLeave={() => setHoveredButton(null)}
                        sx={{
                          borderRadius: "10px",
                          backgroundColor:
                            activeButton === "filter" || !fiterData?.all
                              ? Colors.PRIMARY_COLOR
                              : Colors.DEFAULT_COLOR,
                          color:
                            activeButton === "filter" || !fiterData?.all
                              ? Colors.DEFAULT_COLOR
                              : Colors.PRIMARY_COLOR,
                          "&:hover": {
                            backgroundColor: Colors.PRIMARY_COLOR,
                            color: Colors.DEFAULT_COLOR,
                            "& img": {
                              filter: "none",
                            },
                          },
                          whiteSpace: "nowrap", // Prevents text breaking
                          minWidth: 0,
                          fontSize: isMobileScreen ? "10px" : "inherit",
                        }}
                      >
                        {isMobileScreen ? (
                          <FilterIcon
                            color={
                              activeButton === "filter" ||
                              !fiterData.all ||
                              fiterData?.pinned ||
                              hoveredButton === "filter"
                                ? Colors.DEFAULT_COLOR
                                : Colors.PRIMARY_COLOR
                            }
                            style={{
                              width: "18px",
                              height: "18px",
                              transition: "color 0.3s ease-in-out",
                            }}
                          />
                        ) : (
                          <>
                            {" "}
                            <FilterIcon
                              color={
                                activeButton === "filter" ||
                                !fiterData.all ||
                                fiterData?.pinned ||
                                hoveredButton === "filter"
                                  ? Colors.DEFAULT_COLOR
                                  : Colors.PRIMARY_COLOR
                              }
                              style={{
                                width: "13px",
                                height: "13px",
                                margin: "0px 5px 0px -5px",
                                transition: "color 0.3s ease-in-out",
                              }}
                            />
                            Apply Filter
                          </>
                        )}
                      </Button>
                    </ButtonGroup>
                  )}
                  {location?.pathname !== "/followUpTask" &&
                    showAddButton &&
                    (isMobileScreen ? (
                      <Button
                        variant="contained"
                        color="success"
                        id="create-btn"
                        sx={{
                          minWidth: "30px",
                          height: "30px",
                          padding: "5px",
                          fontSize: "16px",
                          lineHeight: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={(e) => {
                          setAddEditOpen(true);
                          setId(null);
                        }}
                      >
                        +
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="success"
                        id="create-btn"
                        sx={{ whiteSpace: "nowrap" }}
                        onClick={(e) => {
                          setAddEditOpen(true);
                          setId(null);
                        }}
                      >
                        + ADD
                      </Button>
                    ))}
                  {location?.pathname !== "/followUpTask" &&
                    (isMobileScreen ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          height: "32px",
                          width: "32px",
                          borderRadius: "4px",
                          textTransform: "none",
                          border: "1px solid #4680FF",
                          cursor: "pointer",
                          backgroundColor: "white",
                        }}
                        onClick={handleViewChange}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",
                            backgroundColor: "#4680FF",
                            color: "white",
                            borderRadius: "3px",
                          }}
                        >
                          {!fiterData?.kanban ? (
                            <ViewKanbanRounded
                              sx={{ width: "60%", height: "60%" }}
                            />
                          ) : (
                            <WindowRounded
                              sx={{ width: "50%", height: "50%" }}
                            />
                          )}
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          height: "36px",
                          width: "90px",
                          borderRadius: "4px",
                          textTransform: "none",
                          border: "1px solid #4680FF",
                          cursor: "pointer",
                          backgroundColor: "white",
                        }}
                        onClick={handleViewChange}
                      >
                        <Box sx={!fiterData?.kanban ? textStyle : iconStyle}>
                          {!fiterData?.kanban ? (
                            <Typography>Kanban</Typography>
                          ) : (
                            <WindowRounded
                              sx={{ width: "50%", height: "50%" }}
                            />
                          )}
                        </Box>
                        <Box sx={!fiterData?.kanban ? iconStyle : textStyle}>
                          {!fiterData?.kanban ? (
                            <ViewKanbanRounded
                              sx={{ width: "60%", height: "60%" }}
                            />
                          ) : (
                            <Typography>Table</Typography>
                          )}
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
              {fiterData?.kanban ? (
                <KanbanTable
                  data={data}
                  columns={columns}
                  paginationData={paginationData}
                  fiterData={fiterData}
                  setFiterData={setFiterData}
                />
              ) : data?.length ? (
                <>
                  <TableContainer
                    style={{
                      maxWidth: "100%",
                    }}
                  >
                    <div
                      style={{
                        maxHeight: "59.5vh",
                        overflow: "auto",
                        scrollbarWidth: "none",
                      }}
                    >
                      <Table style={{ borderRadius: "10px" }}>
                        <TableHead
                          sx={{
                            backgroundColor: "transparent",
                            position: "sticky",
                            top: 0,
                            left: 0,
                            zIndex: 2,
                          }}
                        >
                          <TableRow
                            style={{
                              backgroundColor: Colors.PRIMARY_COLOR,
                              boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                            }}
                          >
                            {columns.map((column, rowIndex) => (
                              <TableCell
                                key={column.Header}
                                className="text-center"
                                onClick={() => handleSort(column.accessor)}
                                style={{
                                  cursor: "pointer",
                                  color: "#E5E5E5",
                                  // fontSize: "14px",
                                  padding: "12px",
                                  textAlign: "center",
                                  borderTopLeftRadius:
                                    rowIndex === 0 ? "6px" : "0px",
                                  borderTopRightRadius:
                                    rowIndex === columns.length - 1
                                      ? "6px"
                                      : "0px",
                                  borderBottomLeftRadius:
                                    rowIndex === 0 ? "6px" : "0px",
                                  borderBottomRightRadius:
                                    rowIndex === columns.length - 1
                                      ? "6px"
                                      : "0px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      minWidth:
                                        column?.type === undefined
                                          ? ""
                                          : "150px",
                                      maxWidth:
                                        column?.type === undefined
                                          ? ""
                                          : "200px",
                                    }}
                                  >
                                    {" "}
                                    {column?.Header}
                                  </span>

                                  {column?.type !== undefined && (
                                    <img
                                      src={getSortIcon(column.accessor)}
                                      alt="Sort Icon"
                                      width={20}
                                      height={20}
                                      style={{ marginLeft: "5px" }}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            ))}
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={columns.length}
                              style={{
                                backgroundColor: "transparent",
                                height: "15px",
                                padding: "0px",
                                borderBottom: "hidden",
                              }}
                            />
                          </TableRow>
                        </TableHead>

                        <TableBody
                          style={{
                            boxShadow: "0px 2px 5px rgba(0,0,0,0.3)",
                          }}
                        >
                          {data.map((item, rowIndex) => (
                            <TableRow
                              key={item.id || rowIndex}
                              style={{
                                backgroundColor: Colors.DEFAULT_COLOR,
                              }}
                            >
                              {columns.map((column, colIndex) => (
                                <TableCell
                                  key={column.accessor}
                                  style={{
                                    position: "relative",
                                    padding: "5px 12px",
                                    // textAlign: "center",
                                    color: "#333",
                                    // fontSize: "14px",
                                    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                                    borderBottomLeftRadius:
                                      rowIndex === data.length - 1 &&
                                      colIndex === 0
                                        ? "6px"
                                        : "0px",
                                    borderBottomRightRadius:
                                      rowIndex === data.length - 1 &&
                                      colIndex === columns.length - 1
                                        ? "6px"
                                        : "0px",
                                    borderTopLeftRadius:
                                      rowIndex === 0 && colIndex === 0
                                        ? "6px"
                                        : "0px",
                                    borderTopRightRadius:
                                      rowIndex === 0 &&
                                      colIndex === columns.length - 1
                                        ? "6px"
                                        : "0px",
                                  }}
                                >
                                  {column.Cell
                                    ? column.Cell({
                                        row: {
                                          original: item,
                                          index: rowIndex,
                                        },
                                      })
                                    : item[column.accessor]}

                                  {colIndex !== columns.length - 1 && (
                                    <Typography
                                      variant="span"
                                      style={{
                                        position: "absolute",
                                        width: "1px",
                                        height: "50%",
                                        backgroundColor:
                                          "rgba(219, 224, 229, 0.65)",
                                        right: 0,
                                        top: "20%",
                                      }}
                                    />
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </TableContainer>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    marginTop={3}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      style={{ marginBottom: "15px" }}
                    >
                      {isSmallScreen ? (
                        <Pagination
                          count={paginationData?.totalPages || 10}
                          page={paginationData?.currentPage || 1}
                          onChange={(event, page) => handlePageChange(page)}
                          variant="outlined"
                          shape="rounded"
                          disabled={
                            isLoading || paginationData?.totalPages === 0
                          }
                          renderItem={(item) => {
                            if (
                              item.type === "previous" ||
                              item.type === "next" ||
                              item.page === paginationData?.currentPage
                            ) {
                              return <PaginationItem {...item} />;
                            }
                            return null;
                          }}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "& .MuiPaginationItem-root": {
                              fontWeight: "bold",
                              color: "#333",
                            },
                            "& .Mui-selected": {
                              backgroundColor: `${Colors.PRIMARY_COLOR} !important`,
                              color: `${Colors.DEFAULT_COLOR} !important`,
                              fontWeight: "bold",
                              borderRadius: "8px",
                            },
                          }}
                        />
                      ) : (
                        <Pagination
                          count={paginationData?.totalPages || 10}
                          page={paginationData?.currentPage || 1}
                          onChange={(event, page) => handlePageChange(page)}
                          variant="outlined"
                          shape="rounded"
                          disabled={
                            isLoading || paginationData?.totalPages === 0
                          }
                          sx={{
                            "& .MuiPaginationItem-root": {
                              fontWeight: "bold",
                              color: "#333",
                            },
                            "& .Mui-selected": {
                              backgroundColor: `${Colors.PRIMARY_COLOR} !important`,
                              color: `${Colors.DEFAULT_COLOR} !important`,
                              fontWeight: "bold",
                              borderRadius: "8px",
                            },
                          }}
                        />
                      )}

                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="span">Go To</Typography>
                        <TextField
                          name="currentPage"
                          type="number"
                          id="currentPage-field"
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) => handleKeyDown(e)}
                          inputProps={{
                            min: 1,
                            max: paginationData?.totalPages,
                          }}
                          sx={{
                            width: 50,
                            textAlign: "center",
                            "& .MuiInputBase-input": {
                              height: "27px",
                              padding: "2px 5px",
                            },
                          }}
                          onKeyDown={handleKeyDown}
                        />
                      </Stack>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      style={{ margin: "0px 5px 15px 0px" }}
                    >
                      {isSMobileScreen ? (
                        <Typography variant="span">R/P</Typography>
                      ) : (
                        <Typography variant="span">Row/Page</Typography>
                      )}
                      <TextField
                        name="rowsPerPage"
                        type="number"
                        id="rowsPerPage-field"
                        value={
                          inputValue !== undefined || inputValue !== null
                            ? inputValue
                            : paginationData?.limit
                        }
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) =>
                          setInputValue(Number(e.target.value) || 0)
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleInput();
                          }
                        }}
                        sx={{
                          width: 60,
                          textAlign: "center",
                          "& .MuiInputBase-input": {
                            height: "27px",
                            padding: "2px 5px",
                          },
                        }}
                      />
                    </Stack>
                  </Stack>
                </>
              ) : (
                <tr>
                  <td colSpan="3">
                    <h5
                      className="text-primary text-center"
                      style={{ fontWeight: "bold" }}
                    >
                      No Data Available
                    </h5>
                  </td>
                </tr>
              )}
            </>
          )}

          {/* <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Button
                onClick={() => handlePageChange(pagination?.currentPage - 1)}
                disabled={pagination?.currentPage === 1 || isLoading}
                sx={{ backgroundColor: "#1c1c36" }}
              >
                {"<"}
              </Button>
            </Grid>

            <Grid item className="d-none d-md-block">
              <Typography
                sx={{
                  fontFamily: "Arial",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                Page{" "}
                <strong>
                  {pagination?.currentPage} of {pagination?.totalPages}
                </strong>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                name="currentPage"
                type="number"
                id="currentPage-field"
                placeholder="Page"
                onWheel={(e) => e.target.blur()}
                onChange={(e) => handleKeyDown(e)}
                inputProps={{ min: 1, max: pagination?.totalPages }}
                sx={{ width: 70, textAlign: "center" }}
                onKeyDown={handleKeyDown}
              />
            </Grid>

            <Grid item>
              <Button
                onClick={() => handlePageChange(pagination?.currentPage + 1)}
                disabled={
                  pagination?.currentPage === pagination?.totalPages ||
                  isLoading ||
                  (data?.length === 0 && pagination?.currentPage === 1)
                }
                sx={{ backgroundColor: "#1c1c36" }}
              >
                {">"}
              </Button>
            </Grid>
          </Grid> */}
        </Box>
        {/* </Paper> */}

        {/* {sidebarData?.isOpenSidebar && (
          <div>
            <div
              style={{
                position: "fixed",
                top: 0,
                right: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
              onClick={toggleSidebar}
            ></div>

            <div
              style={{
                overflowX: "auto",
                width: "250px",
                height: "95vh",
                position: "fixed",
                top: "70px",
                right: "0", // Sidebar on the right side
                backgroundColor: "#fff",
                padding: "20px",
                zIndex: 1000,
              }}
            >
              <div style={{ paddingBottom: "30px" }}></div>
              <div style={{ marginBottom: "10px" }}>
                <h5 htmlFor="operation-field" className="form-label">
                  Page size
                </h5>

                <div style={{ padding: "10px" }}>
                  <Input
                    type="number"
                    name="pageSize"
                    className="form-control me-2"
                    placeholder={"Page Size"}
                    value={pagination?.limit}
                    aria-label="Search input"
                    onChange={(e) =>
                      setPagination((prevData) => ({
                        ...prevData,
                        limit: Number(e.target.value),
                      }))
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleInput();
                      }
                    }}
                  />
                </div>
              </div>
              <div>
                <h3
                  style={{
                    marginBottom: "10px",
                    color: "#333",
                    fontSize: "16px",
                  }}
                >
                  Filtering By
                </h3>

                <div style={{ padding: "10px" }}>
                  <div className="mb-2">
                    <Autocomplete
                      id="accountName"
                      name="accountName"
                      // options={columns}
                      options={columns.filter(
                        (column) => column?.type !== undefined
                      )}
                      getOptionLabel={(e) => e.Header}
                      getOptionValue={(e) => e.accessor}
                      value={sidebarData?.selectedField}
                      onChange={(e, newValue) => {
                        setSidebarData((prevData) => ({
                          ...prevData,
                          selectedField: newValue,
                        }));
                        // setSelectedField(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              opacity: 1,
                              color: "#4c4d4f !important",
                              WebkitTextFillColor: "#4c4d4f !important",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                  {sidebarData?.selectedField && (
                    <div
                      style={{
                        position: "relative",
                        padding: "20px",
                        borderRadius: "10px",
                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                        backgroundColor: "#fff",
                        maxWidth: "400px",
                        zIndex: 1000,
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel
                        htmlFor="operation-field"
                        className="form-label"
                      >
                        Operator
                      </InputLabel>
                      <div style={{ marginBottom: "10px" }}>
                        <Select
                          labelId="operation-field-label"
                          id="operation-field"
                          value={sidebarData?.selectedFilterOperation?.value}
                          onChange={
                            (e) =>
                              setSidebarData((prevData) => ({
                                ...prevData,
                                selectedFilterOperation: {
                                  value: e.target.value,
                                  label: e.target.value,
                                },
                              }))
                            // setSelectedFilterOperation({
                            //   value: e.target.value,
                            //   label: e.target.value,
                            // })
                          }
                          label="Operation"
                        >
                          {(sidebarData?.selectedField?.type === "number"
                            ? operationNumberOption
                            : sidebarData?.selectedField?.type === "date" ||
                              sidebarData?.selectedField?.type === "datetime"
                            ? // timerTable
                              //   ? operationTimerDateOption
                              // :
                              operationDateOption
                            : operationStringOption
                          ).map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      {!noInputOptions.includes(
                        sidebarData?.selectedFilterOperation?.value
                      ) && (
                        <Input
                          type={sidebarData?.selectedField?.type || "text"}
                          onChange={(e) =>
                            setSidebarData((prevState) => ({
                              ...prevState,
                              inputFilterValue: e.target.value,
                            }))
                          }
                          value={sidebarData?.inputFilterValue}
                          placeholder="Enter search value"
                        />
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          style={{ marginRight: "5px" }}
                          // color="danger"
                          onClick={() =>
                            setSidebarData((prevState) => ({
                              ...prevState,
                              selectedField: null,
                            }))
                          }
                          // setSelectedField(null)}
                        >
                          Close
                        </Button>
                        <Button color="success" onClick={handleApply}>
                          Apply
                        </Button>
                      </div>
                    </div>
                  )}
                  {fiterData?.filters?.map((filter, index) => {
                    const key = Object.keys(filter)[0];
                    const type = accessorToTypeMap[key];
                    const options =
                      type === "number"
                        ? operationNumberOption
                        : type === "date" || type === "datetime"
                        ? // timerTable
                          //   ? operationTimerDateOption
                          //   :
                          operationDateOption
                        : operationStringOption;

                    return (
                      <div key={key}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #e0e0e0",
                            padding: "8px",
                            fontSize: "14px",
                            color: "#555",
                            cursor: "grab",
                            borderRadius: "4px",
                            marginBottom: "6px",
                            backgroundColor: "#f4f4f4",
                          }}
                          draggable
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSidebarData((prevState) => ({
                                ...prevState,
                                openModalIndex: index,
                                selectedFilterOperation: {
                                  label: filter[key].operation,
                                  value: filter[key].operation,
                                },
                                inputFilterValue: filter[key].value,
                                selectedField: null,
                              }));
                              // setOpenModalIndex(index);
                              // setSelectedFilterOperation({
                              //   label: filter[key].operation,
                              //   value: filter[key].operation,
                              // });
                              // setInputFilterValue(filter[key].value);
                              // setSelectedField({
                              //   accessor: key,
                              //   type: type,
                              // });
                              // setSelectedField(null);
                            }}
                          >
                            {accessorToHeaderMap[key]
                              ? `${accessorToHeaderMap[key]}: 
                            ${filter[key].operation} ${
                                  type === "date"
                                    ? formattedDate(new Date(filter[key].value))
                                    : filter[key].value
                                }`
                              : `${key}: ${filter[key].operation} ${filter[key].value}`}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemove(index);

                                setPagination((prevData) => ({
                                  ...prevData,
                                  currentPage: 1,
                                }));
                              }}
                              style={{
                                cursor: "pointer",
                                color: "red",
                                marginLeft: "10px",
                              }}
                            >
                              &#x2212;
                            </button>
                          </span>
                        </div>

                        {sidebarData?.openModalIndex === index && (
                          <div
                            style={{
                              position: "relative",
                              padding: "20px",
                              borderRadius: "10px",
                              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                              backgroundColor: "#fff",
                              maxWidth: "400px",
                              zIndex: 1000,
                              marginBottom: "10px",
                            }}
                          >
                            <InputLabel
                              htmlFor="operation-field"
                              className="form-label"
                            >
                              Operator
                            </InputLabel>
                            <div style={{ marginBottom: "10px" }}>
                              <Select
                                labelId="operation-field-label"
                                id="operation-field"
                                value={
                                  sidebarData?.selectedFilterOperation?.value
                                }
                                onChange={
                                  (e) =>
                                    setSidebarData((prevState) => ({
                                      ...prevState,
                                      selectedFilterOperation: {
                                        value: e.target.value,
                                        label: e.target.value,
                                      },
                                    }))
                                  // setSelectedFilterOperation({
                                  //   value: e.target.value,
                                  //   label: e.target.value,
                                  // })
                                }
                                label="Operation"
                              >
                                {options.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                            {!noInputOptions.includes(
                              sidebarData?.selectedFilterOperation?.value
                            ) && (
                              <Input
                                type={type}
                                onChange={
                                  (e) =>
                                    setSidebarData((prevState) => ({
                                      ...prevState,
                                      inputFilterValue: e.target.value,
                                    }))
                                  // setInputFilterValue(e.target.value)
                                }
                                value={sidebarData?.inputFilterValue}
                                placeholder="Enter search value"
                              />
                            )}

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                              }}
                            >
                              <Button
                                style={{ marginRight: "5px" }}
                                // color="danger"
                                onClick={() =>
                                  setSidebarData((prevState) => ({
                                    ...prevState,
                                    openModalIndex: null,
                                  }))
                                }
                              >
                                Close
                              </Button>
                              <Button
                                color="success"
                                onClick={() => handleApply(key, type, index)}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )} */}
      </Typography>
      {(!location?.search || location?.search === "") && (
        <Filter
          fiterData={fiterData}
          setFiterData={setFiterData}
          setPaginationData={setPaginationData}
          columns={columns}
          isOpenFilterModal={isOpenFilterModal}
          setOpenFilterModal={setOpenFilterModal}
          getAllFilterData={getAllFilterData}
        />
      )}
      {deleteData?.open && (
        <DeleteModal
          dataUrl={dataUrl}
          deleteData={deleteData}
          setDeleteData={setDeleteData}
          callFunc={addFilter}
          // setFiterData={setFiterData}
          // navLink={navLink}
        />
      )}
      {addEditOpen && (
        <RelatedAddEdit
          id={id}
          setId={setId}
          model={model}
          addEditModal={addEditOpen}
          setAddEditModal={setAddEditOpen}
          callFunc={addFilter}
          nav={nav}
          parentData={parentData}
          parentModel={relatedName}
        />
      )}
    </>
  );
};

export default TablePagination;

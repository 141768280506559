import { Box, useMediaQuery } from "@mui/material";
import Profile from "./Profile";
import Notification from "./Notification";
import useConfig from "hooks/useConfig";
import DrawerHeader from "layout/MainLayout/Drawer/DrawerHeader";
import Setting from "./Setting";
import withRouter from "components/withRouter";
import FullScreen from "./FullScreen";
import Navigation from "layout/MainLayout/Drawer/DrawerContent/Navigation";

const HeaderContent = (props) => {
  const { MenuOrientation } = props?.config;
  const { menuOrientation } = useConfig();
  const downLG = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && (
        <DrawerHeader open={true} />
      )}
      <Box sx={{ width: "100%", ml: 1 }} />
      {/* <Setting /> */}
      {/* <FullScreen /> */}
      <Navigation/>
      {/* <Notification /> */}
      {/* <Profile /> */}
    </>
  );
};

export default withRouter(HeaderContent);

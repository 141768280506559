import {
  Modal,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Autocomplete,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  Divider,
  Chip,
  Tooltip,
  IconButton,
  Grid,
  Checkbox,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Colors } from "services/colorConstant";
import PinIcon from "../../assets/images/icons/Pin.svg";
import PinFillIcon from "../../assets/images/icons/PinFill.svg";
import { Filter as FilterIcon, Edit as EditIcon } from "iconsax-react";
import * as url from "../../services/url_helper";
import { Toastr } from "../Toastr";
import { Constants } from "services/keyConstants";

import {
  Share,
  Delete,
  Close,
  Search as SearchIcon,
} from "@mui/icons-material";
import DeleteModal from "../DeleteModal";
import { helperFunction } from "services/helperFunction";
import { ApiServices } from "services/apiServices";
import CustomDatePicker from "components/CustomDatePicker";

const operationStringOption = [
  { value: "equals", label: "equals" },

  { value: "not equal to", label: "not equal to" },

  { value: "contains", label: "contains" },

  { value: "does not contain", label: "does not contain" },

  { value: "starts with", label: "starts with" },
];

const operationNumberOption = [
  { value: "equals", label: "equals" },

  { value: "not equal to", label: "not equal to" },

  { value: "less than", label: "less than" },

  { value: "greater than", label: "greater than" },

  { value: "less or equal", label: "less or equal" },

  { value: "greater or equal", label: "greater or equal" },
];

const operationTimerDateOption = [
  { value: "less or equal", label: "less or equal" },

  { value: "greater or equal", label: "greater or equal" },

  { value: "today", label: "today" },

  { value: "yesterday", label: "yesterday" },

  { value: "this month", label: "this month" },
  { value: "last month", label: "last month" },
  { value: "this fy", label: "this fy" },
  { value: "last fy", label: "last fy" },
  { value: "this year", label: "this year" },
];

const noInputOptions = [
  "today",
  "yesterday",
  "this month",
  "last month",
  "this fy",
  "last fy",
  "this year",
];

function Filter({
  isOpenFilterModal,
  setOpenFilterModal,
  columns,
  setPaginationData,
  fiterData,
  setFiterData,
  getAllFilterData,
}) {
  const [isOpenModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [user, setUser] = useState([]);

  const [inputFilterValue, setInputFilterValue] = useState();
  const [operation, setOperation] = useState(null);
  //   const [filterData, setFiterData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedField, setSelectedField] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [fieldName, setFieldName] = useState(fiterData?.filterName);
  // Track window height on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    userData();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleShare = (shareId) => {
    let uri = url.SHARE_FILTER + shareId;
    const body = {
      shareWith: selectedUsers ? selectedUsers : [],
    };
    ApiServices.callServicePostWithBodyData(uri, body)
      .then((response) => {
        if (response?.result?.type === "success") {
          Toastr("Filter shared successfully", Constants.SUCCESS);
          setOpenShare(false);
          setSearchTerm("");
          setSelectedUsers([]);
        } else {
          Toastr(
            response?.result?.message
              ? response?.result?.message
              : "Error Occurred"
          );
        }
      })
      .catch((error) => {
        console.warn("Call Error", error);
      });
  };

  const userData = async () => {
    try {
      let uri = url.USERS;
      const response = await ApiServices.callServiceGet(uri);

      if (response?.result?.type === "success") {
        setUser(response?.result?.users);
      } else {
        Toastr(
          response?.result?.message
            ? response?.result?.message
            : "Error Occurred"
        );
      }
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const handleAdd = () => {
    if (windowHeight <= 800) {
      setOpenFilterModal(false);
    }
    setFiterData((prevData) => ({
      ...prevData,
      filterName: null,
      filters: null,
      updateFilter: false,
      pinned: false,
      _id: null,
    }));
    setFieldName(null);

    setOpenModal(true);
  };
  const formattedDate = (dates) => {
    const dateObject = new Date(dates);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(dateObject.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleApply = (key, type, index = null) => {
    let value = selectedField?.accessor;

    if (
      selectedField?.accessor === undefined ||
      !selectedField?.accessor ||
      selectedField?.accessor === null ||
      operation === undefined ||
      !operation ||
      operation === null
    ) {
      return;
    }
    const newFilter = {
      [value]: {
        operation: operation,
        value:
          selectedField?.type === "number"
            ? Number(inputFilterValue || 0)
            : selectedField?.type === "date"
            ? formattedDate(inputFilterValue)
            : inputFilterValue,
      },
    };

    if (!fiterData?.filterName || fiterData?.filterName === "DEFAULT") {
      if (selectedField?.index !== null && selectedField?.index !== undefined) {
        const updatedFilters = [...(fiterData?.filters || [])];
        updatedFilters[selectedField?.index] = newFilter;
        setFiterData((prevData) => ({
          ...prevData,
          filters: updatedFilters,
        }));
      } else {
        setFiterData((prevData) => ({
          ...prevData,
          filters: [...(prevData?.filters || []), newFilter],
        }));
      }
    } else {
      if (selectedField?.index !== null && selectedField?.index !== undefined) {
        const updatedFilters = [...fiterData?.filters];
        updatedFilters[selectedField?.index] = newFilter;
        setFiterData((prevData) => ({
          ...prevData,
          filters: updatedFilters,
        }));
      } else {
        setFiterData((prevData) => ({
          ...prevData,
          filters: [...(prevData?.filters || []), newFilter],
        }));
      }
    }

    setPaginationData((prevData) => ({
      ...prevData,
      currentPage: 1,
    }));
    setOperation(null);
    setInputFilterValue("");
    setSelectedField(null);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredOptions = columns.filter(
    (column) =>
      column?.type !== undefined &&
      column?.Header?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredUsers = user?.filter((users) => {
    return users?.fullName?.toLowerCase().includes(searchTerm?.toLowerCase());
  });

  const options =
    selectedField?.type === "number"
      ? operationNumberOption
      : selectedField?.type === "date" || selectedField?.type === "datetime"
      ? operationTimerDateOption
      : operationStringOption;

  const handleRemove = (index) => {
    const updatedFilters = fiterData?.filters.filter((_, i) => i !== index);

    setFiterData((prevData) => ({
      ...prevData,

      filters: updatedFilters,
    }));
  };

  const accessorToHeaderMap = columns.reduce((acc, column) => {
    acc[column.accessor] = column.Header;
    return acc;
  }, {});

  const accessorToTypeMap = columns.reduce((acc, column) => {
    acc[column.accessor] = column?.type;

    return acc;
  }, {});

  const handleInput = (value) => {
    let x = [...fiterData?.allFiterData, ...fiterData?.defaulFilter];

    if (
      (!fieldName && value === "new") ||
      fiterData?.filterName === "DEFAULT" ||
      (value === "new" && x?.some((item) => item?.filterName === fieldName)) ||
      (value === "update" &&
        x?.some(
          (item) =>
            item?._id != fiterData?._id && item?.filterName === fieldName
        ))
    ) {
      Toastr("Filter Name already exist");
      // setOpenModal(false);
      return;
    }

    let addEdit = {
      ...fiterData,
      _id: fiterData?._id,
      save: value,
      filterName: fieldName || fiterData?.filterName,
      pinned: fiterData?.pinned,
    };

    setFiterData(addEdit);

    setOpenModal(false);
  };

  const handleUserSelection = (userId) => {
    setSelectedUsers(
      (prevSelected) =>
        prevSelected.includes(userId)
          ? prevSelected.filter((id) => id !== userId) // Remove if already selected
          : [...prevSelected, userId] // Add if not selected
    );
  };

  const handleOpenDelete = (id, name) => {
    setDeleteData({ open: true, id: id, title: name });
    setOpenDelete(true);
  };

  return (
    <>
      {windowWidth >= 500 && windowHeight <= 800 ? (
        <Modal
          open={isOpenModal}
          onClose={() => {
            setOpenModal(false);
            setFiterData((prevData) => ({
              ...prevData,
              updateFilter: null,
            }));
          }}
          style={{
            zIndex: 2,
            maxHeight: windowWidth >= 800 ? 540 : 320,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: windowWidth >= 800 ? "360px" : "320px",
              left: "calc(100% - 260px)",
              transform: "translate(-50%, -50%)",
              width: 450,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 3,
              borderRadius: 2,
              overflow: "auto",
              maxHeight: windowWidth >= 800 ? "540px" : "320px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Select Column
                </Typography>

                <Box
                  sx={{
                    maxHeight: 150,
                    overflowY: "auto",
                    border: `1px solid ${Colors.PRIMARY_MEDIUM_COLOR}`,
                    borderRadius: "8px",
                    marginBottom: 2,
                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <TextField
                    placeholder="Search column"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearch}
                    sx={{
                      marginBottom: 1,
                      padding: "10px 10px 0px 10px",
                    }}
                    InputProps={{
                      sx: {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "4px",
                        },
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon
                            sx={{
                              color: Colors.PRIMARY_COLOR,
                              borderLeft: "1px solid black",
                              paddingLeft: "10px",
                            }}
                          />{" "}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <List>
                    {filteredOptions.map((option, index) => (
                      <React.Fragment key={option.accessor}>
                        <ListItem disablePadding>
                          <ListItemButton
                            sx={{
                              margin: "5px 10px",
                              padding: "5px",
                              borderRadius: "0px",
                              borderRight:
                                selectedField?.accessor === option.accessor
                                  ? `2px solid ${Colors.PRIMARY_COLOR}`
                                  : "none",
                              fontSize: "12px",
                              backgroundColor:
                                selectedField?.accessor === option.accessor
                                  ? Colors.PRIMARY_LIGHT_COLOR
                                  : "inherit",
                              color:
                                selectedField?.accessor === option.accessor
                                  ? Colors.PRIMARY_COLOR
                                  : "inherit",
                              "&:hover": {
                                backgroundColor: Colors.PRIMARY_LIGHT_COLOR,
                                color: Colors.PRIMARY_COLOR,
                                borderRight: `2px solid ${Colors.PRIMARY_COLOR}`,
                              },
                            }}
                            onClick={() => setSelectedField(option)}
                          >
                            {option.Header}
                          </ListItemButton>
                        </ListItem>
                        {index < filteredOptions.length - 1 && (
                          <Divider sx={{ margin: "0 10px" }} />
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </Box>
                {fiterData?.filters?.length > 0 && (
                  <Box
                    sx={{
                      maxHeight: 150,
                      overflowY: "auto",
                      border: `1px solid ${Colors.PRIMARY_MEDIUM_COLOR}`,
                      borderRadius: "8px",
                      padding: "10px",
                      boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {fiterData?.filters?.map((filter, index) => {
                      const key = Object.keys(filter)[0];
                      const ownerId = localStorage?.getItem("userId");

                      const type = accessorToTypeMap[key];

                      return (
                        <Box>
                          <Box
                            key={index}
                            sx={{
                              padding: "6px 12px",
                              marginBottom: 1,
                              backgroundColor: Colors.PRIMARY_LIGHT_COLOR,
                              borderRadius: "4px",
                              border:
                                selectedField?.index === index
                                  ? `1px solid ${Colors.PRIMARY_MEDIUM_COLOR}`
                                  : "",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            onClick={() => {
                              setSelectedField({
                                accessor: key,
                                Header: accessorToHeaderMap[key],
                                type: type,
                                index: index,
                              });
                              setOperation(filter[key].operation);
                              setInputFilterValue(filter[key].value);
                              //   handleApply((index = index));
                            }}
                          >
                            <Typography
                              variant="span"
                              style={{ fontSize: "12px" }}
                            >
                              {accessorToHeaderMap[key]
                                ? `${accessorToHeaderMap[key]}- 

                            ${filter[key].operation} -${
                                    type === "date" || type === "datetime"
                                      ? helperFunction.dateFormat(
                                          filter[key].value
                                        )
                                      : filter[key].value
                                  }`
                                : `${key} - ${filter[key].operation} - ${
                                    filter[key].value === ownerId
                                      ? fiterData?.filterName
                                      : filter[key]?.value
                                  }`}
                            </Typography>
                            <Typography
                              onClick={(e) => {
                                handleRemove(index);

                                setPaginationData((prevData) => ({
                                  ...prevData,

                                  currentPage: 1,
                                }));
                              }}
                              variant="span"
                              color="black"
                              style={{ cursor: "pointer" }}
                            >
                              ✕
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Grid>
              <Grid item xs={6}>
                {/* Display selected columns */}

                {/* Operations */}
                <Typography
                  variant="subtitle1"
                  sx={{ marginBottom: 1 }}
                  component="div"
                >
                  Operations
                </Typography>
                <RadioGroup
                  value={operation}
                  onChange={(e) => setOperation(e.target.value)}
                  sx={{ margin: "10px 10px" }}
                >
                  {options.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={
                        <Radio sx={{ padding: "5px", marginRight: "8px" }} />
                      }
                      label={option?.label}
                      sx={{
                        fontSize: "12px", // Text size for labels
                        ".MuiTypography-root": { fontSize: "12px" }, // Ensure consistent font size
                      }}
                    />
                  ))}
                </RadioGroup>

                {/* Filter Input */}

                <>
                  {!noInputOptions.includes(operation) &&
                    (selectedField?.type === "date" ||
                    selectedField?.type === "datetime" ? (
                      <>
                        <CustomDatePicker
                          value={inputFilterValue}
                          onChange={(date) => setInputFilterValue(date)}
                        />
                      </>
                    ) : (
                      <TextField
                        fullWidth
                        type={selectedField?.type || "text"}
                        label="Enter Value"
                        value={inputFilterValue}
                        onChange={(e) => setInputFilterValue(e.target.value)}
                        sx={{ marginBottom: 2 }}
                      />
                    ))}
                </>

                {/* Filter Name */}
                <TextField
                  fullWidth
                  label="Enter Filter Name"
                  defaultValue={fieldName ? fieldName : fiterData?.filterName}
                  onChange={(e) =>
                    // setFiterData((prevData) => ({
                    //   ...prevData,
                    //   filterName: e.target.value,
                    // }))
                    setFieldName(e.target.value)
                  }
                  sx={{ marginBottom: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={fiterData?.pinned ? PinFillIcon : PinIcon}
                          alt="pin icon"
                          width={16}
                          height={16}
                          style={{
                            margin: "0px 5px 0px -5px",
                            transform: "rotate(0deg)",
                            transition: "transform 0.3s ease-in-out",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setFiterData((prev) => ({
                              ...prev,
                              pinned: !prev.pinned,
                              save: "update",
                            }))
                          } // Toggle pinned value
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            {/* Buttons */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button variant="outlined" onClick={handleApply}>
                Apply
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleInput("new");
                }}
              >
                Add
              </Button>
              {fiterData?._id !== fiterData?.filterName &&
                fiterData?.updateFilter !== false &&
                fiterData?._id !== null &&
                fiterData?._id !== undefined && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleInput("update");
                    }}
                  >
                    Update
                  </Button>
                )}
            </Box>
          </Box>
        </Modal>
      ) : (
        <Modal
          open={isOpenModal}
          onClose={() => {
            setOpenModal(false);
            setFiterData((prevData) => ({
              ...prevData,
              updateFilter: null,
            }));
          }}
          style={{
            zIndex: 2,
            maxHeight: 500,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "470px",
              left: "calc(100% - 540px)",
              transform: "translate(-50%, -50%)",
              width: 300,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 3,
              borderRadius: 2,
              overflow: "auto",
              maxHeight: "650px",

              "@media (max-width: 600px)": {
                left: "50%",
                top: "55%",
                width: "90%",
                transform: "translate(-50%, -50%)",
                maxHeight: "400px",
                Width: "250px",
              },
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Select Column
            </Typography>

            {/* Select Column */}
            <Box
              sx={{
                maxHeight: 150,
                overflowY: "auto",
                border: `1px solid ${Colors.PRIMARY_MEDIUM_COLOR}`,
                borderRadius: "8px",
                marginBottom: 2,
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <TextField
                placeholder="Search column"
                variant="outlined"
                size="small"
                fullWidth
                value={searchTerm}
                onChange={handleSearch}
                sx={{
                  marginBottom: 1,
                  padding: "10px 10px 0px 10px",
                }}
                InputProps={{
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "4px",
                    },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon
                        sx={{
                          color: Colors.PRIMARY_COLOR,
                          borderLeft: "1px solid black",
                          paddingLeft: "10px",
                        }}
                      />{" "}
                    </InputAdornment>
                  ),
                }}
              />

              <List>
                {filteredOptions.map((option, index) => (
                  <React.Fragment key={option.accessor}>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          margin: "5px 10px",
                          padding: "5px",
                          borderRadius: "0px",
                          borderRight:
                            selectedField?.accessor === option.accessor
                              ? `2px solid ${Colors.PRIMARY_COLOR}`
                              : "none",
                          fontSize: "12px",
                          backgroundColor:
                            selectedField?.accessor === option.accessor
                              ? Colors.PRIMARY_LIGHT_COLOR
                              : "inherit",
                          color:
                            selectedField?.accessor === option.accessor
                              ? Colors.PRIMARY_COLOR
                              : "inherit",
                          "&:hover": {
                            backgroundColor: Colors.PRIMARY_LIGHT_COLOR,
                            color: Colors.PRIMARY_COLOR,
                            borderRight: `2px solid ${Colors.PRIMARY_COLOR}`,
                          },
                        }}
                        onClick={() => setSelectedField(option)}
                      >
                        {option.Header}
                      </ListItemButton>
                    </ListItem>
                    {index < filteredOptions.length - 1 && (
                      <Divider sx={{ margin: "0 10px" }} />
                    )}
                  </React.Fragment>
                ))}
              </List>
            </Box>

            {fiterData?.filters?.length > 0 && (
              <Box
                sx={{
                  maxHeight: 150,
                  overflowY: "auto",
                  border: `1px solid ${Colors.PRIMARY_MEDIUM_COLOR}`,
                  borderRadius: "8px",
                  padding: "10px",
                  boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                }}
              >
                {fiterData?.filters?.map((filter, index) => {
                  const key = Object.keys(filter)[0];
                  const ownerId = localStorage.getItem("userId");
                  const type = accessorToTypeMap[key];
                  const options =
                    type === "number"
                      ? operationNumberOption
                      : type === "date" || type === "datetime"
                      ? operationTimerDateOption
                      : // operationDateOption
                        operationStringOption;

                  return (
                    <Box>
                      <Box
                        key={index}
                        sx={{
                          padding: "6px 12px",
                          marginBottom: 1,
                          backgroundColor: Colors.PRIMARY_LIGHT_COLOR,
                          borderRadius: "4px",
                          border:
                            selectedField?.index === index
                              ? `1px solid ${Colors.PRIMARY_MEDIUM_COLOR}` // Highlight border when selected
                              : "", // Default border
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        onClick={() => {
                          setSelectedField({
                            accessor: key,
                            Header: accessorToHeaderMap[key],
                            type: type,
                            index: index,
                          });
                          setOperation(filter[key].operation);
                          setInputFilterValue(filter[key].value);
                        }}
                      >
                        <Typography variant="span" style={{ fontSize: "12px" }}>
                          {accessorToHeaderMap[key]
                            ? `${accessorToHeaderMap[key]}- 

                            ${filter[key].operation} -${
                                type === "date" || type === "datetime"
                                  ? helperFunction.dateFormat(filter[key].value)
                                  : filter[key].value
                              }`
                            : `${key} - ${filter[key].operation} - ${
                                filter[key].value === ownerId
                                  ? fiterData?.filterName
                                  : filter[key]?.value
                              }`}
                        </Typography>
                        <Typography
                          onClick={(e) => {
                            handleRemove(index);

                            setPaginationData((prevData) => ({
                              ...prevData,

                              currentPage: 1,
                            }));
                          }}
                          variant="span"
                          color="black"
                          style={{ cursor: "pointer" }}
                        >
                          ✕
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}

            {/* Operations */}
            <Typography
              variant="subtitle1"
              sx={{ marginBottom: 1 }}
              component="div"
            >
              Operations
            </Typography>
            <RadioGroup
              value={operation}
              onChange={(e) => setOperation(e.target.value)}
              sx={{ margin: "10px 10px" }}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={
                    <Radio sx={{ padding: "5px", marginRight: "8px" }} />
                  }
                  label={option.label}
                  sx={{
                    fontSize: "12px",
                    ".MuiTypography-root": { fontSize: "12px" },
                  }}
                />
              ))}
            </RadioGroup>

            {/* Filter Input */}

            {!noInputOptions.includes(operation) &&
              (selectedField?.type === "date" ? (
                <CustomDatePicker
                  value={inputFilterValue}
                  onChange={(date) => setInputFilterValue(date)}
                />
              ) : (
                <TextField
                  fullWidth
                  type={selectedField?.type || "text"}
                  label="Enter Value"
                  value={inputFilterValue}
                  onChange={(e) => setInputFilterValue(e.target.value)}
                  sx={{ marginBottom: 2 }}
                />
              ))}

            {/* Filter Name */}

            <TextField
              fullWidth
              label="Enter Filter Name"
              defaultValue={fiterData?.filterName}
              onChange={(e) =>
                // setFiterData((prevData) => ({
                //   ...prevData,
                //   filterName: e.target.value,
                // }))
                setFieldName(e.target.value)
              }
              sx={{ marginBottom: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={
                        // fiterData?.pinned?.[filterName] ? PinFillIcon : PinIcon
                        fiterData?.pinned ? PinFillIcon : PinIcon
                      }
                      alt="pin icon"
                      width={16}
                      height={16}
                      style={{
                        margin: "0px 5px 0px -5px",
                        transform: "rotate(0deg)",
                        transition: "transform 0.3s ease-in-out",
                        cursor: "pointer",
                      }}
                      onClick={
                        () =>
                          setFiterData((prev) => ({
                            ...prev,
                            pinned: !prev.pinned,
                            save: "update",
                          }))
                        // setFiterData((prev) => ({
                        //   ...prev,
                        //   pinned: {
                        //     ...prev.pinned,
                        //     // [filterName]: !prev.pinned?.[filterName],
                        //   },
                        // }))
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />

            {/* Buttons */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
              }}
            >
              <Button variant="outlined" onClick={handleApply}>
                Apply
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleInput("new");
                }}
              >
                Add
              </Button>

              {fiterData?._id !== fiterData?.filterName &&
                fiterData?.updateFilter !== false &&
                fiterData?._id !== null &&
                fiterData?._id !== undefined && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleInput("update");
                    }}
                  >
                    Update
                  </Button>
                )}
            </Box>
          </Box>
        </Modal>
      )}
      <Modal
        open={isOpenFilterModal}
        onClose={() => setOpenFilterModal(false)}
        style={{ zIndex: 2, maxHeight: "400px" }}
      >
        <Box
          sx={{
            p: 2,
            border: "1px solid #ccc",
            borderRadius: "8px",
            width: "350px",
            position: "absolute",
            top: "300px",
            left: "calc(100% - 200px)",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            maxHeight: "300px ",
            overflow: "auto",
          }}
        >
          {/* Saved Filters */}

          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            marginBottom={1}
          >
            <Grid item>
              <Typography variant="h5">Saved</Typography>
            </Grid>

            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                style={{ borderRadius: "4px", padding: "2px 5px" }}
                onClick={() => {
                  handleAdd();
                }}
              >
                <FilterIcon
                  style={{ width: "12px", height: "12px", marginRight: "5px" }}
                />
                <Typography variant="span" style={{ fontSize: "12px" }}>
                  Add Filter
                </Typography>
              </Button>
              <Typography
                variant="span"
                style={{ marginLeft: "10px", paddingTop: "7px" }}
              >
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenFilterModal(false)}
                />
              </Typography>
            </Grid>
          </Grid>

          <Box>
            {/* Filter Options */}
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              {[
                { filterName: "DEFAULT", _id: null, filters: null },
                ...(fiterData?.defaulFilter ?? []),
                ...(fiterData?.allFiterData ?? []),
              ].map((filter, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "25px",
                    padding: "4px 12px",
                    backgroundColor:
                      (filter?.pinned &&
                        fiterData?.filterName === filter?.filterName) ||
                      fiterData?._id === filter?._id ||
                      (!fiterData?._id && filter?.filterName === "DEFAULT")
                        ? Colors.PRIMARY_LIGHT_COLOR
                        : "white",
                  }}
                >
                  <Typography variant="span" sx={{ marginRight: "10px" }}>
                    {filter?._id === undefined
                      ? // ? { filterName: "DEFAULT", _id: null }
                        fiterData?.defaulFilter[0]?.filterName
                        ? fiterData?.defaulFilter[0]
                        : { filterName: "DEFAULT", _id: filter?.filterName }
                      : filter?.filterName}
                  </Typography>
                  <Checkbox
                    checked={
                      (filter?.pinned &&
                        fiterData?.filterName === filter?.filterName) ||
                      fiterData?._id === filter?._id ||
                      (!fiterData?._id && filter?.filterName === "DEFAULT")
                    }
                    onChange={() => {
                      setFiterData((prevData) => ({
                        ...prevData,
                        ...filter,
                      }));
                      setFieldName(filter?.filterName);
                      setPaginationData((prevData) => ({
                        ...prevData,
                        currentPage: 1,
                      }));
                    }}
                    sx={{
                      padding: 0,
                      width: "17px",
                      height: "17px",
                      "& .MuiSvgIcon-root": {
                        fontSize: "17px",
                      },
                    }}
                  />
                </Box>
              ))}
            </Box>

            {/* Applied Filters */}
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ mt: 2 }}
              component="div"
            >
              Applied
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {fiterData?.filters?.map((filter, index) => {
                const key = Object.keys(filter)[0];
                const type = accessorToTypeMap[key];
                const ownerId = localStorage.getItem("userId");

                return (
                  <Chip
                    key={key}
                    label={`${accessorToHeaderMap[key] || key} - ${
                      filter[key].operation
                    } - ${
                      type === "date" || type === "datetime"
                        ? helperFunction?.dateFormat(filter[key].value)
                        : filter[key]?.value === ownerId
                        ? fiterData?.filterName
                        : filter[key]?.value
                    }`}
                    onDelete={() => handleRemove(index)} // Delete function
                    color="primary"
                    variant="outlined"
                    sx={{ justifyContent: "space-between" }}
                  />
                );
              })}
            </Box>
          </Box>

          {/* Action Icons */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
            {fiterData?._id && fiterData?._id !== fiterData?.filterName && (
              <Tooltip title="Share">
                <IconButton
                  color="primary"
                  sx={{
                    opacity: fiterData?.filters?.length > 0 ? 1 : 0.5,
                    pointerEvents:
                      fiterData?.filters?.length > 0 ? "auto" : "none",
                  }}
                  onClick={() => {
                    setOpenShare(true);
                  }}
                >
                  <Share />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Edit">
              <IconButton
                color="success"
                // sx={{
                //   opacity: fiterData?.filters?.length > 0 ? 1 : 0.5, // Change opacity based on condition
                //   pointerEvents:
                //     fiterData?.filters?.length > 0 ? "auto" : "none", // Disable pointer events when no filterData
                // }}
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            {fiterData?._id && fiterData?._id !== fiterData?.filterName && (
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  sx={{
                    marginLeft: "5px",
                    cursor: "pointer",
                    marginTop: "8px",
                    height: "20px",
                    width: "20px",
                    // opacity: fiterData?.filters?.length > 0 ? 1 : 0.5, // Change opacity based on condition
                    // pointerEvents:
                    //   fiterData?.filters?.length > 0 ? "auto" : "none", // Disable pointer events when no filterData
                  }}
                  onClick={(e) => {
                    e.preventDefault();

                    handleOpenDelete(fiterData?._id, fiterData?.filterName);
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openShare}
        onClose={() => {
          setOpenShare(false);
          // setFiterData((prevData) => ({
          //   ...prevData,
          //   updateFilter: null,
          // }));
        }}
        style={{
          zIndex: 2,
          maxHeight: windowWidth >= 800 ? 540 : 300,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: windowWidth >= 800 ? "550px" : "550px",
            left: "calc(100% - 200px)",
            transform: "translate(-50%, -50%)",
            width: 360,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            overflow: "auto",
            maxHeight: windowWidth >= 800 ? "540px" : "320px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Select User
              </Typography> */}

              <Box
                sx={{
                  maxHeight: 140,
                  overflowY: "auto",
                  border: `1px solid ${Colors.PRIMARY_MEDIUM_COLOR}`,
                  borderRadius: "8px",
                  marginBottom: 2,
                  boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                }}
              >
                <TextField
                  placeholder="Search column"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchTerm}
                  onChange={handleSearch}
                  sx={{
                    // marginBottom: 1,
                    padding: "10px 10px 0px 10px",
                  }}
                  InputProps={{
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "4px",
                      },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon
                          sx={{
                            color: Colors.PRIMARY_COLOR,
                            borderLeft: "1px solid black",
                            paddingLeft: "10px",
                          }}
                        />{" "}
                      </InputAdornment>
                    ),
                  }}
                />

                <List>
                  {filteredUsers?.map((option, index) => (
                    <React.Fragment key={option.id || index}>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{
                            margin: "5px 10px",
                            padding: "5px",
                            borderRadius: "0px",
                            borderRight: selectedUsers.includes(option?._id)
                              ? `2px solid ${Colors.PRIMARY_COLOR}`
                              : "none",
                            fontSize: "12px",
                            backgroundColor: selectedUsers.includes(option?._id)
                              ? Colors.PRIMARY_LIGHT_COLOR
                              : "inherit",
                            color: selectedUsers.includes(option?._id)
                              ? Colors.PRIMARY_COLOR
                              : "inherit",
                            "&:hover": {
                              backgroundColor: Colors.PRIMARY_LIGHT_COLOR,
                              color: Colors.PRIMARY_COLOR,
                              borderRight: `2px solid ${Colors.PRIMARY_COLOR}`,
                            },
                          }}
                          onClick={() => handleUserSelection(option?._id)}
                        >
                          {option?.fullName}
                        </ListItemButton>
                      </ListItem>
                      {index < filteredUsers?.length - 1 && (
                        <Divider sx={{ margin: "0 10px" }} />
                      )}
                    </React.Fragment>
                  ))}
                </List>
              </Box>
              {selectedUsers?.length > 0 && (
                <Box
                  sx={{
                    maxHeight: 150,
                    overflowY: "auto",
                    border: `1px solid ${Colors.PRIMARY_MEDIUM_COLOR}`,
                    borderRadius: "8px",
                    padding: "10px",
                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {Array.from(
                    { length: Math.ceil(selectedUsers?.length / 2) },
                    (_, rowIndex) => {
                      const rowUsers = selectedUsers?.slice(
                        rowIndex * 2,
                        rowIndex * 2 + 2
                      );

                      return (
                        <Box
                          key={rowIndex}
                          sx={{
                            display: "flex",
                            gap: "10px",
                            marginBottom: "8px",
                            flexWrap: "wrap",
                          }}
                        >
                          {rowUsers?.map((selectedUserId) => {
                            const selectedUser = user?.find(
                              (usr) => usr?._id === selectedUserId
                            );

                            return (
                              selectedUser && (
                                <Box
                                  key={selectedUser.id}
                                  sx={{
                                    flex: "1",
                                    minWidth: "45%", // Ensures two per row
                                    padding: "6px 12px",
                                    backgroundColor: Colors.PRIMARY_LIGHT_COLOR,
                                    borderRadius: "4px",
                                    border: `1px solid ${Colors.PRIMARY_MEDIUM_COLOR}`,
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    variant="span"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {selectedUser.fullName}
                                  </Typography>
                                  <Typography
                                    onClick={() => {
                                      setSelectedUsers((prev) =>
                                        prev.filter(
                                          (id) => id !== selectedUserId
                                        )
                                      );
                                    }}
                                    variant="span"
                                    color="black"
                                    style={{ cursor: "pointer" }}
                                  >
                                    ✕
                                  </Typography>
                                </Box>
                              )
                            );
                          })}
                        </Box>
                      );
                    }
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={() => handleShare(fiterData?._id)}
              sx={{ marginRight: "10px", fontSize: "12px" }}
            >
              Apply
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpenShare(false);
                setSearchTerm("");
                setSelectedUsers([]);
              }}
              sx={{ fontSize: "12px" }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <DeleteModal
        dataUrl={url.Delete_FILTER}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        callFunc={getAllFilterData}
      />
    </>
  );
}

export default Filter;

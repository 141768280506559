import React, { useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import * as url from "../../../services/url_helper";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import BreadCrumb from "components/BreadCrumb";
import CustomLink from "components/CustomLink";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/deleteRed.svg";

import SimpleTable from "components/SimpleTable";
import { getObjectPermissions } from "services/utils";

const Currency = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [addEditOpen, setAddEditOpen] = useState(false);
  const [id, setId] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const location = useLocation();
  const permission = getObjectPermissions().currency;

  const handleOpenDelete = (id, name) => {
    setDeleteData({ open: true, id: id, title: name });
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: "Currency Name",
          accessor: "isoCode",
          type: "string",
          Cell: ({ row }) => (
            <CustomLink
              to={
                row.original?._id ? `/currency/view/${row.original?._id}` : "#"
              }
            >
              <div className="name">{row.original?.isoCode}</div>
            </CustomLink>
          ),
        },
        {
          Header: "Conversion Rate",
          accessor: "conversionRate",
          type: "string",
          Cell: ({ row }) => (
            <div className="name">{row.original?.conversionRate}</div>
          ),
        },
        {
          Header: "Decimal Places",
          accessor: "decimalPlaces",
          type: "string",
          Cell: ({ row }) => (
            <div className="name">{row.original?.decimalPlaces}</div>
          ),
        },
        permission[0] >= 1 && {
          Header: "Action",
          accessor: "action",
          Cell: ({ row }) => (
            <Stack direction="row" spacing={1}>
              <CustomLink to={`/currency/view/${row.original?._id}`} newTab>
                <Tooltip title="View">
                  <IconButton color="primary">
                    <OpenInNewIcon />
                  </IconButton>
                </Tooltip>
              </CustomLink>
              <Tooltip title="Edit">
                <IconButton
                  color="success"
                  onClick={() => {
                    setAddEditOpen(true);
                    setId(row.original?._id);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              {permission[0] >= 3 && (
                <Tooltip title="Delete">
                  <IconButton
                    color="error"
                    onClick={() =>
                      handleOpenDelete(row.original?._id, row.original?.isoCode)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          ),
        },
      ].filter(Boolean),
    [permission]
  );

  return (
    <div className="page-content" style={{ height: "100%" }}>
      <BreadCrumb title="Currency" currentPage="RegTech" />
      {isLoading ? (
        "Loading..."
      ) : (
        <Box id="currencyList">
          {permission ? (
            location.pathname === "/currency" ? (
              <SimpleTable
                column={columns}
                addEditOpen={addEditOpen}
                setAddEditOpen={setAddEditOpen}
                dataUrl={url.CURRENCY}
                setDeleteData={setDeleteData}
                deleteData={deleteData}
                id={id}
                setId={setId}
                modal={"currency"}
              />
            ) : (
              <h5
                className="text-primary text-center"
                style={{ fontWeight: "bold" }}
              >
                No Data Available
              </h5>
            )
          ) : (
            <h5
              className="text-primary text-center"
              style={{ fontWeight: "bold" }}
            >
              No Access
            </h5>
          )}
        </Box>
      )}
    </div>
  );
};

export default Currency;

import { Link, Typography, Icon } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ArrowRight2, Home } from "iconsax-react";

const Breadcrumb = ({
  title,
  currentPage,
  secondPage,
  thirdPage,
  currentLink,
  secondLink,
}) => {
  let lastPage = currentPage;

  if (thirdPage) {
    lastPage = thirdPage;
  } else if (secondPage) {
    lastPage = secondPage;
  }

  const breadcrumbStyles = {
    lastPageStyle: {
      color: "#4680FF",
      fontWeight: 550,
      fontSize: { xs: "13px", sm: "15px" },
    },
    nonLastStyle: {
      color: "#5B6B79",
      fontWeight: 400,
      fontSize: { xs: "12px", sm: "14px" },
    },
  };

  return (
    (title || currentPage) && (
      <>
        <div
          style={{
            margin: "0px -24px",
            padding: "15px 15px 15px 24px",
            display: "flex",
            justifyContent: "space-between",
            // backgroundColor: "#FFFFFF",
            height: "48px",
            // zIndex: 1000,
            "@media (max-width: 768px)": {
              padding: "10px",
            },
            "@media (max-width: 450px)": {
              padding: "5px",
            },
          }}
        >
          {title && (
            <Link
              color="inherit"
              component={RouterLink}
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "450",
                // paddingLeft: "35px",
                fontSize: "18px",
                "&:hover": { textDecoration: "none", cursor: "default" },
                "@media (max-width: 768px)": {
                  fontSize: "16px",
                  paddingLeft: "20px",
                },
                "@media (max-width: 450px)": {
                  fontSize: "14px",
                  paddingLeft: "10px",
                },
              }}
            >
              {title}
            </Link>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingRight: "24px",
              // fontSize: "16px",
              "@media (max-width: 768px)": {
                paddingRight: "20px",
                // fontSize: "14px",
              },
              "@media (max-width: 450px)": {
                paddingRight: "10px",
                // fontSize: "12px",
              },
            }}
          >
            {currentPage && (
              <Icon
                sx={{
                  width: 25,
                  height: 25,
                  paddingRight: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#5B6B79",
                  "@media (max-width: 768px)": {
                    width: 22,
                    height: 22,
                  },
                  "@media (max-width: 450px)": {
                    width: 20,
                    height: 20,
                  },
                }}
              >
                <Home sx={{ width: "50%", height: "50%" }} />
              </Icon>
            )}
            {currentLink ? (
              <Link
                color="inherit"
                component={RouterLink}
                to={`/${currentLink}`}
                sx={{
                  ...(lastPage === currentPage
                    ? breadcrumbStyles.lastPageStyle
                    : breadcrumbStyles.nonLastStyle),
                  "&:hover": {
                    textDecoration: "none",
                    cursor: "pointer",
                  },
                }}
              >
                {currentPage}
              </Link>
            ) : (
              <Typography
                variant="span"
                sx={{
                  ...(lastPage === currentPage
                    ? breadcrumbStyles.lastPageStyle
                    : breadcrumbStyles.nonLastStyle),
                  // cursor: "pointer",
                }}
              >
                {currentPage}
              </Typography>
            )}

            {secondPage && (
              <>
                <Icon
                  sx={{
                    width: 15,
                    height: 15,
                    margin: 1,
                    color: "#5B6B79",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ArrowRight2 sx={{ width: "100%", height: "100%" }} />
                </Icon>
                {secondLink ? (
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to={`/${secondLink}`}
                    sx={{
                      ...(lastPage === secondPage
                        ? breadcrumbStyles.lastPageStyle
                        : breadcrumbStyles.nonLastStyle),
                      "&:hover": {
                        textDecoration: "none",
                        cursor: "pointer",
                      },
                    }}
                  >
                    {secondPage}
                  </Link>
                ) : (
                  <span
                    style={{
                      ...(lastPage === secondPage
                        ? breadcrumbStyles.lastPageStyle
                        : breadcrumbStyles.nonLastStyle),
                    }}
                  >
                    {secondPage}
                  </span>
                )}
              </>
            )}

            {thirdPage && (
              <>
                <Icon
                  sx={{
                    width: 15,
                    height: 15,
                    margin: 1,
                    color: "#5B6B79",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ArrowRight2 sx={{ width: "100%", height: "100%" }} />
                </Icon>

                <Link
                  color="inherit"
                  component={RouterLink}
                  sx={{
                    ...(lastPage === thirdPage
                      ? breadcrumbStyles.lastPageStyle
                      : breadcrumbStyles.nonLastStyle),

                    "&:hover": { textDecoration: "none", cursor: "default" },
                  }}
                >
                  {thirdPage}
                </Link>
              </>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default Breadcrumb;

import React from "react";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Box,
  Stack,
  Select,
  MenuItem,
  Card,
  IconButton,
} from "@mui/material";
import { Toastr } from "components/Toastr";
import { useFormik } from "formik";
import { isError } from "lodash";
import { ApiServices } from "services/apiServices";
import { Constants } from "services/keyConstants";
import * as url from "services/url_helper";
import { Okb } from "iconsax-react";
import InformationCard from "components/InformationCard";
import CustomDatePicker from "components/CustomDatePicker";
import { option } from "services/selectConstant";
import {
  BookmarkBorderOutlined,
  BookmarkOutlined,
  Cancel,
} from "@mui/icons-material";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import { ReactComponent as CrossIcon } from "../../../assets/button_svg/Vector.svg";
import { helperFunction } from "services/helperFunction";
import { titleStyle, dataStyle } from "services/commonStyles";
import DeleteModal from "components/DeleteModal";
import { getObjectPermissions, getSecureToken } from "services/utils";
import * as Yup from "yup";

const gstOption = [
  { value: "NA", label: "NA" },
  { value: "IGST", label: "IGST 18%" },
  { value: "CGST", label: "CGST 9% & SGST 9%" },
];

const statusOption = [
  "Sent",
  "Partially Paid",
  "Paid",
  "Declined",
  "Expired",
  "Decision Pending",
];

function AddEditPInvoice({
  id = null,
  data,
  setData,
  setAddEditOpen,
  addEditOpen,
  setId,
  parentData,
  parentModel,
  setRecallState,
}) {
  const validationSchema = Yup.object({
    proposalId: Yup.mixed().test(
      "proposalId-validation",
      "Proposal Name is required",
      (value) => {
        if (!value) return false;
        if (typeof value === "string" && value.trim() !== "") return true;
        if (typeof value === "object" && value._id) return true;
        return false;
      }
    ),
    currencyId: Yup.mixed().test(
      "currencyId-validation",
      "Currency is required",
      (value) => {
        if (!value) return false;
        if (typeof value === "string" && value.trim() !== "") return true;
        if (typeof value === "object" && value._id) return true;
        return false;
      }
    ),
    invoiceDate: Yup.string().trim().required("Invoice Date is required"),
  });

  const formik = useFormik({
    initialValues: {
      ...data,
      invoiceDate: data?.invoiceDate ? data?.invoiceDate : new Date(),
      proposalId: data?.proposalId || "",
      currencyId: data?.currencyId || "",
      expiryDate: data?.expiryDate
        ? data?.expiryDate
        : new Date(new Date().setDate(new Date().getDate() + 7)),
      ownerId: data?.ownerId
        ? data.ownerId
        : getSecureToken(Constants.KEY_USER_DATA),
      gstPercentage: data?.gstPercentage || "NA",
    },
    validationSchema,
    onSubmit: (values) => {
      if (id) {
        const updatedValues = helperFunction.getUpdatedValues(values, data);
        updatePinvoice(updatedValues);
      } else {
        create(values);
      }
    },
  });
  const [options, setOptions] = useState({
    opportunity: [],
    prposal: [],
    currency: [],
    user: [],
  });
  const [deleteData, setDeleteData] = useState(null);

  const currencyData = () => {
    try {
      let uri = url.CURRENCY;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              currency: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const userData = async () => {
    try {
      let uri = url.USERS;
      const response = await ApiServices.callServiceGet(uri);

      if (response?.result?.type === "success") {
        setOptions((prev) => ({ ...prev, user: response?.result?.users }));
      } else {
        Toastr(response?.result?.message || "Error Occurred");
      }
    } catch (error) {
      Toastr(
        response?.result?.message ? response?.result?.message : "Error Occurred"
      );
      console.warn("Error:", error);
    }
  };

  const handleInputChange = (event, newInputValue, reason) => {
    if (reason === "input") {
      if (newInputValue?.length >= 3) {
        prposalData(newInputValue);
        opportunityData(newInputValue);
      } else {
        setOptions((prev) => ({ ...prev, prposal: [] }));
      }
    }
  };

  const prposalData = (query) => {
    try {
      let uri = url.PROPOSAL + `name/${query}`;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              prposal: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const updatePinvoice = (values) => {
    try {
      let uri = url.PINVOICE + id;
      let body = {
        ...values,
      };

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("P Invoice Updated Successfully", Constants.SUCCESS);
            if (setRecallState) {
              setRecallState(true);
            }
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const getPInvoice = () => {
    try {
      let uri = url.PINVOICE + id;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.data);
            setData(response?.result?.data);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const create = (values) => {
    try {
      let uri = url.PINVOICE;
      let body = {
        ...values,
      };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("P Invoice Added Successfully", Constants.SUCCESS);
            if (setRecallState) {
              setRecallState(true);
            }
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const opportunityData = (query) => {
    try {
      let uri = url.OPPORTUNITY + `name/${query}`;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prevState) => ({
              ...prevState,
              opportunity: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: data?.name });
  };

  useEffect(() => {
    if (id && !data) {
      getPInvoice();
    }
    userData();
    currencyData();
    if (parentModel) {
      switch (parentModel) {
        case "proposal":
          formik.setFieldValue("proposalId", parentData);
          formik.setFieldValue("opportunityId", parentData?.opportunityId);
          formik.setFieldValue("accountId", parentData?.accountId);
          break;

        case "opportunity":
          formik.setFieldValue("opportunityId", parentData);
          formik.setFieldValue("accountId", parentData?.accountId);
          prposalData(`getallvalues?${parentModel}Id=${parentData?._id}`);
          break;
        case "account":
          formik.setFieldValue("accountId", parentData);
          prposalData(`getallvalues?${parentModel}Id=${parentData?._id}`);
          break;
        default:
          if (parentData?.proposalId) {
            formik.setFieldValue("proposalId", parentData?.proposalId);
            formik.setFieldValue("opportunityId", parentData?.opportunityId);
            formik.setFieldValue("accountId", parentData?.accountId);
          } else {
            formik.setFieldValue("opportunityId", parentData?.opportunityId);
            formik.setFieldValue("accountId", parentData?.accountId);
            prposalData(`getallvalues?${parentModel}Id=${parentData?._id}`);
          }
      }
    }
  }, []);

  const permission = getObjectPermissions()?.["p-invoice"];

  return (
    <div>
      <DeleteModal
        dataUrl={url.PINVOICE}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        //    callFunc={addFilter}
        navLink={"/pInvoice-list"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          py: 2,
        }}>
        <Typography
          component='div'
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}>
          P Invoice Information
        </Typography>
        {/* <Typography variant="h6">Account Information</Typography> */}
        {permission !== undefined && permission[0] >= 3 && (
          <>
            <Typography
              component='span'
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}>
              <IconButton
                size='small'
                color='green'
                onClick={() => {
                  setAddEditOpen((prev) => !prev);
                  if (location?.pathname === "/pInvoice-list") {
                    setData(null);
                    setId(null);
                  }
                }}
                sx={{
                  p: 0.95,
                  borderRadius: "5px",
                  border: "1px solid red",
                }}>
                <CrossIcon width='2' height='2' />
              </IconButton>

              <IconButton
                size='small'
                color='green'
                onClick={() => {
                  formik.handleSubmit();
                  if (location?.pathname === "/pInvoice-list") {
                    setData(null);
                    setId(null);
                  }
                }}
                sx={{
                  p: 0,
                  borderRadius: "5px",
                  border: "1px solid green",
                }}>
                <BookmarkBorderOutlined
                  sx={{ color: "green", height: "16px" }}
                />{" "}
              </IconButton>

              {id && permission !== undefined && permission[0] >= 3 && (
                <IconButton
                  size='small'
                  onClick={handleOpenDelete}
                  sx={{
                    p: 0,
                    borderRadius: "5px",
                    border: "1px solid #4680FF ",
                  }}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          m: 0,
          p: 0,
          maxHeight: "65vh",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}>
        <form onSubmit={formik.handleSubmit}>
          <InformationCard title='Core Details' color='#E76767'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>P Invoice Number</Typography>

              <Typography {...dataStyle}>
                {formik?.values?.name ? formik.values.name : "" || "-"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>P Invoice Owner</Typography>

              <Autocomplete
                id='ownerId'
                name='ownerId'
                options={options?.user}
                getOptionLabel={(option) => option?.fullName || ""}
                value={
                  typeof formik.values.ownerId === "object"
                    ? {
                        fullName: formik.values.ownerId?.fullName || "",
                        _id: formik.values.ownerId?._id || "",
                      }
                    : options.user.find(
                        (option) => option._id === formik.values?.ownerId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "ownerId",
                    newValue ? newValue?._id : ""
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select P Invoice Owner'
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Proposal Name
                <Typography variant='span' color='red'>
                  *
                </Typography>
              </Typography>

              <Autocomplete
                id='proposalId'
                name='proposalId'
                options={options?.prposal}
                getOptionLabel={(option) => option?.name}
                value={
                  typeof formik.values.proposalId === "object"
                    ? {
                        name: formik.values.proposalId?.name || "",
                        _id: formik.values.proposalId?._id || "",
                      }
                    : options.prposal.find(
                        (option) => option._id === formik.values?.proposalId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "proposalId",
                    newValue ? newValue?._id : ""
                  );
                }}
                onInputChange={handleInputChange}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      formik.touched.proposalId &&
                      Boolean(formik.errors.proposalId)
                    }
                    helperText={
                      formik.touched.proposalId && formik.errors.proposalId
                    }
                    placeholder='Select Proposal Name'
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              {/* <Autocomplete
                id="opportunityId"
                name="opportunityId"
                options={options?.opportunity || []}
                getOptionLabel={(option) => option?.name || ""}
                value={
                  typeof formik?.values?.opportunityId === "object"
                    ? formik?.values?.opportunityId
                    : options?.opportunity?.find(
                        (option) => option?._id === formik.values?.opportunityId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "opportunityId",
                    newValue ? newValue?._id : ""
                  );
                }}
                onInputChange={handleInputChange}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                        mb: 1,
                      },
                    }}
                  />
                )}
              /> */}

              <Typography {...titleStyle}>Opportunity Name</Typography>

              <Typography {...dataStyle}>
                {formik?.values?.opportunityId?.name
                  ? formik.values.opportunityId.name
                  : "-"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Currency{" "}
                <Typography variant='span' color='red'>
                  *
                </Typography>
              </Typography>

              <Autocomplete
                id='currencyId'
                name='currencyId'
                options={options?.currency}
                getOptionLabel={(option) => option?.isoCode}
                value={
                  typeof formik.values.currencyId === "object"
                    ? {
                        isoCode: formik.values.currencyId?.isoCode || "",
                        _id: formik.values.currencyId?._id || "",
                      }
                    : options.currency.find(
                        (option) => option._id === formik.values?.currencyId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "currencyId",
                    newValue ? newValue?._id : ""
                  );
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select Currency'
                    error={
                      formik.touched?.currencyId &&
                      Boolean(formik.errors?.currencyId)
                    }
                    helperText={
                      formik.touched?.currencyId && formik.errors?.currencyId
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Account Name</Typography>

              <Typography {...dataStyle}>
                {formik?.values?.accountId?.name
                  ? formik.values.accountId.name
                  : "-"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Status</Typography>
              <Autocomplete
                id='status'
                name='status'
                options={statusOption}
                value={formik.values.status || null}
                onChange={(event, newValue) => {
                  formik.setFieldValue("status", newValue ? newValue : "");
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select Status Type'
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />{" "}
            </Grid>
          </InformationCard>
          <InformationCard title='General Details' color='#0000FF'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Invoice Date{" "}
                <Typography variant='span' color='red'>
                  *
                </Typography>
              </Typography>

              <CustomDatePicker
                value={formik?.values?.invoiceDate}
                onChange={(date) => {
                  formik.setFieldValue("invoiceDate", date);
                  formik.setFieldValue(
                    "expiryDate",
                    new Date().setDate(date?.getDate() + 7)
                  );
                }}
                error={
                  formik.touched.invoiceDate &&
                  Boolean(formik.errors.invoiceDate)
                }
                helperText={
                  formik.touched.invoiceDate && formik.errors.invoiceDate
                }
                placeholder='Select Invoice Date'

                // skipDay={0}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Expiry Date</Typography>
              <CustomDatePicker
                value={formik?.values?.expiryDate}
                onChange={(date) => formik.setFieldValue("expiryDate", date)}
                // skipDay={7}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>GST Type</Typography>

              <Autocomplete
                id='gstPercentage'
                name='gstPercentage'
                options={gstOption}
                getOptionLabel={(option) => option?.label || ""}
                value={
                  gstOption.find(
                    (option) => option.value === formik.values.gstPercentage
                  ) || null
                }
                // defaultValue="NA"
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "gstPercentage",
                    newValue ? newValue?.value : ""
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option.value === value?.value
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select GST Type'
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>GST(9%)</Typography>
              <Typography {...dataStyle}>
                {formik?.values?.gst9 ? formik?.values?.gst9 : "" || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Total Invoiced Value (With GST)
              </Typography>
              <Typography {...dataStyle}>
                {formik?.values?.totalInvoicedValueWithGST
                  ? formik?.values?.totalInvoicedValueWithGST
                  : "" || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>GST</Typography>

              <Typography {...dataStyle}>
                {formik?.values?.gst ? formik?.values?.gst : "" || "-"}
              </Typography>
            </Grid>
          </InformationCard>

          <InformationCard title='Other Details' color='#00FF00'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Total Final Invoiced Value
              </Typography>

              <Typography></Typography>
              <Typography {...dataStyle}>
                {formik?.values?.totalFinalInvoicedValueINR
                  ? formik?.values?.totalFinalInvoicedValueINR
                  : "" || "-"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>FP Bank Charges</Typography>

              <TextField
                name='fpBankCharges'
                value={formik?.values?.bankCharges}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter FP Bank Charges'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Total Payment Received</Typography>
              <Typography {...dataStyle}>
                {formik?.values?.totalPaymentReceivedINR
                  ? formik?.values?.totalPaymentReceivedINR
                  : "" || "-"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Notes</Typography>
              <TextField
                name='notes'
                value={formik?.values?.notes}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Notes'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Total Invoiced Value</Typography>

              <Typography {...dataStyle}>
                {formik?.values?.totalInvoicedValueINR
                  ? formik?.values?.totalInvoicedValueINR
                  : "" || "-"}
              </Typography>

              {/* <TextField
              name="name"
              value={formik?.values?.totalInvoicedValueINR}
              onChange={formik.handleChange}
              fullWidth
              disabled={true}
              variant="outlined"
              placeholder="Enter total invoiced Value"
              InputProps={{
                sx: {
                  height: "41px",
                },
              }}
            /> */}
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Remaning Amount</Typography>

              <Typography {...dataStyle}>
                {formik?.values?.remaningAmount
                  ? formik?.values?.remaningAmount
                  : "" || "-"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>TDS</Typography>
              <TextField
                name='tds'
                type='number'
                value={formik?.values?.tds}
                onChange={formik.handleChange}
                onWheel={(e) => e.target.blur()}
                fullWidth
                variant='outlined'
                placeholder='Enter TDS'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>TDS Date</Typography>

              <CustomDatePicker
                value={formik?.values?.tdsDate}
                onChange={(date) => formik.setFieldValue("tdsDate", date)}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>TDS Note</Typography>

              <TextField
                name='tdsNote'
                value={formik?.values?.tdsNote}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter TDS Note'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
          </InformationCard>
        </form>
      </Box>
    </div>
  );
}

export default AddEditPInvoice;

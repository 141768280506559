import { BookmarkBorderOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Constants } from "services/keyConstants";
import { Box } from "@mui/system";
import CustomDatePicker from "components/CustomDatePicker";
import InformationCard from "components/InformationCard";
import { Toastr } from "components/Toastr";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { ApiServices } from "services/apiServices";
import * as url from "services/url_helper";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import { ReactComponent as CrossIcon } from "../../../assets/button_svg/Vector.svg";
import JoditEditor from "jodit-react";
import DeleteModal from "components/DeleteModal";
import { getObjectPermissions, getSecureToken } from "services/utils";

const AddEditServiceBrif = ({
  id = null,
  data,
  setData,
  setAddEditOpen,
  setId,
  setRecallState,
}) => {
  const [options, setOptions] = useState({
    users: [],
  });
  const editor = useRef(null);
  const [deleteData, setDeleteData] = useState(null);

  const formik = useFormik({
    initialValues: {
      ...data,
      ownerId: data?.ownerId
        ? data.ownerId
        : getSecureToken(Constants.KEY_USER_DATA),
    },
    onSubmit: (values) => {
      if (id) {
        update(values);
      } else {
        create(values);
      }
    },
  });

  const [localContent, setLocalContent] = useState(formik.values.serviceBrief);
  const getServiceData = () => {
    try {
      let uri = url.SERVICE_BRIEF + id;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.data);
            setData(response?.result?.data);
            setLocalContent(response?.result?.data?.serviceBrief);
          } else {
            setIsLoading(false);
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" Function Error", error);
    }
  };

  const getOwner = () => {
    try {
      let uri = url.USERS;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              users: response?.result?.users,
            }));
          } else {
            setIsLoading(false);
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" Function Error", error);
    }
  };

  const update = (values) => {
    try {
      let uri = url.SERVICE_BRIEF + id;
      let body = {
        ...values,
      };

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);
            setAddEditOpen(false);
            if (setRecallState) {
              setRecallState(true);
            }

            Toastr("Service Updated Successfully", Constants.SUCCESS);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };
  const create = (values) => {
    try {
      let uri = url.SERVICE_BRIEF;
      let body = {
        ...values,
      };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("Service Brief Added Successfully", Constants.SUCCESS);
            setAddEditOpen(false);
            if (setRecallState) {
              setRecallState(true);
            }
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: data?.name });
  };

  useEffect(() => {
    if (id && !data) {
      getServiceData();
    }
    getOwner();
  }, []);
  const permission = getObjectPermissions()?.["service-brief"];

  return (
    <>
      <DeleteModal
        dataUrl={url.SERVICE_BRIEF}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        //    callFunc={addFilter}
        navLink={"/serviceBrief-list"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          py: 2,
        }}
      >
        <Typography
          component="div"
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          Service Brief
        </Typography>
        {/* <Typography variant="h6">Account Information</Typography> */}
        {permission !== undefined && permission[0] >= 3 && (
          <>
            <Typography
              component="span"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <IconButton
                size="small"
                onClick={() => {
                  setAddEditOpen((prev) => !prev);
                  if (location?.pathname === "/serviceBrief-list") {
                    setData(null);
                    setId(null);
                  }
                }}
                sx={{
                  p: 0.95,
                  borderRadius: "5px",
                  border: "1px solid red",
                }}
              >
                <CrossIcon width="2" height="2" />
              </IconButton>

              <IconButton
                size="small"
                color="green"
                onClick={() => {
                  formik.handleSubmit();
                  //   if (location?.pathname === "/serviceBrief-list") {
                  //     setData(null);
                  //     setId(null);
                  //   }
                }}
                sx={{
                  p: 0,
                  borderRadius: "5px",
                  border: "1px solid green",
                }}
              >
                <BookmarkBorderOutlined
                  sx={{ color: "green", height: "16px" }}
                />
              </IconButton>

              {id && (
                <IconButton
                  size="small"
                  // color="primary"
                  onClick={handleOpenDelete}
                  sx={{
                    p: 0,
                    borderRadius: "5px",
                    border: "1px solid #4680FF ",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          m: 0,
          p: 0,
          maxHeight: "65vh",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <InformationCard title="Core Details" color="red">
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography
                color="secondary"
                sx={{
                  p: 0,
                  fontSize: "12px",
                }}
                variant="h6"
              >
                Service Brief Name
              </Typography>
              <TextField
                name="name"
                value={formik?.values?.name}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Service brief Name"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography
                color="secondary"
                sx={{
                  p: 0,
                  fontSize: "12px",
                }}
              >
                Owner Name
              </Typography>
              <Autocomplete
                id="ownerId"
                name="ownerId"
                options={options?.users || []}
                getOptionLabel={(option) => option?.fullName}
                value={
                  typeof formik.values?.ownerId === "object"
                    ? {
                        fullName: formik.values.ownerId?.fullName || "",
                        _id: formik.values?.ownerId?._id || "",
                      }
                    : options?.users?.find(
                        (option) => option?._id === formik.values?.ownerId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "ownerId",
                    newValue ? newValue?._id : ""
                  );
                }}
                onBlur={formik.handleBlur}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </InformationCard>
          <InformationCard title="General Detail" color="blue">
            <Grid item xs={12} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography
                // component="div"
                color="secondary"
                sx={{
                  p: 0,
                  fontSize: "12px",
                }}
              >
                Service Brief
              </Typography>

              <JoditEditor
                ref={editor}
                value={localContent}
                // value={formik.values?.serviceBrief}
                onBlur={() => formik.setFieldTouched("serviceBrief", true)}
                onChange={(content) =>
                  formik.setFieldValue("serviceBrief", content)
                }
              />
            </Grid>
          </InformationCard>
        </form>
      </Box>
    </>
  );
};

export default AddEditServiceBrif;

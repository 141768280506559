import React from "react";
import InformationCard from "components/InformationCard";
import { Toastr } from "components/Toastr";
import { useFormik } from "formik";
import { Constants } from "services/keyConstants";

import { useEffect, useState } from "react";
import { ApiServices } from "services/apiServices";
import * as url from "services/url_helper";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import { ReactComponent as CrossIcon } from "../../../assets/button_svg/Vector.svg";
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { BookmarkBorderOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import { helperFunction } from "services/helperFunction";
import { dataStyle, titleStyle } from "services/commonStyles";
import DeleteModal from "components/DeleteModal";
import { getObjectPermissions } from "services/utils";
import * as Yup from "yup";

const AddEditPaymentDistribution = ({
  id = null,
  data,
  setData,
  setAddEditOpen,
  setId,
  parentData,
  parentModel,
  setRecallState,
}) => {
  const [options, setOptions] = useState({
    account: [],
    currency: [],
    paymentDetail: [],
    pInvoice: [],
  });
  const [selectedType, setSelectedType] = useState("");
  const [deleteData, setDeleteData] = useState(null);
  const permission = getObjectPermissions()?.["payment-distribution"];
  const validationSchema = Yup.object({
    paymentDetailId: Yup.mixed().test(
      "paymentDetailId-validation",
      "Payment Detail Name is required",
      (value) => {
        if (!value) return false;
        if (typeof value === "string" && value.trim() !== "") return true;
        if (typeof value === "object" && value._id) return true;
        return false;
      }
    ),
    pInvoiceId: Yup.mixed().test(
      "pInvoiceId-validation",
      "P Invoice Name is required",
      (value) => {
        if (!value) return false;
        if (typeof value === "string" && value.trim() !== "") return true;
        if (typeof value === "object" && value._id) return true;
        return false;
      }
    ),
    amount: Yup.number()
      .typeError("Amount must be a number")
      .positive("Amount must be greater than zero")
      .required("Amount is required"),
  });
  const formik = useFormik({
    initialValues: {
      ...data,
      paymentDetailId: data?.paymentDetailId || "",
      pInvoiceId: data?.paymentDetailId || "",
      amount: data?.amount || "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (id) {
        const updatedValues = helperFunction.getUpdatedValues(values, data);
        update(updatedValues);
      } else {
        create(values);
      }
    },
  });

  const getOnePInvoice = (idx) => {
    try {
      let uri = url.PINVOICE + idx;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            if (
              response?.result?.data?.accountId?._id !==
              formik?.values?.accountId?._id
            ) {
              formik.setFieldValue(
                "accountId",
                response?.result?.data?.accountId
              );
            }
            getPaymentByAccount(response?.result?.data?.accountId?._id);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };
  const getOnePayment = (idx) => {
    try {
      let uri = url.PAYMENT_DETAILS + idx;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            if (
              response?.result?.data?.accountId?._id !==
              formik?.values?.accountId?._id
            ) {
              formik.setFieldValue(
                "accountId",
                response?.result?.data?.accountId
              );
            }
            getPInvoiceByAccount(response?.result?.data?.accountId?._id);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const getPInvoiceByName = (query) => {
    try {
      let uri = url.PINVOICE + `/name/${query}`;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              pInvoice: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };
  const getPaymentByName = (query) => {
    try {
      let uri = url.PAYMENT_DETAILS + `/name/${query}`;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              paymentDetail: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const getPaymentByAccount = (query) => {
    try {
      let uri = url.PAYMENT_DETAILS + `?accountId=${query}`;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              paymentDetail: response?.result?.data,
            }));
            if (selectedType == "") {
              formik.setFieldValue("paymentDetailId", "");
            }
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const getPInvoiceByAccount = (query) => {
    try {
      let uri = url.PINVOICE + `?accountId=${query}`;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              pInvoice: response?.result?.data,
            }));
            // if (selectedType == "") {
            //   formik.setFieldValue("pInvoiceId", "");
            // }
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };
  const update = (values) => {
    try {
      let uri = url.PAYMENT_DISTRIBUTION + id;
      let body = {
        ...values,
      };

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);
            if (setRecallState) {
              setRecallState(true);
            }
            setAddEditOpen(false);
            Toastr(
              "Payment Distribution Updated Successfully",
              Constants.SUCCESS
            );
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };
  const create = (values) => {
    try {
      let uri = url.PAYMENT_DISTRIBUTION;
      let body = {
        ...values,
      };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);
            Toastr(
              "Payment Distribution Added Successfully",
              Constants.SUCCESS
            );
            if (setRecallState) {
              setRecallState(true);
            }
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };
  const getPaymentDistribution = () => {
    try {
      let uri = url.PAYMENT_DISTRIBUTION + id;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.data);
            setData(response?.result?.data);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };
  const handleInputChange = (event, newInputValue, reason) => {
    if (event) {
      event.preventDefault();
    }
    if (reason === "input") {
      if (newInputValue?.length >= 3) {
        switch (event?.target?.id) {
          case "paymentDetailId":
            if (selectedType !== "pInvoiceId") {
              getPaymentByName(newInputValue);
            }
            break;
          case "pInvoiceId":
            if (selectedType !== "paymentDetailId") {
              getPInvoiceByName(newInputValue);
            }
            break;
        }
      }
    }
  };

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: data?.name });
  };

  useEffect(() => {
    if (id && !data) {
      getPaymentDistribution();
    }
    if (parentModel) {
      if (parentModel == "payment-detail") {
        formik.setFieldValue("paymentDetailId", parentData);
        formik.setFieldValue("accountId", parentData?.accountId);
        getPInvoiceByAccount(parentData?.accountId?._id);
        setSelectedType("paymentDetailId");
      } else if (parentModel == "p-invoice") {
        formik.setFieldValue("pInvoiceId", parentData);
        formik.setFieldValue("accountId", parentData?.accountId);
        getPaymentByAccount(parentData?.accountId?._id);
        setSelectedType("pInvoiceId");
        // } else if (parentData?.paymentDetailId) {
        //   formik.setFieldValue("paymentDetailId", parentData?.paymentDetailId);
        //   formik.setFieldValue("accountId", parentData?.accountId);
        // } else if (parentData?.pInvoiceId) {
        //   formik.setFieldValue("pInvoiceId", parentData?.pInvoiceId);
        //   formik.setFieldValue("accountId", parentData?.accountId);
      } else {
        formik.setFieldValue("accountId", parentData);
        getPaymentByName(`getallvalues?${parentModel}Id=${parentData?._id}`);
        getPInvoiceByName(`getallvalues?${parentModel}Id=${parentData?._id}`);
      }
    }
  }, []);
  return (
    <>
      <DeleteModal
        dataUrl={url.PAYMENT_DISTRIBUTION}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        //    callFunc={addFilter}
        navLink={"/paymentDistribution-list"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          py: 2,
        }}>
        <Typography
          component='div'
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}>
          Payment Distribution
        </Typography>
        <>
          <Typography
            component='span'
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
            }}>
            <IconButton
              size='small'
              onClick={() => {
                setAddEditOpen((prev) => !prev);
                if (location?.pathname === "/paymentDistribution-list") {
                  setData(null);
                  setId(null);
                }
              }}
              sx={{
                p: 0.95,
                borderRadius: "5px",
                border: "1px solid red",
              }}>
              <CrossIcon width='2' height='2' />
            </IconButton>

            <IconButton
              size='small'
              color='green'
              onClick={() => {
                formik.handleSubmit();
              }}
              sx={{
                p: 0,
                borderRadius: "5px",
                border: "1px solid green",
              }}>
              <BookmarkBorderOutlined sx={{ color: "green", height: "16px" }} />
            </IconButton>

            {id && permission !== undefined && permission[0] >= 3 && (
              <IconButton
                size='small'
                onClick={handleOpenDelete}
                sx={{
                  p: 0,
                  borderRadius: "5px",
                  border: "1px solid #4680FF ",
                }}>
                <DeleteIcon />
              </IconButton>
            )}
          </Typography>
        </>
      </Box>
      <Box
        sx={{
          m: 0,
          p: 0,
          maxHeight: "65vh",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}>
        <form onSubmit={formik.handleSubmit}>
          <InformationCard title='Core Details' color='red'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Payment Distribution Number
              </Typography>
              <Typography {...dataStyle}>
                {formik?.values?.name || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Account Name</Typography>
              <Typography {...dataStyle}>
                {formik.values?.accountId?.name || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Payment Detail Name
                <Typography variant='span' color='red'>
                  *
                </Typography>
              </Typography>
              <Autocomplete
                id='paymentDetailId'
                name='paymentDetailId'
                options={options?.paymentDetail}
                getOptionLabel={(option) => option?.name}
                value={
                  typeof formik.values?.paymentDetailId === "object"
                    ? formik?.values?.paymentDetailId
                    : options?.paymentDetail?.find(
                        (option) =>
                          option._id === formik.values?.paymentDetailId
                      ) || null
                }
                onChange={(event, newValue) => {
                  if (newValue && selectedType !== "pInvoiceId") {
                    setSelectedType("paymentDetailId");
                    getOnePayment(newValue?._id);
                  }
                  formik.setFieldValue(
                    "paymentDetailId",
                    newValue ? newValue?._id : ""
                  );
                }}
                onInputChange={handleInputChange}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select Payment Detail Name'
                    error={
                      formik.touched.paymentDetailId &&
                      Boolean(formik.errors.paymentDetailId)
                    }
                    helperText={
                      formik.touched.paymentDetailId &&
                      formik.errors.paymentDetailId
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                P Invoice{" "}
                <Typography variant='span' color='red'>
                  *
                </Typography>
              </Typography>
              <Autocomplete
                id='pInvoiceId'
                name='pInvoiceId'
                options={options?.pInvoice}
                getOptionLabel={(option) => option?.name}
                value={
                  typeof formik.values?.pInvoiceId === "object"
                    ? formik?.values?.pInvoiceId
                    : options?.pInvoice?.find(
                        (option) => option._id === formik.values?.pInvoiceId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "pInvoiceId",
                    newValue ? newValue?._id : ""
                  );
                  if (newValue && selectedType !== "paymentDetailId") {
                    setSelectedType("pInvoiceId");
                    getOnePInvoice(newValue?._id);
                  }
                }}
                onInputChange={handleInputChange}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select P Invoice'
                    error={
                      formik.touched.pInvoiceId &&
                      Boolean(formik.errors.pInvoiceId)
                    }
                    helperText={
                      formik.touched.pInvoiceId && formik.errors.pInvoiceId
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </InformationCard>
          <InformationCard title='General Detail' color='blue'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Amount
                <Typography variant='span' color='red'>
                  *
                </Typography>
              </Typography>
              <TextField
                name='amount'
                value={formik?.values?.amount}
                onChange={formik.handleChange}
                onWheel={(e) => e.target.blur()}
                fullWidth
                type='number'
                variant='outlined'
                placeholder='Amount'
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Currency</Typography>
              <Typography {...dataStyle}>
                {formik.values.currencyId?.isoCode || "-"}
              </Typography>
            </Grid>
          </InformationCard>
          <InformationCard title='Other Details' color='green'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography
                // component="div"
                {...titleStyle}>
                Amount Recieved Date
              </Typography>
              <Typography {...dataStyle}>
                {helperFunction.dateFormat(
                  formik?.values?.paymentDetailId?.paymentDate
                ) || "-"}
              </Typography>
            </Grid>
          </InformationCard>
        </form>
      </Box>
    </>
  );
};

export default AddEditPaymentDistribution;

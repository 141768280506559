import { Constants } from "services/keyConstants";
import { openSnackbar } from "store/reducers/snackbar";

import { dispatch } from "store";
export const Toastr = (message = "Error", color = Constants.ERROR) => {
  dispatch(
    openSnackbar({
      open: true,
      message: message,
      variant: "alert",
      anchorOrigin: { vertical: "top", horizontal: "right" },
      alert: {
        color: color,
      },
    })
  );
};

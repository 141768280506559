import { Dialog, DialogContent } from "@mui/material";
import AddEditOpportunity from "pages/CRM/Opportunity/AddEditOpportunity";
import AddEditAccount from "pages/CRM/Account/AddEditAccount";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { PopupTransition } from "components/@extended/Transitions";
import AddEditPaymentDetail from "pages/CRM/PaymentDetails/AddEditPaymentDetail";
import AddEditPInvoice from "pages/CRM/PInvoice/AddEditPInvoice";
import AddEditProposal from "pages/CRM/Proposal/AddEditProposal";
import AddEditFInvoice from "pages/CRM/FInvoice/AddEditFInvoice";
import AddEditPaymentDistribution from "pages/CRM/PaymentDistribution/AddEditPaymentDistribution";
import AddEditTermCondition from "pages/CRM/TermCondition/AddEditTermCondition";
import AddEditServiceBrif from "pages/CRM/ServiceBrief/AddEditServiceBrif";
import AddEditCurrency from "pages/CRM/Currency/AddEditCurrency";
import AddEditContact from "pages/CRM/Contact/AddEditContact";
import NotesPopup from "./NotesPopup";
import AddEditFollowUp from "pages/CRM/FollowUpTask/AddEditFollowUp";
import AddEditUsers from "pages/CRM/Users/AddEditUsers";
import AddEditBank from "pages/CRM/Bank/AddEditBank";

const RelatedAddEdit = ({
  id = null,
  model,
  addEditModal,
  setAddEditModal,
  callFunc,
  nav = true,
  setId,
  parentData,
  parentModel,
}) => {
  const [data, setData] = useState(null);
  const [addEditOpen, setAddEditOpen] = useState(addEditModal);
  const [recallState, setRecallState] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (recallState) {
      if (!nav || model === "notes" || model === "followUpTask") {
        callFunc();
        setAddEditModal(false);
      } else {
        navigate(`/${model}/view/${data?._id}`);
        setAddEditModal(false);
      }
    } else {
      setAddEditModal(addEditOpen);
    }
  }, [addEditOpen]);

  const ModalCall = () => {
    switch (model) {
      case "account":
        return (
          <AddEditAccount
            id={id}
            setData={setData}
            data={data}
            setAddEditOpen={setAddEditOpen}
            setId={setId}
            setRecallState={setRecallState}
          />
        );
      case "opportunity":
        return (
          <AddEditOpportunity
            id={id}
            setData={setData}
            data={data}
            setAddEditOpen={setAddEditOpen}
            setId={setId}
            parentData={parentData}
            parentModel={parentModel}
            setRecallState={setRecallState}
          />
        );
      case "proposal":
        return (
          <AddEditProposal
            id={id}
            setData={setData}
            data={data}
            parentData={parentData}
            parentModel={parentModel}
            setAddEditOpen={setAddEditOpen}
            addEditOpen={addEditOpen}
            setId={setId}
            setRecallState={setRecallState}
          />
        );
      case "paymentDetail":
        return (
          <AddEditPaymentDetail
            id={id}
            setData={setData}
            data={data}
            setAddEditOpen={setAddEditOpen}
            setId={setId}
            parentData={parentData}
            parentModel={parentModel}
            setRecallState={setRecallState}
          />
        );
      case "paymentDistribution":
        return (
          <AddEditPaymentDistribution
            id={id}
            setData={setData}
            data={data}
            setAddEditOpen={setAddEditOpen}
            setId={setId}
            parentData={parentData}
            parentModel={parentModel}
            setRecallState={setRecallState}
          />
        );

      case "pInvoice":
        return (
          <AddEditPInvoice
            id={id}
            setData={setData}
            data={data}
            setAddEditOpen={setAddEditOpen}
            setId={setId}
            parentData={parentData}
            parentModel={parentModel}
            setRecallState={setRecallState}
          />
        );

      case "fInvoice":
        return (
          <AddEditFInvoice
            id={id}
            setData={setData}
            data={data}
            setAddEditOpen={setAddEditOpen}
            setId={setId}
            parentData={parentData}
            parentModel={parentModel}
            setRecallState={setRecallState}
          />
        );
      case "termCondition":
        return (
          <AddEditTermCondition
            id={id}
            setData={setData}
            data={data}
            setAddEditOpen={setAddEditOpen}
            setRecallState={setRecallState}
            setId={setId}
          />
        );
      case "serviceBrief":
        return (
          <AddEditServiceBrif
            id={id}
            setData={setData}
            data={data}
            setAddEditOpen={setAddEditOpen}
            setId={setId}
            setRecallState={setRecallState}
          />
        );
      case "currency":
        return (
          <AddEditCurrency
            id={id}
            setData={setData}
            data={data}
            setAddEditOpen={setAddEditOpen}
            setId={setId}
            setRecallState={setRecallState}
          />
        );
      case "users":
        return (
          <AddEditUsers
            id={id}
            setData={setData}
            data={data}
            setAddEditOpen={setAddEditOpen}
            setId={setId}
            setRecallState={setRecallState}
          />
        );
      case "contact":
        return (
          <AddEditContact
            id={id}
            setData={setData}
            data={data}
            setAddEditOpen={setAddEditOpen}
            setId={setId}
            parentData={parentData}
            parentModel={parentModel}
            setRecallState={setRecallState}
          />
        );
      case "followUpTask":
        return (
          <AddEditFollowUp
            id={id}
            setId={setId}
            setData={setData}
            data={data}
            addEditOpen={addEditOpen}
            setAddEditOpen={setAddEditOpen}
            parentData={parentData}
            parentModel={parentModel}
            setRecallState={setRecallState}
          />
        );
      case "notes":
        return (
          <NotesPopup
            id={id}
            setData={setData}
            data={data}
            addEditOpen={addEditOpen}
            setAddEditOpen={setAddEditOpen}
            setId={setId}
            parentData={parentData}
            parentModel={parentModel}
            setRecallState={setRecallState}
          />
        );
      case "bank":
        return (
          <AddEditBank
            id={id}
            setData={setData}
            data={data}
            setAddEditOpen={setAddEditOpen}
            setId={setId}
            setRecallState={setRecallState}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Dialog
        open={addEditOpen}
        onClose={() => {
          setAddEditOpen(false);
          setId(null);
          setData(null);
        }}
        keepMounted
        TransitionComponent={PopupTransition}
        maxWidth="md"
        sx={{
          "& .MuiDialog-paper": {
            width: { xs: "90%", sm: "650px" },
            borderRadius: "20px",
            overflow: "auto",
          },
        }}
        style={{ zIndex: 4 }}
        aria-labelledby="column-delete-title"
        aria-describedby="column-delete-description"
      >
        <DialogContent>{ModalCall()}</DialogContent>
      </Dialog>
    </>
  );
};
export default RelatedAddEdit;

export const helperFunction = {
  dateFormat: (date, time = false) => {
    const dates = new Date(date);
    if (!date || typeof date === "boolean" || dates == "Invalid Date") {
      return "";
    }

    const year = dates?.getFullYear();
    if (year <= 2000) {
      return "";
    }
    if (time) {
      return dates?.toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    } else {
      return dates?.toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    }
  },

  amountFormat: (number = 0, IN = false, fraction = 2) => {
    const options = {
      minimumFractionDigits: fraction,
      maximumFractionDigits: fraction,
    };
    if (!number) {
      return "0";
    } else if (IN) {
      return new Intl.NumberFormat("en-IN", options).format(number);
    } else {
      return new Intl.NumberFormat("en-US", options).format(number);
    }
  },

  getUpdatedValues: (values, data) =>
    Object?.entries(values)?.reduce((acc, [key, value]) => {
      if (JSON.stringify(value) !== JSON.stringify(data[key])) {
        acc[key] = value;
      }
      return acc;
    }, {}),

  createMarkup: (text) => {
    const formattedText = text?.replace(/\n/g, "<br>");
    return { __html: formattedText };
  },
};

import {
  Button,
  InputAdornment,
  Pagination,
  PaginationItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Colors } from "services/colorConstant";
import { Box, Stack } from "@mui/system";
import RelatedAddEdit from "./RelatedList/RelatedAddEdit";
import DeleteModal from "./DeleteModal";
import { Toastr } from "./Toastr";
import { ApiServices } from "services/apiServices";
import Loaders from "components/Loaders/Loaders";

const SimpleTable = ({
  column,
  // data,
  addEditOpen,
  setAddEditOpen,
  id,
  setId,
  modal,
  dataUrl,
  deleteData,
  setDeleteData,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState();
  const [filteredData, setFiltredData] = useState(data);
  const [isFocused, setIsFocused] = useState(false);
  const [paginationData, setPaginationData] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: Math.ceil(data?.length / 10) || 1,
  });
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    try {
      let uri = dataUrl;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            response?.result?.users
              ? setData(response?.result?.users)
              : setData(response?.result?.data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" Function Error", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setIsLoading(true);
    setPaginationData((prevData) => ({ ...prevData, currentPage: pageNumber }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.name === "search") {
      setPaginationData((prevData) => ({
        ...prevData,
        currentPage: 1,
      }));
    } else if (event.key === "Enter" && event.target.name === "currentPage") {
      setIsLoading(true);
      setPaginationData((prevData) => ({
        ...prevData,
        currentPage: Number(event.target.value) || 1,
      }));
    }
  };
  const columns = useMemo(() => [
    {
      Header: " ",
      accessor: "srno",
      Cell: (cellProps) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {cellProps.row.index +
              1 +
              (paginationData?.currentPage - 1) * paginationData?.limit}
          </div>
        </div>
      ),
    },
    ...column,
  ]);

  const paginatedData = filteredData?.slice(
    (paginationData.currentPage - 1) * paginationData.limit,
    paginationData.currentPage * paginationData.limit
  );

  const search = () => {
    setFiltredData(() =>
      data?.filter((row) =>
        // Object.values(row).some((value) =>
        //   value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        // )
        column.some((col) => {
          const accessorPath = col.accessor.split("?.");
          let value = row;
          for (const key of accessorPath) {
            value = value?.[key];
          }
          return value
            ?.toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        })
      )
    );
  };
  useEffect(() => {
    setPaginationData((prevData) => ({
      ...prevData,
      totalPages: Math.ceil(filteredData?.length / prevData.limit) || 1,
      currentPage: 1,
    }));
  }, [filteredData]);
  useEffect(() => {
    search();
  }, [searchQuery, data]);
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loaders />
      ) : (
        <>
          {data && data.length > 0 ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "space-between",
                  padding: "5px 0px 10px 0px",
                  gap: "10px",
                  backgroundColor: "transparent",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: isFocused ? 1 : 0,
                    minWidth: "140px",
                    maxWidth: "80%",
                    alignContent: "center",
                    transition: "flex-grow 0.4s ease-in-out",
                    // backgroundColor: Colors.DEFAULT_COLOR,
                    borderRadius: "8px",
                  }}
                >
                  <TextField
                    type="text"
                    name="search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            sx={{ fontSize: 13, marginRight: "-10px" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={`${data?.length ? data.length : ""} Search...`}
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={searchQuery}
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchQuery(e.target.value);
                      // search();
                    }}
                    onClick={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    // onKeyDown={handleKeyDown}
                    sx={{
                      // mb: 2,
                      backgroundColor: Colors.DEFAULT_COLOR,
                      borderRadius: "8px",
                    }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  color="success"
                  id="create-btn"
                  sx={{ whiteSpace: "nowrap" }}
                  onClick={(e) => {
                    setAddEditOpen(true);
                    setId(null);
                  }}
                >
                  + ADD
                </Button>
              </Box>
              <TableContainer style={{ overflowX: "auto", maxWidth: "100%" }}>
                <Table style={{ borderRadius: "10px" }}>
                  <TableHead sx={{ backgroundColor: "transparent" }}>
                    <TableRow
                      style={{
                        backgroundColor: Colors.PRIMARY_COLOR,
                        boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                      }}
                    >
                      {columns?.map((col, rowIndex) => (
                        <TableCell
                          key={rowIndex}
                          className="text-center"
                          style={{
                            cursor: "pointer",
                            color: "#E5E5E5",
                            // fontSize: "14px",
                            padding: "12px",
                            textAlign: "center",
                            borderTopLeftRadius: rowIndex === 0 ? "6px" : "0px",
                            borderTopRightRadius:
                              rowIndex === columns.length - 1 ? "6px" : "0px",
                            borderBottomLeftRadius:
                              rowIndex === 0 ? "6px" : "0px",
                            borderBottomRightRadius:
                              rowIndex === columns.length - 1 ? "6px" : "0px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                minWidth:
                                  col?.type === undefined ? "" : "150px",
                                maxWidth:
                                  col?.type === undefined ? "" : "200px",
                              }}
                            >
                              {col.Header}
                            </span>
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        style={{
                          backgroundColor: "transparent",
                          height: "15px",
                          padding: "0px",
                          borderBottom: "hidden",
                        }}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody
                    style={{
                      boxShadow: "0px 2px 5px rgba(0,0,0,0.3)",
                    }}
                  >
                    {paginatedData?.map((row, rowIndex) => (
                      <TableRow
                        // key={rowIndex}
                        key={row.id || rowIndex}
                        style={{
                          backgroundColor: Colors.DEFAULT_COLOR,
                        }}
                      >
                        {columns.map((col, colIndex) => (
                          <TableCell
                            key={col.accessor}
                            style={{
                              position: "relative",
                              padding: "5px 12px",
                              // textAlign: "center",
                              color: "#333",
                              // fontSize: "14px",
                              boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                              borderBottomLeftRadius:
                                rowIndex === data?.length - 1 && colIndex === 0
                                  ? "6px"
                                  : "0px",
                              borderBottomRightRadius:
                                rowIndex === data?.length - 1 &&
                                colIndex === columns.length - 1
                                  ? "6px"
                                  : "0px",
                              borderTopLeftRadius:
                                rowIndex === 0 && colIndex === 0
                                  ? "6px"
                                  : "0px",
                              borderTopRightRadius:
                                rowIndex === 0 &&
                                colIndex === columns.length - 1
                                  ? "6px"
                                  : "0px",
                            }}
                          >
                            {col.Cell
                              ? col.Cell({
                                  row: { original: row, index: rowIndex },
                                })
                              : row[column.accessor]}
                            {colIndex !== columns.length - 1 && (
                              <Typography
                                variant="span"
                                style={{
                                  position: "absolute",
                                  width: "1px",
                                  height: "50%",
                                  backgroundColor: "rgba(219, 224, 229, 0.65)",
                                  right: 0,
                                  top: "20%",
                                }}
                              />
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                marginTop={3}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  style={{ marginBottom: "15px" }}
                >
                  <Pagination
                    count={paginationData?.totalPages || 1}
                    page={paginationData?.currentPage || 1}
                    onChange={(event, page) => handlePageChange(page)}
                    variant="outlined"
                    shape="rounded"
                    disabled={paginationData?.totalPages === 0}
                    renderItem={(item) => {
                      if (
                        item.type === "previous" ||
                        item.type === "next" ||
                        item.page === paginationData?.currentPage
                      ) {
                        return <PaginationItem {...item} />;
                      }
                      return null;
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      "& .MuiPaginationItem-root": {
                        fontWeight: "bold",
                        color: "#333",
                      },
                      "& .Mui-selected": {
                        backgroundColor: `${Colors.PRIMARY_COLOR} !important`,
                        color: `${Colors.DEFAULT_COLOR} !important`,
                        fontWeight: "bold",
                        borderRadius: "8px",
                      },
                    }}
                  />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="span">Go To</Typography>
                  <TextField
                    name="currentPage"
                    type="number"
                    id="currentPage-field"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleKeyDown(e)}
                    inputProps={{ min: 1, max: paginationData?.totalPages }}
                    sx={{
                      width: 50,
                      textAlign: "center",
                      "& .MuiInputBase-input": {
                        height: "27px",
                        padding: "2px 5px",
                      },
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </Stack>
              </Stack>
            </>
          ) : (
            <>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  variant="outlined"
                  color="success"
                  id="create-btn"
                  sx={{ whiteSpace: "nowrap" }}
                  onClick={(e) => {
                    setAddEditOpen(true);
                    setId(null);
                  }}
                >
                  + ADD
                </Button>
              </Box>
              <tr>
                <td colSpan="3">
                  <h5
                    className="text-primary text-center"
                    style={{ fontWeight: "bold" }}
                  >
                    No Data Available
                  </h5>
                </td>
              </tr>
            </>
          )}

          {deleteData?.open && (
            <DeleteModal
              dataUrl={dataUrl}
              deleteData={deleteData}
              setDeleteData={setDeleteData}
              callFunc={getData}
              // navLink={navLink}
            />
          )}
          {addEditOpen && (
            <RelatedAddEdit
              id={id}
              setId={setId}
              model={modal}
              addEditModal={addEditOpen}
              setAddEditModal={setAddEditOpen}
              callFunc={getData}
              // nav={false}
            />
          )}
        </>
      )}
    </>
  );
};

export default SimpleTable;

import React, { useEffect, useState } from "react";
import { ApiServices } from "services/apiServices";
import * as url from "../../../services/url_helper";
import {
  Grid,
  Typography,
  Card,
  Container,
  Box,
  IconButton,
} from "@mui/material";
import InformationCard from "components/InformationCard";
import AddEditRedTech from "./AddEditRecTech";
import { useParams } from "react-router";
import { Edit } from "iconsax-react";
import { titleStyle, dataStyle } from "services/commonStyles";
import { helperFunction } from "services/helperFunction";
import Breadcrumb from "components/BreadCrumb";
import Loaders from "components/Loaders/Loaders";

function RegTechView() {
  const [data, setData] = useState({});
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [addEditOpen, setAddEditOpen] = useState(false);

  const permission = [3, 1];

  const getRegTechData = async () => {
    try {
      let uri = url.REGTECH;
      const response = await ApiServices.callServiceGet(uri);

      if (response?.result?.type === "success") {
        setData((prevState) => ({
          ...prevState,
          ...response?.result?.data[0],
        }));
      } else {
        Toastr(response?.result?.message || "Error Occurred", "Failed");
      }
    } catch (error) {
      console.warn("Function Error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRegTechData();
  }, []);

  return (
    <div>
      <Breadcrumb title='RegTech' currentPage='RegTech' />
      <div className='page-content' style={{ height: "100%" }}>
        {isLoading ? (
          <Loaders />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={6} sx={{ height: "99%" }}>
              <Card
                sx={{
                  backgroundColor: "transparent",
                  boxShadow: 0,
                }}>
                <Container
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}>
                  {addEditOpen ? (
                    <>
                      <AddEditRedTech
                        id={id}
                        data={data}
                        setData={setData}
                        setAddEditOpen={setAddEditOpen}
                      />
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          py: 2,
                          px: 0,
                        }}>
                        <Typography variant='div'>
                          RegTech Information
                        </Typography>
                        {permission !== undefined && permission[0] >= 3 && (
                          <>
                            <Typography
                              component='span'
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                              }}>
                              <IconButton
                                size='small'
                                color='green'
                                onClick={() => setAddEditOpen(!addEditOpen)}
                                sx={{
                                  p: 0,
                                  borderRadius: "5px",
                                  border: "1px solid green",
                                }}>
                                <Edit size='small' color='green' />
                              </IconButton>
                            </Typography>
                          </>
                        )}
                      </Box>

                      <Box
                        sx={{
                          m: 0,
                          p: 0,
                          maxHeight: "65vh",
                          overflowY: "auto",
                          scrollbarWidth: "none",
                        }}>
                        <InformationCard title='Core Details' color='#E76767'>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ pl: 2, pt: 1.5, m: 0 }}>
                            <Typography {...titleStyle}>Name</Typography>
                            <Typography {...dataStyle}>
                              {data?.name || "-"}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ pl: 2, pt: 1.5, m: 0 }}>
                            <Typography {...titleStyle}>Email</Typography>
                            <Typography {...dataStyle}>
                              {data?.email || "-"}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ pl: 2, pt: 1.5, m: 0 }}>
                            <Typography {...titleStyle}>Phone</Typography>
                            <Typography {...dataStyle}>
                              {data?.phone || "-"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ pl: 2, pt: 1.5, m: 0 }}>
                            <Typography {...titleStyle}>GST Number</Typography>
                            <Typography {...dataStyle}>
                              {data?.gstNumber || "-"}
                            </Typography>
                          </Grid>
                        </InformationCard>

                        <InformationCard
                          title='General Details'
                          color='#0000FF'>
                          <Grid item xs={12} sx={{ pl: 2, pt: 1.5, m: 0 }}>
                            <Typography {...titleStyle}>Address</Typography>
                            <Typography {...dataStyle}>
                              {data?.address || "-"}
                            </Typography>
                          </Grid>
                        </InformationCard>

                        <InformationCard title='Author Detail' color='orange'>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ px: 2, pt: 1.5, m: 0 }}>
                            <Typography {...titleStyle}>Created By</Typography>
                            <Typography {...dataStyle}>
                              <span style={{ display: "block" }}>
                                {data?.createdBy?.fullName},{" "}
                              </span>
                              {helperFunction.dateFormat(data?.createdAt, true)}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ px: 2, pt: 1.5, m: 0 }}>
                            <Typography {...titleStyle}>
                              Last Modified By
                            </Typography>
                            <Typography {...dataStyle}>
                              <span style={{ display: "block" }}>
                                {data?.lastModifiedBy?.fullName ||
                                  data?.createdBy?.fullName}
                                ,{" "}
                              </span>
                              {helperFunction.dateFormat(data?.updatedAt, true)}
                            </Typography>
                          </Grid>
                        </InformationCard>
                      </Box>
                    </>
                  )}
                </Container>
              </Card>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default RegTechView;

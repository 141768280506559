import { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import {
  BookmarkBorderOutlined,
  BookmarkOutlined,
  Cancel,
  ConstructionOutlined,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import { Toastr } from "components/Toastr";
import { useLocation } from "react-router";
import { useFormik } from "formik";
import { isError } from "lodash";
import { ApiServices } from "services/apiServices";
import * as url from "services/url_helper";
import InformationCard from "components/InformationCard";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import { ReactComponent as CrossIcon } from "../../../assets/button_svg/Vector.svg";
import CustomDatePicker from "components/CustomDatePicker";
import { helperFunction } from "services/helperFunction";
import { Constants } from "services/keyConstants";
import { getObjectPermissions, getSecureToken } from "services/utils";
import DeleteModal from "components/DeleteModal";
import { parseISO, format, addDays } from "date-fns";
import { spaceStyle } from "../../../services/commonStyles";

import * as Yup from "yup";

// const categories = [
//   {
//     name: "Certification",
//     subCategories: [
//       "CE Certification",
//       "Free Sale Certificate",
//       "GMP",
//       "ISO 13485 Certification",
//       "ISO 9001 Certification",
//     ],
//   },
//   {
//     name: "Consulting",
//     subCategories: [
//       "AMC - General",
//       "AMC - Premium",
//       "ANVISA",
//       "CE - IVDR",
//       "CE - MDR",
//       "Clinical Evaluation",
//       "Clinical Trial",
//       "Country Registration",
//       "EUDAMED Registration",
//       "GAP Assessment - EU IVDR",
//       "GAP Assessment - EU MDR",
//       "GAP Assessment - QMS",
//       "Health Canada",
//       "Indian MDR -Import",
//       "Indian MDR -Manufacturing",
//       "ISO 13485 Consultancy",
//       "MDSAP",
//       "MHRA Registration",
//       "NIOSH Approval",
//       "PMCF Study",
//       "PSUR",
//       "QMS Updation as per 21 CFR 820",
//       "QMS updation as per EU IVDR",
//       "QMS updation as per EU MDR",
//       "Risk Management",
//       "Testing",
//       "Training",
//       "UKCA",
//       "Usability",
//       "US FDA 510K",
//       "Validation",
//     ],
//   },
//   {
//     name: "Representation",
//     subCategories: [
//       "EAR",
//       "PRRC",
//       "UKRP",
//       "US FDA Listing", //US FDA Agent
//     ],
//   },
//   { name: "Others", hasTextInput: true },
// ];
const categories = [
  { name: "ISO 13485" },
  { name: "ISO 13485 + 21 CFR Part 820" },
  { name: "ISO 13485 + MDSAP" },
  { name: "ISO 13485 + 21 CFR Part 820 + MDSAP" },
];
const probabilityOptions = ["Hot", "Warm", "Cold"];

const leadSourceOption = [
  "External Referral",
  "Trade Show",
  "Existing Client",
  "Internal Referral",
  "Marketing",
  "Cold Call",
  "Other",
];
const stageOptions = [
  "Prospecting",
  "Needs Analysis",
  "Informal Quote",
  "Negotiation",
  "Formal Quote",
  "Closed Won",
  "Closed Lost",
];
const lostReasonOptions = [
  "No Response",
  "Quote too high ",
  "Awarded to Competitor",
  "Other",
];

const titleStyle = {
  color: "secondary",
  sx: {
    p: 0,
    fontSize: "12px",
  },
};

const AddEditOpportunity = ({
  id = null,
  data,
  setData,
  setAddEditOpen,
  setId,
  parentData,
  parentModel,
  setRecallState,
}) => {
  const [user, setUser] = useState([]);
  const [deleteData, setDeleteData] = useState(null);
  const [options, setOptions] = useState({
    account: [],
    currency: [],
    contact: [],
  });
  const location = useLocation();

  const permission = getObjectPermissions()?.opportunity;

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Opportunity Name is required"),
    enquiryCategory: Yup.string()
      .trim()
      .required("Enquiry Category is required"),
    // enquiryFor: Yup.string().trim().required("enquiryFor is required"),
    startDate: Yup.string().trim().required("Start Date is required"),
  });

  const formik = useFormik({
    initialValues: {
      ...data,
      name: data?.name || "",
      enquiryCategory: data?.enquiryCategory || "",
      // enquiryFor: data?.enquiryFor || "",
      startDate: data?.startDate ? data?.startDate : new Date() || "",
      ownerId: data?.ownerId
        ? data.ownerId
        : getSecureToken(Constants.KEY_USER_DATA),
    },
    validationSchema, //addDays(new Date(), 0)
    onSubmit: (values) => {
      if (id) {
        console.log("values>>>", values);
        const updatedValues = helperFunction.getUpdatedValues(values, data);
        update(updatedValues);
      } else {
        create(values);
      }
    },
  });

  const subCategories = formik?.values?.enquiryCategory
    ? categories.find(
        (category) => category?.name === formik?.values?.enquiryCategory
      )?.subCategories || []
    : [];

  const currencyData = () => {
    try {
      let uri = url.CURRENCY;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              currency: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const getAccount = (query) => {
    try {
      let uri = url.ACCOUNT + `/name/${query}`;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              account: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const getContact = (query) => {
    try {
      if (query !== undefined) {
        let uri = url.CONTACT + `?accountId=${query}`;
        ApiServices.callServiceGet(uri)
          .then((response) => {
            if (response?.result?.type === "success") {
              setOptions((prev) => ({
                ...prev,
                contact: response?.result?.data || [],
              }));
            } else {
              Toastr(
                response?.result?.message
                  ? response?.result?.message
                  : "Error Occurred",
                "Failed"
              );
            }
          })
          .catch((error) => {
            console.warn(" API error", error);
          });
      }
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const getOpportunityDetail = () => {
    try {
      let uri = url.OPPORTUNITY + id;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.data);
            setData(response?.result?.data);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const userData = () => {
    try {
      let uri = url.USERS;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setUser(response?.result?.users);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const update = (values) => {
    try {
      let uri = url.OPPORTUNITY + id;
      let body = {
        ...values,
      };

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("Opportunity Updated Successfully", Constants.SUCCESS);
            if (setRecallState) {
              setRecallState(true);
            }

            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const create = (values) => {
    try {
      let uri = url.OPPORTUNITY;
      let body = {
        ...values,
      };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("Opportunity Added Successfully", Constants.SUCCESS);
            setRecallState(true);
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const handleAccountInputChange = (event, newInputValue, reason) => {
    if (reason === "input") {
      if (newInputValue?.length >= 3) {
        getAccount(newInputValue);
      } else {
        setOptions((prev) => ({ ...prev, account: [] }));
      }
    }
  };

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: data?.name });
  };

  useEffect(() => {
    if (id && !data) {
      getOpportunityDetail();
    }
    userData();
    currencyData();
    getContact(formik.values?.accountId?._id);

    if (parentModel) {
      if (parentModel == "account") {
        formik.setFieldValue("accountId", parentData);
      } else if (parentData?.accountId) {
        formik.setFieldValue("accountId", parentData?.accountId);
      } else {
        getAccount(`getallvalues?${parentModel}Id=${parentData?._id}`);
      }
    }
  }, []);

  return (
    <>
      <DeleteModal
        dataUrl={url.OPPORTUNITY}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        //    callFunc={addFilter}
        navLink={"/opportunity-list"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          py: 2,
        }}
      >
        <Typography
          component="div"
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          Opportunity Information
        </Typography>
        <>
          <Typography
            component="span"
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
            }}
          >
            <IconButton
              size="small"
              color="green"
              onClick={() => {
                setAddEditOpen((prev) => !prev);
                if (location?.pathname === "/opportunity-list") {
                  setData(null);
                  setId(null);
                }
              }}
              sx={{
                p: 0.95,
                borderRadius: "5px",
                border: "1px solid red",
              }}
            >
              <CrossIcon width="2" height="2" />
            </IconButton>

            <IconButton
              size="small"
              color="green"
              onClick={() => formik.handleSubmit()}
              sx={{
                p: 0,
                borderRadius: "5px",
                border: "1px solid green",
              }}
            >
              <BookmarkBorderOutlined sx={{ color: "green", height: "16px" }} />
            </IconButton>

            {id && permission !== undefined && permission[0] >= 3 && (
              <IconButton
                size="small"
                onClick={handleOpenDelete}
                sx={{
                  p: 0,
                  borderRadius: "5px",
                  border: "1px solid #4680FF ",
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Typography>
        </>
      </Box>
      <Box
        sx={{
          m: 0,
          p: 0,
          maxHeight: "65vh",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <InformationCard title="Core Details" color="blue">
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Opportunity Name
                <Typography variant="span" color="red">
                  *
                </Typography>
              </Typography>
              <TextField
                name="name"
                value={formik?.values?.name}
                placeholder="Enter Opportunity Name"
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />

              <Typography {...titleStyle}>Account Name</Typography>
              <Autocomplete
                id="accountName"
                name="accountName"
                options={options?.account}
                getOptionLabel={(option) =>
                  option && typeof option === "object" ? option.name || "" : ""
                }
                value={
                  typeof formik.values?.accountId === "object"
                    ? formik.values.accountId
                    : options?.account?.find(
                        (option) => option._id === formik.values?.accountId
                      ) || null
                }
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue("contactId", null);
                  formik.setFieldValue(
                    "accountId",
                    newValue ? newValue?._id : null
                  );
                  getContact(newValue?._id);
                }}
                onInputChange={handleAccountInputChange}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Account Name"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />

              <Typography {...titleStyle}>
                Enquiry Category
                <Typography variant="span" color="red">
                  *
                </Typography>
              </Typography>
              <Autocomplete
                id="enquiryCategory"
                name="enquiryCategory"
                options={categories}
                getOptionLabel={(option) => option?.name || ""}
                onChange={(event, newValue) => {
                  // formik.setFieldValue("enquiryFor", "");
                  formik.setFieldValue(
                    "enquiryCategory",
                    newValue ? newValue?.name : null
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.name === value?.name
                }
                // value={{ name: formik.values?.enquiryCategory }}
                value={
                  categories.find(
                    (cat) => cat.name === formik.values?.enquiryCategory
                  ) || null
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Enquiry Category"
                    error={
                      formik.touched.enquiryCategory &&
                      Boolean(formik.errors.enquiryCategory)
                    }
                    helperText={
                      formik.touched.enquiryCategory &&
                      formik.errors.enquiryCategory
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
              {formik.errors?.enquiryFor && !formik.errors.enquiryCategory && (
                <Typography variant="span" {...spaceStyle}>
                  &nbsp;
                </Typography>
              )}

              <Typography {...titleStyle}>
                Start Date
                <Typography variant="span" color="red">
                  *
                </Typography>
              </Typography>
              <CustomDatePicker
                value={formik?.values?.startDate}
                onChange={(date) => formik.setFieldValue("startDate", date)}
                // skipDay={0}
                error={
                  formik.touched.startDate && Boolean(formik.errors.startDate)
                }
                helperText={formik.touched.startDate && formik.errors.startDate}
                placeholder="Select Start Date"
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Opportunity Owner</Typography>
              <Autocomplete
                id="ownerId"
                name="ownerId"
                options={user}
                isOptionEqualToValue={(option, value) =>
                  option._id === value?._id
                }
                getOptionLabel={(option) => option?.fullName}
                value={
                  typeof formik.values?.ownerId === "object"
                    ? {
                        fullName: formik.values.ownerId?.fullName || "",
                        _id: formik.values.ownerId?._id || "",
                      }
                    : user.find(
                        (option) => option._id === formik.values?.ownerId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue("ownerId", newValue ? newValue._id : "");
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Opportunity Owner"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />

              {formik.errors.name && formik.touched.name && (
                <Typography variant="span" {...spaceStyle}>
                  &nbsp;
                </Typography>
              )}

              <Typography {...titleStyle}>Contact Name</Typography>
              <Autocomplete
                id="contactName"
                name="contactName"
                options={options?.contact || []}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                getOptionLabel={(option) => option?.fullName}
                value={
                  typeof formik.values.contactId === "object"
                    ? {
                        fullName: formik.values.contactId?.fullName || "",
                        _id: formik.values.contactId?._id || "",
                      }
                    : options?.contact?.find(
                        (option) => option._id === formik.values?.contactId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "contactId",
                    newValue ? newValue?._id : ""
                  );
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Contact Name"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />

              {/* <Typography {...titleStyle}>
                Enquiry For
                <Typography variant="span" color="red">
                  *
                </Typography>
              </Typography> */}
              {/* {formik?.values?.enquiryCategory !== "Others" ? (
                <Autocomplete
                  id="enquiryFor"
                  name="enquiryFor"
                  options={subCategories.map((data) => {
                    return { label: data, value: data };
                  })}
                  getOptionLabel={(option) =>
                    option.label ? option.label : ""
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  }
                  value={{
                    label: formik.values?.enquiryFor,
                    value: formik.values?.enquiryFor,
                  }}
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "enquiryFor",
                      newValue?.value ? newValue?.value : ""
                    );
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        formik.touched.enquiryFor &&
                        Boolean(formik.errors.enquiryFor)
                      }
                      helperText={
                        formik.touched.enquiryFor && formik.errors.enquiryFor
                      }
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          opacity: 1,
                          color: "#4c4d4f !important",
                          WebkitTextFillColor: "#4c4d4f !important",
                        },
                      }}
                    />
                  )}
                />
              ) : (
                <TextField
                  name="enquiryFor"
                  placeholder="Enter enquiry category"
                  error={
                    formik.touched.enquiryFor &&
                    Boolean(formik.errors.enquiryFor)
                  }
                  helperText={
                    formik.touched.enquiryFor && formik.errors.enquiryFor
                  }
                  value={formik?.values?.enquiryFor}
                  onChange={formik.handleChange}
                  fullWidth
                  InputProps={{
                    sx: {
                      height: "41px",
                    },
                  }}
                />
              )} */}
              {/* {!formik.errors.enquiryFor && formik.errors.enquiryCategory && (
                <Typography variant="span" {...spaceStyle}>
                  &nbsp;
                </Typography>
              )} */}
              <Typography {...titleStyle}>Stage</Typography>
              <Autocomplete
                id="stage"
                name="stage"
                options={stageOptions}
                value={formik.values.stage || ""}
                onChange={(event, newValue) => {
                  formik.setFieldValue("stage", newValue ? newValue : "");
                }}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Stage"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
              <Typography {...titleStyle}>End Date</Typography>
              <CustomDatePicker
                value={formik?.values?.endDate}
                onChange={(date) => formik.setFieldValue("endDate", date)}
                placeholder="Select End Date"
              />
              {formik.errors.startDate && formik.touched.startDate && (
                <Typography variant="span" {...spaceStyle}>
                  &nbsp;
                </Typography>
              )}
            </Grid>
          </InformationCard>

          <InformationCard title="General Details" color="#E76767">
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Lead Source</Typography>
              <Autocomplete
                id="leadSource"
                name="leadSource"
                options={leadSourceOption}
                // isOptionEqualToValue={(option, value) =>
                //   option?._id === value?._id
                // }
                value={formik.values.leadSource || null}
                onChange={(event, newValue) => {
                  formik.setFieldValue("leadSource", newValue ? newValue : "");
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Lead Source"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />

              <Typography {...titleStyle}>Currency</Typography>
              <Autocomplete
                id="currencyId"
                name="currencyId"
                options={options?.currency}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                getOptionLabel={(option) =>
                  option && typeof option === "object"
                    ? option.isoCode || ""
                    : ""
                }
                value={
                  typeof formik.values.currencyId === "object"
                    ? {
                        isoCode: formik.values.currencyId?.isoCode || "",
                        _id: formik.values.currencyId?._id || "",
                      }
                    : options.currency.find(
                        (option) => option._id === formik.values?.currencyId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "currencyId",
                    newValue ? newValue?._id : ""
                  );
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Currency"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />

              <Typography {...titleStyle}>Probability</Typography>
              <Autocomplete
                id="probability"
                name="probability"
                options={probabilityOptions}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                value={
                  typeof formik.values.probabilityHWC === "string"
                    ? {
                        label: formik.values?.probabilityHWC || "",
                        value: formik.values?.probabilityHWC || "",
                      }
                    : probabilityOptions.find(
                        (option) => option === formik.values?.probabilityHWC
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "probabilityHWC",
                    newValue ? newValue : ""
                  );
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Probability"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Lead Detail</Typography>
              <TextField
                name="leadDetails"
                value={formik?.values?.leadDetails}
                placeholder="Enter Lead Detail"
                onChange={formik.handleChange}
                fullWidth
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />

              <Typography {...titleStyle}>Amount</Typography>
              <TextField
                name="amount"
                type="number"
                placeholder="Enter Amount"
                value={formik?.values?.amount}
                onChange={formik.handleChange}
                fullWidth
                onWheel={(e) => e.target.blur()}
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
          </InformationCard>

          <InformationCard title="Other Details" color="#2CA87F">
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Lost Reason</Typography>
              <Autocomplete
                id="lostReason"
                name="lostReason"
                options={lostReasonOptions}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                value={
                  typeof formik.values.lostReason === "string"
                    ? {
                        label: formik.values?.lostReason || "",
                        value: formik.values?.lostReason || "",
                      }
                    : lostReasonOptions.find(
                        (option) => option === formik.values?.lostReason
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue("lostReason", newValue ? newValue : "");
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Lost Reason"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />

              <Typography {...titleStyle}>Description</Typography>
              <TextField
                name="description"
                value={formik?.values?.description}
                placeholder="Enter Description"
                onChange={formik.handleChange}
                fullWidth
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}> Lost Reason Text</Typography>
              <TextField
                name="lostReasonAdditional"
                value={formik?.values?.lostReasonAdditional}
                placeholder="Enter Lost Reason Text"
                onChange={formik.handleChange}
                fullWidth
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />

              <Typography {...titleStyle}>Notes</Typography>
              <TextField
                name="notes"
                value={formik?.values?.notes}
                placeholder="Enter Notes"
                onChange={formik.handleChange}
                fullWidth
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
          </InformationCard>
        </form>
      </Box>
    </>
  );
};
export default AddEditOpportunity;

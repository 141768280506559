import { BookmarkBorderOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Constants } from "services/keyConstants";
import { Box } from "@mui/system";
import CustomDatePicker from "components/CustomDatePicker";
import InformationCard from "components/InformationCard";
import { Toastr } from "components/Toastr";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { ApiServices } from "services/apiServices";
import * as url from "services/url_helper";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import { ReactComponent as CrossIcon } from "../../../assets/button_svg/Vector.svg";
import JoditEditor from "jodit-react";
import DeleteModal from "components/DeleteModal";
import { getSecureToken } from "services/utils";
import { titleStyle } from "services/commonStyles";
import { getObjectPermissions } from "services/utils";


const AddEditBank = ({ id = null, data, setData, setAddEditOpen, setId }) => {
  const [options, setOptions] = useState({
    users: [],
    currency: [],
  });
  const editor = useRef(null);
  const [deleteData, setDeleteData] = useState(null);

  const formik = useFormik({
    initialValues: {
      ...data,
      ownerId: data?.ownerId
        ? data?.ownerId
        : getSecureToken(Constants?.KEY_USER_DATA),
    },
    onSubmit: (values) => {
      if (id) {
        update(values);
      } else {
        create(values);
      }
    },
  });

  const [localContent, setLocalContent] = useState(
    formik?.values?.bankDetails || ""
  );

  const getBankData = () => {
    try {
      let uri = url.BANK + id;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.data);
            setData(response?.result?.data);
            setLocalContent(response?.result?.data?.bankDetails);
          } else {
            setIsLoading(false);
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" Function Error", error);
    }
  };

  const getOwner = () => {
    try {
      let uri = url.USERS;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              users: response?.result?.users,
            }));
          } else {
            setIsLoading(false);
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" Function Error", error);
    }
  };

  const update = (values) => {
    try {
      let uri = url.BANK + id;
      let body = {
        ...values,
      };

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);
            setAddEditOpen(false);
            Toastr("Bank Updated Successfully", Constants.SUCCESS);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const create = (values) => {
    try {
      let uri = url.BANK;
      let body = {
        ...values,
      };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("Bank Added Successfully", Constants.SUCCESS);
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const currencyData = () => {
    try {
      let uri = url.CURRENCY;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              currency: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: data?.name });
  };

  useEffect(() => {
    if (id && !data) {
      getBankData();
    }
    getOwner();
    currencyData();
  }, []);
  const permission = getObjectPermissions()?.bank;

  return (
    <>
      <DeleteModal
        dataUrl={url.BANK}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        //    callFunc={addFilter}
        navLink={"/bank-list"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          py: 2,
        }}
      >
        <Typography
          component="div"
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          Bank Information
        </Typography>
        {permission !== undefined && permission[0] >= 3 && (
          <>
            <Typography
              component="span"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <IconButton
                size="small"
                onClick={() => {
                  setAddEditOpen((prev) => !prev);
                  if (location?.pathname === "/bank-list") {
                    setData(null);
                    setId(null);
                  }
                }}
                sx={{
                  p: 0.95,
                  borderRadius: "5px",
                  border: "1px solid red",
                }}
              >
                <CrossIcon width="2" height="2" />
              </IconButton>

              <IconButton
                size="small"
                color="green"
                onClick={() => {
                  formik.handleSubmit();
                }}
                sx={{
                  p: 0,
                  borderRadius: "5px",
                  border: "1px solid green",
                }}
              >
                <BookmarkBorderOutlined
                  sx={{ color: "green", height: "16px" }}
                />
              </IconButton>

              {id && (
                <IconButton
                  size="small"
                  // color="primary"
                  onClick={handleOpenDelete}
                  sx={{
                    p: 0,
                    borderRadius: "5px",
                    border: "1px solid #4680FF ",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          m: 0,
          p: 0,
          maxHeight: "65vh",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <InformationCard title="Core Details" color="red">
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle} variant="h6">
                Bank Name
              </Typography>
              <TextField
                name="name"
                value={formik?.values?.name}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Bank Name"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Owner Name</Typography>
              <Autocomplete
                id="ownerId"
                name="ownerId"
                options={options?.users || []}
                getOptionLabel={(option) => option?.fullName}
                value={
                  typeof formik?.values?.ownerId === "object"
                    ? {
                        fullName: formik?.values?.ownerId?.fullName || "",
                        _id: formik?.values?.ownerId?._id || "",
                      }
                    : options?.users?.find(
                        (option) => option?._id === formik?.values?.ownerId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "ownerId",
                    newValue ? newValue?._id : ""
                  );
                }}
                onBlur={formik.handleBlur}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Owner Name"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle} variant="h6">
                Currency
              </Typography>
              <Autocomplete
                id="currencyId"
                name="currencyId"
                options={options?.currency}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                getOptionLabel={(option) =>
                  option && typeof option === "object"
                    ? option.isoCode || ""
                    : ""
                }
                value={
                  typeof formik?.values?.currencyId === "object"
                    ? {
                        isoCode: formik?.values?.currencyId?.isoCode || "",
                        _id: formik?.values.currencyId?._id || "",
                      }
                    : options.currency.find(
                        (option) => option?._id === formik?.values?.currencyId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "currencyId",
                    newValue ? newValue?._id : ""
                  );
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Currency"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </InformationCard>
          <InformationCard title="General Detail" color="blue">
            <Grid item xs={12} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Bank Details</Typography>

              <JoditEditor
                ref={editor}
                value={localContent || ""}
                onBlur={() => formik.setFieldTouched("bankDetails", true)}
                onChange={(content) => {
                  setLocalContent(content);
                  formik.setFieldValue("bankDetails", content);
                }}
              />
            </Grid>
          </InformationCard>
        </form>
      </Box>
    </>
  );
};

export default AddEditBank;

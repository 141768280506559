import { useState, useRef, useMemo, useEffect } from "react";
import { useParams } from "react-router";

import JoditEditor from "jodit-react";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  IconButton,
  Divider,
  TextField,
  Dialog,
  DialogContent,
} from "@mui/material";
import { PopupTransition } from "components/@extended/Transitions";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Toastr } from "../Toastr";
import { Note1 } from "iconsax-react";
import { useFormik } from "formik";
import * as url from "services/url_helper";
import { ApiServices } from "services/apiServices";
import { Constants } from "services/keyConstants";
import { helperFunction } from "services/helperFunction";

export default function NotesPopup({
  id = null,
  data,
  setData,
  addEditOpen,
  setAddEditOpen,
  setId,
  parentData,
  parentModel,
  setRecallState,
}) {
  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      buttons: [
        "bold",
        "italic",
        "underline",
        "align",
        "ul",
        "indent",
        "outdent",
        "font",
        "fontsize",
        "lineHeight",
      ],
      toolbarAdaptive: false,
      toolbarSticky: false,
      statusbar: false,
      style: {
        border: "none",
        outline: "none",
        boxShadow: "none",
      },
    }),
    []
  );

  const formik = useFormik({
    initialValues: {
      ...data,
      view: data?.view ? data?.view : "Private",
      relatedTo: data?.relatedTo ? data?.relatedTo : parentData?._id,
      schemapath: data?.schemapath ? data?.schemapath : parentModel,
    },

    onSubmit: (values) => {
      if (id) {
        const updatedValues = helperFunction.getUpdatedValues(values, data);
        update(updatedValues);
      } else {
        create(values);
      }
    },
  });

  const [textContent, setTextContent] = useState(formik?.values?.text);

  const create = (values) => {
    try {
      let uri = url.NOTES;

      let body = {
        ...values,
      };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);
            Toastr("Notes Added Successfully", Constants.SUCCESS);
            setRecallState(true);
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const update = (values) => {
    try {
      let uri = url.NOTES + id;
      let body = {
        ...values,
      };

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("Notes Updated Successfully", Constants.SUCCESS);
            setRecallState(true);
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const getNotesDetail = () => {
    try {
      let uri = url.NOTES + id;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.data);
            setData(response?.result?.data);
            setTextContent(response?.result?.data?.text);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };
  useEffect(() => {
    if (id && !data) {
      getNotesDetail();
    }
  });

  return (
    <Dialog
      open={addEditOpen}
      onClose={() => {
        setAddEditOpen(false);
      }}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper": {
          // width: { xs: "90%", sm: "800px" },
          // minHeight: "50%",
          borderRadius: "20px",
          overflow: "auto",
        },
      }}
      style={{ zIndex: 4 }}
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            py: 2,
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box
              borderRadius={2}
              width="650px"
              p={2}
              display="flex"
              flexDirection="column"
              gap={0.5}
              sx={{ backgroundColor: "white" }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="row">
                  <Typography>
                    <Note1 variant="Bold" color="#6293FF" />
                  </Typography>
                  <div>
                    <TextField
                      variant="standard"
                      name="heading"
                      value={formik?.values?.heading}
                      onChange={formik?.handleChange}
                      placeholder="Enter heading..."
                      // onBlur={handleBlur}
                      onBlur={formik.handleBlur}
                      autoFocus
                      fullWidth
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": { borderBottom: "none" },
                        "& .MuiInput-underline:hover:before": {
                          borderBottom: "none",
                        },
                      }}
                    />
                  </div>
                </Box>
                <IconButton sx={{ paddingLeft: "10px" }}>
                  <CloseIcon
                    onClick={() => {
                      setAddEditOpen(false);
                    }}
                  />
                </IconButton>
              </Box>
              <Divider color="#CFD1D4" sx={{ borderBottomWidth: 2 }} />

              <Box
                sx={{
                  "& .jodit-container": {
                    border: "none !important",
                    boxShadow: "none",
                    width: "100%",
                    height: "100px !important",
                    display: "flex",
                    flexDirection: "column",
                  },
                  "& .jodit-workplace": {
                    height: "100% !important",
                    overflow: "auto !important",
                  },
                }}
              >
                <JoditEditor
                  ref={editor}
                  value={textContent}
                  config={config}
                  onBlur={() => formik.setFieldTouched("text", true)}
                  onChange={(content) => {
                    formik.setFieldValue("text", content);
                  }}
                />
              </Box>

              <Box display="flex" justifyContent="right">
                <Box display="flex" alignItems="center">
                  <ToggleButtonGroup
                    name="view"
                    value={formik?.values?.view || "Public"}
                    exclusive
                    onChange={(_, newValue) => {
                      if (newValue !== null) {
                        formik.setFieldValue("view", newValue);
                      }
                    }}
                    sx={{
                      borderRadius: "50px",
                      backgroundColor: "#FFFFFF",
                      display: "flex",
                      overflow: "hidden",
                      border: "1px solid #6293FF",
                    }}
                  >
                    <ToggleButton
                      value="Public"
                      sx={{
                        fontSize: "10px",
                        width: "49px",
                        height: "25px",
                        borderTopLeftRadius: "50px",
                        borderBottomLeftRadius: "50px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        color:
                          formik?.values?.view === "Public"
                            ? "white"
                            : "#6293FF",
                        backgroundColor:
                          formik?.values?.view === "Public"
                            ? "#6293FF"
                            : "transparent",
                        "&.Mui-selected": {
                          backgroundColor: "#6293FF !important",
                          color: "white",
                        },
                        "&:hover": {
                          backgroundColor:
                            formik?.values?.view === "Public"
                              ? "#4F82E9"
                              : "transparent",
                        },
                      }}
                    >
                      Public
                    </ToggleButton>
                    <ToggleButton
                      value="Private"
                      sx={{
                        fontSize: "10px",
                        width: "49px",
                        height: "25px",
                        borderTopRightRadius: "50px",
                        borderBottomRightRadius: "50px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        color:
                          formik?.values?.view === "Private"
                            ? "white"
                            : "#6293FF",
                        backgroundColor:
                          formik?.values?.view === "Private"
                            ? "#6293FF"
                            : "transparent",
                        "&.Mui-selected": {
                          backgroundColor: "#6293FF !important",
                          color: "white",
                        },
                        "&:hover": {
                          backgroundColor:
                            formik?.values?.view === "Private"
                              ? "#4F82E9"
                              : "transparent",
                        },
                      }}
                    >
                      Private
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                <IconButton
                  color="primary"
                  onClick={() => {
                    if (formik.values) {
                      formik.handleSubmit();
                    }
                  }}
                >
                  <CheckIcon />
                </IconButton>
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { PopupTransition } from "components/@extended/Transitions";
import withRouter from "components/withRouter";
import { Toastr } from "./Toastr";
import { ApiServices } from "services/apiServices";
import { Constants } from "services/keyConstants";
import { useState } from "react";
import { useNavigate } from "react-router";

const DeleteModal = (props) => {
  const { dataUrl, deleteData, setDeleteData, navLink, callFunc } = props;

  const navigate = useNavigate();
  const deleteById = () => {
    try {
      let uri = dataUrl + deleteData?.id;

      ApiServices.callServiceDelete(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            if (!navLink) {
              callFunc();
            } else {
              navigate(navLink);
            }
            Toastr(
              `${deleteData?.title} Deleted Successfully.`,
              Constants.SUCCESS
            );
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              Constants.ERROR
            );
          }
        })
        .catch((error) => {
          console.error("DELETE API error", error);
        });
    } catch (error) {
      console.error("DELETE function error", error);
    }
  };

  const handleDelete = () => {
    deleteById();
    setDeleteData({ open: false });
  };

  return (
    <Dialog
      open={deleteData?.open}
      onClose={() => setDeleteData({ open: false })}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="xs"
      sx={{
        "& .MuiDialog-paper": {
          width: { xs: "90%", sm: "450px" },
          borderRadius: "20px",
          overflow: "auto",
        },
      }}
      style={{ zIndex: 5 }}
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
    >
      <DialogContent sx={{ mt: 2, my: 1 }} style={{ borderRadius: "30px" }}>
        <Stack alignItems="center" spacing={3.5}>
          <Stack spacing={2}>
            <Typography align="center" style={{ fontSize: "16px" }}>
              Do you want to delete
              <Typography
                variant="subtitle1"
                component="span"
                style={{ margin: "0 5px" }}
              >
                {deleteData?.title}
              </Typography>
              🧐?
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={3}
            sx={{ width: 1, padding: "0px 50px" }}
          >
            <Button
              fullWidth
              style={{
                borderRadius: "60px",
                backgroundColor: "#E76767",
                width: "60%",
                fontSize: "12px",
              }}
              variant="contained"
              onClick={handleDelete}
            >
              Yes For Sure
            </Button>
            <Button
              fullWidth
              onClick={() => {
                setDeleteData({ open: false });
              }}
              sx={{
                borderRadius: "60px",
                backgroundColor: "#6293FF",
                color: "white",
                width: "80%",
                padding: "6px 10px",
                whiteSpace: { xs: "normal", sm: "nowrap" },
                fontSize: "12px",
              }}
              variant="contained"
            >
              I changed my mined
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default withRouter(DeleteModal);

import { height, maxHeight } from "@mui/system";
import { Colors } from "./colorConstant";

export const dataStyle = {
  variant: "body2",
  component: "div",
  sx: {
    p: 0,
    color: "#1E1E1E",
    fontSize: "14px",
    fontWeight: "400",
  },
};

export const titleStyle = {
  color: "secondary",
  sx: {
    p: 0,
    fontSize: "12px",
    mt: 1,
  },
};

export const spaceStyle = {
  display: "inline-block",
  fontSize: "0.875em",
  marginTop: "0.22rem",
  visibility: "hidden",
};

export const cardStyle = {
  backgroundColor: "transparent",
  boxShadow: 0,
  borderRadius: "10px",
  border: "1px solid #4680FF",
  maxHeight: "100%",
  height: "100%",
};

export const containerStyle = {
  backgroundColor: Colors.DEFAULT_COLOR,
  borderRadius: "5px",
  maxHeight: "100%",
  width: "auto",
  height: "100%",
};

export const boxStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  py: 2,
};

export const typographyStyle = {
  component: "span",
  sx: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
  },
};

export const mainBoxStyle = {
  m: 0,
  p: 0,
  //  maxHeight: "66vh",
  maxHeight: "90%",
  overflowY: "auto",
  scrollbarWidth: "none",
};

export const warmColor = {
  primary: "#E99C26",
  secondary: "#EDAD4D",
  third: "#F7DCB3",
};

export const hotColor = {
  primary: "#E14747",
  secondary: "#E76767",
  third: "#F7C1C1",
};

export const coldColor = {
  primary: "#6293FF",
  secondary: "#7EA6FF",
  third: "#C8D9FF",
};

export const defaultKanban = {
  default: "#BAA1FF",
};

export const kanban = {
  warm: "#E99C26",
  hot: "#E14747",
  cold: "#6293FF",
  default: "#BAA1FF",
};

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  Divider,
  List,
  Grid,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { Edit2 } from "iconsax-react";
import { defaultKanban, warmColor } from "services/commonStyles";
import { hotColor } from "services/commonStyles";
import { coldColor } from "services/commonStyles";
import { kanban } from "services/commonStyles";

import { lighten } from "@mui/system";
const KanbanTable = ({ data }) => {
  const style = {
    py: 0,
    width: "100%",
    maxWidth: 360,
    borderRadius: 2,
    backgroundColor: "background.paper",
  };
  const ThermometerButton = ({
    level = 90,
    width = 20,
    height = 60,
    fillColor,
  }) => {
    const tempLevel = Math.min(100, Math.max(0, level));
    const { primary, secondary, third } = fillColor;

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: `${width}px`,
          height: `${height + 20}px`,
          position: "relative",
        }}>
        <Box
          sx={{
            width: `${width / 1.75}px`,
            height: `${height}px`,
            background: "lightgray",
            borderRadius: "6px",
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: 0,
            position: "relative",
            border: "2px solid #94C6D6",
            overflow: "visible",
          }}>
          <Box
            sx={{
              width: "3px",
              height: "1px",
              borderRadius: "1px",
              backgroundColor: "#9192A1",
              position: "absolute",
              top: 5,
              left: -1,
              zIndex: 4,
            }}
          />
          <Box
            sx={{
              width: "3px",
              height: "1px",
              borderRadius: "1px",
              backgroundColor: "#9192A1",
              position: "absolute",
              top: 10,
              left: -1,
              zIndex: 4,
            }}
          />
          <Box
            sx={{
              width: "3px",
              height: "1px",
              borderRadius: "1px",
              backgroundColor: "#9192A1",
              position: "absolute",
              top: 15,
              left: -1,
              zIndex: 4,
            }}
          />
          <Box
            sx={{
              width: "3px",
              height: "1px",
              borderRadius: "1px",
              backgroundColor: "#9192A1",
              position: "absolute",
              top: 20,
              left: -1,
              zIndex: 4,
            }}
          />
          <Box
            sx={{
              width: "3px",
              height: "1px",
              borderRadius: "1px",
              backgroundColor: "#9192A1",
              position: "absolute",
              top: 25,
              left: -1,
              zIndex: 4,
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: "0",
              width: "100%",
              // height:'10px',
              height: `${tempLevel}%`,
              // background: `linear-gradient(to top, ${fillColor}, #F7C1C1)`,
              backgroundColor: secondary,
              transition: "height 0.3s ease",
              borderRadius: "0px",
              zIndex: 2,
            }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "49%",
                height: `calc(100% + 4px)`,
                // background: `linear-gradient(to top, ${fillColor}, #F7C1C1)`,
                backgroundColor: third,
                transition: "height 0.3s ease",
                borderRadius: "0px",
                zIndex: 3,
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: `${width}px`,
            height: `${width}px`,
            background: primary,
            // background: fillColor,
            borderRadius: "50%",
            border: "2px solid #94C6D6",
            marginTop: "-5px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            position: "relative",
          }}>
          <Box
            sx={{
              width: "85%",
              height: "85%",
              background: secondary,
              borderRadius: "50%",
              position: "absolute",
              top: "0",
              left: 1.4,
            }}>
            <Box
              sx={{
                width: "37%",
                height: "37%",
                background: third,
                borderRadius: "50%",
                position: "absolute",
                top: 2.5,
                left: "1px",
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const KanbanCard = ({ title, subTitle, thermometer = null, borderColor }) => {
    return (
      <Paper
        sx={{
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          borderTop: `8px solid ${borderColor}`,
          borderRight: `1px solid ${borderColor}`,
          borderBottom: `1px solid ${borderColor}`,
          borderLeft: `1px solid ${borderColor}`,
          justifyContent: "space-around",
          margin: "5px 0px 15px 0px",
        }}>
        <Box sx={{ padding: "10px" }}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0%",
              }}>
              {title}
            </Typography>

            <Typography
              sx={{
                width: "18px",
                height: "18px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "3px",
                padding: "2px",
                border: `0.8px solid ${borderColor}`,
                fontSize: "12px",
                fontWeight: 500,
                fontFamily: "Inter, sans-serif",
              }}>
              <Edit2 variant='Bulk' color={borderColor} />
            </Typography>
          </Box>

          <List>
            <Divider sx={{ borderColor: `${borderColor}` }} />
          </List>

          <Box>
            <Grid container alignItems='center'>
              <Grid item md={10} xs={9}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "400",
                      color: "#000000",
                    }}>
                    {subTitle}
                  </Typography>
                </Box>

                <Box
                  display='flex'
                  alignItems='center'
                  width='fit-content'
                  borderRadius='6px'
                  mt={1}
                  sx={{
                    backgroundColor: lighten(borderColor, 0.8),
                    padding: "3px",
                  }}>
                  <EventIcon
                    sx={{ color: borderColor, fontSize: "16px", mr: 0.5 }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "10px",
                      lineHeight: "20px",
                      letterSpacing: "0%",
                      color: borderColor,
                    }}>
                    29/05/2023
                  </Typography>
                </Box>
              </Grid>
              {thermometer && (
                <Grid
                  item
                  md={2}
                  xs={3}
                  display='flex'
                  justifyContent='flex-end'>
                  <ThermometerButton
                    level={40}
                    width={19}
                    height={30}
                    fillColor={
                      thermometer === "Hot"
                        ? hotColor
                        : thermometer === "Warm"
                        ? warmColor
                        : thermometer === "Cold"
                        ? coldColor
                        : thermometer === ""
                        ? kanban.default
                        : ""
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Paper>
    );
  };

  const CustomRow = ({ title = "", children, count = 0, totalSum = 0 }) => {
    return (
      <>
        <Box
          sx={{
            height: "40px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#E9F0FF",
            padding: "5px 10px",
            position: "sticky",
            top: 5,
            zIndex: 10,
          }}>
          <Typography>{title}</Typography>
          <Typography
            sx={{
              width: "28px",
              height: "22px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "6px",
              backgroundColor: "#7EA6FF",
              fontSize: "12px",
              fontWeight: 500,
              fontFamily: "Inter, sans-serif",
            }}>
            {count}
          </Typography>
        </Box>
        <Box
          sx={{
            paddingTop: "5px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography
            sx={{
              color: "#4680FF",
              fontWeight: "500",
              fontSize: "17px",
              textAlign: "center",
            }}>
            {totalSum}
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: "100%",
        overflowY: "auto",
        scrollbarWidth: "none",
        height: "65vh",
        backgroundColor: "white",
        borderRadius: "15px",
      }}>
      <Box
        sx={{
          backgroundColor: "white",
          display: "flex",
          scrollbarWidth: "none",
          padding: "10px 15px",
        }}>
        {data?.map((value, index) => {
          return (
            <Box
              key={index}
              sx={{
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                minWidth: "250px",
                width: "100%",
              }}>
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "10px",
                  position: "sticky",
                  top: 0,
                  zIndex: 10,
                }}
              />
              <CustomRow
                title={value._id}
                count={value.count}
                totalSum={value.totalSum}
              />

              <Box sx={{ paddingTop: "10px" }}>
                {value?.items?.map((names, idx) => {
                  const kanbanColors = [
                    kanban.warm,
                    kanban.hot,
                    kanban.cold,
                    kanban.default,
                  ];
                  const borderColor =
                    names?.probabilityHWC === "Hot"
                      ? kanbanColors[1]
                      : names?.probabilityHWC === "Cold"
                      ? kanbanColors[2]
                      : names?.probabilityHWC === "Warm"
                      ? kanbanColors[0]
                      : names?.probabilityHWC === ""
                      ? kanbanColors[3]
                      : kanbanColors[idx % kanbanColors.length];

                  return (
                    <KanbanCard
                      key={idx}
                      title={names?.name}
                      subTitle={names?.ownerId_fullName}
                      thermometer={names?.probabilityHWC}
                      borderColor={borderColor}
                    />
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default KanbanTable;

import {
  BookmarkBorderOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Constants } from "services/keyConstants";
import { Box } from "@mui/system";
import InformationCard from "components/InformationCard";
import { Toastr } from "components/Toastr";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { ApiServices } from "services/apiServices";
import * as url from "services/url_helper";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import { ReactComponent as CrossIcon } from "../../../assets/button_svg/Vector.svg";
import DeleteModal from "components/DeleteModal";
import { titleStyle } from "services/commonStyles";
import { helperFunction } from "services/helperFunction";

const AddEditUsers = ({
  id = null,
  data,
  setData,
  setAddEditOpen,
  setId,
  setRecallState,
}) => {
  const [options, setOptions] = useState({
    type: ["admin", "user"],
    status: [
      { value: true, lable: "Active" },
      { value: false, lable: "Inactive" },
    ],
  });
  const [deleteData, setDeleteData] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const formik = useFormik({
    initialValues: {
      ...data,
    },
    onSubmit: (values) => {
      if (id) {
        const updatedValues = helperFunction.getUpdatedValues(values, data);
        const valueWithEmail = { ...updatedValues, email: values?.email };
        update(valueWithEmail);
      } else {
        create(values);
      }
    },
  });

  const getUserData = () => {
    try {
      let uri = url.USERS + id;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.user);
            setData(response?.result?.user);
          } else {
            setIsLoading(false);
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" Function Error", error);
    }
  };
  const update = (values) => {
    const formData = new FormData();
    for (let key in values) {
      formData.append(key, values[key]);
    }
    try {
      let uri = url.USERS + id;
      ApiServices.callServicePutWithFormData(uri, formData)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.user);
            setAddEditOpen(false);
            if (setRecallState) {
              setRecallState(true);
            }
            Toastr("User Updated Successfully", Constants.SUCCESS);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };
  const create = (values) => {
    try {
      let uri = url.USERS;
      let body = {
        ...values,
      };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.user);
            Toastr("User Added Successfully", Constants.SUCCESS);
            setAddEditOpen(false);
            if (setRecallState) {
              setRecallState(true);
            }
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: data?.fullName });
  };

  useEffect(() => {
    if (id && !data) {
      getUserData();
    }
  }, []);
  const permission = [3, 1];

  return (
    <>
      <DeleteModal
        dataUrl={url.USERS}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        navLink={"/users-list"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          py: 2,
        }}
      >
        <Typography
          component="div"
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          User Details
        </Typography>
        {permission !== undefined && permission[0] >= 3 && (
          <>
            <Typography
              component="span"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <IconButton
                size="small"
                onClick={() => {
                  setAddEditOpen((prev) => !prev);
                  if (location?.pathname === "/termCondition-list") {
                    setData(null);
                    setId(null);
                  }
                }}
                sx={{
                  p: 0.95,
                  borderRadius: "5px",
                  border: "1px solid red",
                }}
              >
                <CrossIcon width="2" height="2" />
              </IconButton>

              <IconButton
                size="small"
                color="green"
                onClick={() => {
                  formik.handleSubmit();
                }}
                sx={{
                  p: 0,
                  borderRadius: "5px",
                  border: "1px solid green",
                }}
              >
                <BookmarkBorderOutlined
                  sx={{ color: "green", height: "16px" }}
                />
              </IconButton>

              {id && (
                <IconButton
                  size="small"
                  onClick={handleOpenDelete}
                  sx={{
                    p: 0,
                    borderRadius: "5px",
                    border: "1px solid #4680FF ",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          m: 0,
          p: 0,
          maxHeight: "65vh",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <InformationCard title="Core Details" color="red">
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Name</Typography>
              <TextField
                name="fullName"
                value={formik?.values?.fullName}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Full Name"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Email</Typography>
              <TextField
                name="email"
                type="email"
                value={formik?.values?.email}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Email"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Phone</Typography>
              <TextField
                name="mobileNumber"
                type="string"
                value={formik?.values?.mobileNumber}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Phone"
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Password</Typography>
              <TextField
                name="password"
                type={showPassword ? "text" : "password"}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        aria-label={
                          showPassword
                            ? "hide the password"
                            : "display the password"
                        }
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        // onMouseUp={handleMouseUpPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
          </InformationCard>
          <InformationCard title="General Detail" color="blue">
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Type</Typography>
              <Autocomplete
                id="userType"
                name="userType"
                options={options?.type}
                getOptionLabel={(option) => option}
                value={formik.values?.userType || "user"}
                onChange={(event, newValue) => {
                  formik.setFieldValue("userType", newValue || null);
                }}
                isOptionEqualToValue={(option, value) => option === value}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Status</Typography>
              <Autocomplete
                id="isActive"
                name="isActive"
                options={options?.status}
                getOptionLabel={(option) => option?.lable}
                value={
                  options?.status?.find(
                    (item) => item?.value === formik.values?.isActive || true
                  ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue("isActive", newValue?.value ?? null);
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </InformationCard>
        </form>
      </Box>
    </>
  );
};

export default AddEditUsers;

import { Card, CardContent, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Edit, Edit2, Eye, Polkadot } from "iconsax-react";
import React, { useEffect, useState } from "react";
import CustomLink from "../CustomLink";
import bg from "../../assets/bg-images/bg.svg";
import { Colors } from "services/colorConstant";
import * as url from "services/url_helper";
import { ApiServices } from "services/apiServices";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
} from "@mui/lab";
import { helperFunction } from "services/helperFunction";
import { Toastr } from "components/Toastr";
import RelatedAddEdit from "./RelatedAddEdit";
import { NetworkCellSharp } from "@mui/icons-material";
import NotesPopup from "./NotesPopup";

const NoteCard = ({ setAll, parentData, parentModel }) => {
  const [notes, setNotes] = useState([]);
  const [addEditOpen, setAddEditOpen] = useState(false);

  const [data, setData] = useState(null);
  const [recallState, setRecallState] = useState(false);
  const [id, setId] = useState(null);

  const getParentData = () => {
    try {
      let uri = url.NOTES_PARENT_RELATED_DATA + parentData?._id;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            // setIsLoading(false);
            setNotes(response?.result?.data);
          } else {
            // setIsLoading(false);

            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" Function Error", error);
    }
  };

  useEffect(() => {
    getParentData();
  }, []);
  useEffect(() => {
    if (recallState) {
      getParentData();
      setRecallState(false);
    }
  }, [recallState]);
  const CustomTimelineDot = ({ index }) => (
    <Box
      sx={{
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset",
        backgroundColor: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}>
      <Box
        sx={{
          width: "60%",
          height: "60%",
          borderRadius: "50%",
          backgroundColor: "primary.main",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}>
        {index != 0 && (
          <Box
            sx={{
              width: "60%",
              height: "60%",
              borderRadius: "50%",
              backgroundColor: "white",
              position: "absolute",
            }}
          />
        )}
      </Box>
    </Box>
  );
  return (
    <Card
      sx={{
        maxWidth: 345,
        minHeight: "210px",
        width: "100%",
        maxHeight: "77vh",
        boxSizing: "content-box",
        border: "3px solid #D0DFFF",
        borderRadius: "16px",
        backgroundRepeat: "no-repeat",
        padding: "0px",
        margin: "0px",
        overflowY: "scroll",
        overflowX: "hidden",
        scrollbarWidth: "none",
      }}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          textAlign: "center",
          height: "60px",
          px: "20px",
          backgroundColor: "inherit",
          background: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "110%",
          border: "none",
        }}>
        <Box display='flex' alignItems='center'>
          <Typography variant='h5' fontWeight='500'>
            Notes
          </Typography>

          <Typography
            sx={{
              marginLeft: 1,
              fontWeight: "bold",
              backgroundColor: Colors.CARD_ICONCOLOR,
              borderRadius: "3.27px",
              height: "18px",
              width: "18px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "12px",
              color: "#353535",
            }}>
            {notes?.count}
          </Typography>
        </Box>

        <Box display='flex' alignItems='center'>
          <Typography
            sx={{
              // marginLeft: 1,
              fontWeight: "bold",
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              // border: "0.5px solid #C8D9FF",
              height: "20px",
              // width: "48px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "10px",
              fontWeight: 400,
              color: "#4680FF",
              cursor: "pointer",
            }}
            onClick={() => {
              setAll((prev) => ({ ...prev, notes: false }));
            }}>
            View Less
          </Typography>
          <Typography
            sx={{
              marginLeft: 1,
              fontWeight: "bold",
              backgroundColor: Colors.CARD_ICONCOLOR,
              borderRadius: "50%",
              height: "18px",
              width: "18px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setId(null);
              setData(null);
              setAddEditOpen(true);
            }}>
            +
          </Typography>
        </Box>
      </Box>
      {notes.data !== null && notes?.count !== 0 ? (
        <Box
          sx={{
            maxHeight: "68vh",
            overflowY: "scroll",
            overflowX: "hidden",
            scrollbarWidth: "none",
          }}>
          <CardContent
            sx={{
              p: 0,
              maxWidth: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              px: "20px",
              py: "7px",
              backgroundColor: "white",
              height: "auto",
            }}>
            {/* for public notes */}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: "30px",
                borderBottom: "1px solid rgb(173, 198, 252)",
              }}>
              <Typography
                variant='body2'
                sx={{
                  fontWeight: 400,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "250px",
                }}>
                Public Notes
              </Typography>
            </Box>
            <Timeline
              sx={{
                padding: 0,
                margin: 0,
                "& .MuiTimelineItem-root": {
                  paddingLeft: 0,
                },
                "& .MuiTimelineContent-root": {
                  padding: 0,
                  marginLeft: "8px",
                },
                "& .MuiTimelineItem-missingOppositeContent:before": {
                  display: "none",
                },
              }}>
              {notes?.data?.public?.length !== 0 ? (
                <>
                  {notes?.data?.public?.map((note, index) => (
                    <TimelineItem key={index} position='right'>
                      <TimelineSeparator>
                        <CustomTimelineDot index={index} />
                        {index !== notes?.data?.public?.length - 1 && (
                          <TimelineConnector
                            sx={{
                              backgroundColor: "primary.main",
                              width: 4,
                              boxShadow:
                                "0.5px 0px 2px 0px rgba(0, 0, 0, 0.25) inset",
                            }}
                          />
                        )}
                      </TimelineSeparator>
                      <TimelineContent
                        sx={{
                          mt: "0",
                          display: "flex",
                          alignSelf: "flex-start",
                          flexDirection: "column",
                        }}>
                        <Box
                          display='flex'
                          alignItems='center'
                          justifyContent='space-between'>
                          <Typography
                            color='primary'
                            display='flex'
                            flexDirection='row'>
                            <Typography
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "70px",
                              }}>
                              {note?.heading}
                            </Typography>
                            <Typography>
                              {helperFunction.dateFormat(note?.date)}
                            </Typography>
                          </Typography>

                          <IconButton
                            size='small'
                            variant='outlined'
                            color='primary'
                            sx={{
                              minWidth: "28px",
                              width: "30px",
                              height: "24px",
                              padding: "10px",
                              borderRadius: "4px",
                              border: "0.3px solid rgb(169, 195, 251)",
                            }}
                            onClick={() => {
                              setData(note);
                              setId(note?._id);
                              setAddEditOpen(true);
                            }}>
                            <Edit2 variant='Bulk' color='black' />
                          </IconButton>
                        </Box>
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          style={{
                            maxWidth: "100%",
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                          }}
                          dangerouslySetInnerHTML={helperFunction.createMarkup(
                            note?.text || "-"
                          )}></Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </>
              ) : (
                <>
                  <Typography
                    variant='body2'
                    sx={{
                      fontWeight: 300,
                      height: "20px",
                      fontSize: "9px",
                      color: "#1E1E1E",
                    }}>
                    Public Notes Not available
                  </Typography>
                </>
              )}
            </Timeline>

            {/* for private notes */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: "30px",
                borderBottom: "1px solid rgb(173, 198, 252)",
              }}>
              <Typography
                variant='body2'
                sx={{
                  fontWeight: 400,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "250px",
                }}>
                Private Notes
              </Typography>
            </Box>

            <Timeline
              sx={{
                padding: 0,
                margin: 0,
                "& .MuiTimelineItem-root": {
                  paddingLeft: 0,
                },
                "& .MuiTimelineContent-root": {
                  padding: 0,
                  marginLeft: "8px",
                },
                "& .MuiTimelineItem-missingOppositeContent:before": {
                  display: "none",
                },
              }}>
              {notes?.data?.private?.length !== 0 ? (
                <>
                  {notes?.data?.private?.map((note, index) => (
                    <TimelineItem key={index} position='right'>
                      <TimelineSeparator>
                        <CustomTimelineDot index={index} />
                        {index !== notes?.data?.private?.length - 1 && (
                          <TimelineConnector
                            sx={{
                              backgroundColor: "primary.main",
                              width: 4,
                              boxShadow:
                                "0.5px 0px 2px 0px rgba(0, 0, 0, 0.25) inset",
                            }}
                          />
                        )}
                      </TimelineSeparator>
                      <TimelineContent
                        sx={{
                          mt: "0",
                          display: "flex",
                          alignSelf: "flex-start",
                          flexDirection: "column",
                        }}>
                        <Box
                          display='flex'
                          alignItems='center'
                          justifyContent='space-between'>
                          <Typography
                            color='primary'
                            display='flex'
                            flexDirection='row'>
                            <Typography
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "70px",
                              }}>
                              {note?.heading}
                            </Typography>
                            <Typography>
                              {helperFunction.dateFormat(note?.date)}
                            </Typography>
                          </Typography>

                          <IconButton
                            size='small'
                            variant='outlined'
                            color='primary'
                            sx={{
                              minWidth: "28px",
                              width: "30px",
                              height: "24px",
                              padding: "10px",
                              borderRadius: "4px",
                              border: "0.3px solid rgb(169, 195, 251)",
                            }}
                            onClick={() => {
                              setData(note);
                              setId(note?._id);
                              setAddEditOpen(true);
                            }}>
                            <Edit2 variant='Bulk' color='black' />
                          </IconButton>
                        </Box>
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          style={{
                            maxWidth: "100%",
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                          }}
                          dangerouslySetInnerHTML={helperFunction.createMarkup(
                            note?.text || "-"
                          )}></Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </>
              ) : (
                <>
                  <Typography
                    variant='body2'
                    sx={{
                      fontWeight: 300,
                      height: "20px",
                      fontSize: "9px",
                      color: "#1E1E1E",
                    }}>
                    Private Notes Not available
                  </Typography>
                </>
              )}
            </Timeline>
          </CardContent>
          {addEditOpen && (
            <NotesPopup
              id={id}
              setId={setId}
              data={data}
              setData={setData}
              addEditOpen={addEditOpen}
              setAddEditOpen={setAddEditOpen}
              setRecallState={setRecallState}
              parentData={parentData}
              parentModel={parentModel}
            />
          )}
        </Box>
      ) : (
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            px: "20px",
            py: "10px",
            backgroundColor: "white",
            height: "auto",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height: "30px",
              borderBottom: "1px solid rgb(173, 198, 252)",
            }}>
            <Typography
              variant='body2'
              sx={{
                fontWeight: 400,
                height: "20px",
                fontSize: "12px",
                color: "#1E1E1E",
              }}>
              No data available
            </Typography>
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

export default NoteCard;

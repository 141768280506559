import React, { useState } from "react";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { parseISO, format, addDays } from "date-fns";

const CustomDatePicker = ({
  value,
  onChange,
  validate = false,
  name,
  skipDay,
  error,
  helperText,
}) => {
  const [open, setOpen] = useState(false);

  const handleDateChange = (newValue) => {
    const date = newValue instanceof Date && !isNaN(newValue) ? newValue : null;
    onChange(date);
  };

  // const parsedValue =
  //   name === "startname" && !value
  //     ? new Date()
  //     : name === "endname" && !value
  //     ? addDays(new Date(), skipDay)
  //     : typeof value === "string"
  //     ? parseISO(value)
  //     : value;
  const parsedValue =
    skipDay !== undefined && skipDay !== null && !value
      ? addDays(new Date(), skipDay)
      : typeof value === "string"
      ? parseISO(value)
      : value;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        value={parsedValue || null}
        onChange={handleDateChange}
        open={open}
        onClose={() => setOpen(false)}
        format="dd-MM-yyyy"
        slotProps={{
          textField: {
            placeholder: "DD-MM-YYYY",
            onClick: () => setOpen(true),
            size: "small",
            error: error,
            helperText: helperText,
          },
          openPickerIcon: {
            onClick: () => setOpen(true),
          },
        }}
        sx={{
          width: name === "paymentTerm" ? "150px" : "100%",
          marginBottom: name === "paymentTerm" ? "-15px" : 1,
          "& .MuiInputBase-root": {
            fontSize: "0.875rem",
            height: "41px",
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;

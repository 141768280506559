import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Box,
  IconButton,
  Stack,
} from "@mui/material";
import { Toastr } from "components/Toastr";
import { useFormik } from "formik";
import { ApiServices } from "services/apiServices";
import { Constants } from "services/keyConstants";
import * as url from "services/url_helper";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import { ReactComponent as CrossIcon } from "../../../assets/button_svg/Vector.svg";
import { BookmarkBorderOutlined } from "@mui/icons-material";
import InformationCard from "components/InformationCard";
import { titleStyle } from "services/commonStyles";
import DeleteModal from "components/DeleteModal";

const AddEditCurrency = ({
  id = null,
  data,
  setData,
  setAddEditOpen,
  setRecallState,
}) => {
  const [deleteData, setDeleteData] = useState(null);
  const formik = useFormik({
    initialValues: {
      ...data,
    },
    onSubmit: (values) => {
      if (id) {
        update(values);
      } else {
        create(values);
      }
    },
  });

  const currencyData = () => {
    try {
      let uri = url.CURRENCY + id;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.data);
            setData(response?.result?.data);
          } else {
            Toastr(response?.result?.message || "Error Occurred");
          }
        })
        .catch((error) => {
          console.warn("API error", error);
        });
    } catch (error) {
      console.warn("Function error", error);
    }
  };

  const update = (values) => {
    try {
      let uri = url.CURRENCY + id;
      let body = { ...values };

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);
            Toastr("Currency Updated Successfully", Constants.SUCCESS);
            setAddEditOpen(false);
            if (setRecallState) {
              setRecallState(true);
            }
          } else {
            Toastr(response?.result?.message || "Error Occurred");
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn("Call Error", error);
        });
    } catch (error) {
      console.warn("API error", error);
    }
  };

  const create = (values) => {
    try {
      let uri = url.CURRENCY;
      let body = { ...values };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);
            Toastr("Currency Added Successfully", Constants.SUCCESS);
            setAddEditOpen(false);
            if (setRecallState) {
              setRecallState(true);
            }
          } else {
            Toastr(response?.result?.message || "Error Occurred");
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn("Call Error", error);
        });
    } catch (error) {
      console.warn("API error", error);
    }
  };

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: data?.isoCode });
  };

  useEffect(() => {
    if (id && !data) {
      currencyData();
    }
  }, []);

  return (
    <>
      <DeleteModal
        dataUrl={url.CURRENCY}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        //    callFunc={addFilter}
        navLink={"/currency"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          py: 2,
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
          Currency Information
        </Typography>
        <Typography
          component="span"
          sx={{ display: "flex", flexDirection: "row", gap: "5px" }}
        >
          <IconButton
            size="small"
            onClick={() => setAddEditOpen((prev) => !prev)}
            sx={{ p: 0.95, borderRadius: "5px", border: "1px solid red" }}
          >
            <CrossIcon width="2" height="2" />
          </IconButton>
          <IconButton
            size="small"
            color="green"
            onClick={() => formik.handleSubmit()}
            sx={{ p: 0, borderRadius: "5px", border: "1px solid green" }}
          >
            <BookmarkBorderOutlined sx={{ color: "green", height: "16px" }} />
          </IconButton>
          {id && (
            <IconButton
              size="small"
              onClick={handleOpenDelete}
              sx={{ p: 0, borderRadius: "5px", border: "1px solid #4680FF " }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          m: 0,
          p: 0,
          maxHeight: "65vh",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <InformationCard title="Currency Details" color="#0000FF">
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Currency Name</Typography>
              <TextField
                name="isoCode"
                value={formik?.values?.isoCode}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter Currency Name"
                InputProps={{ sx: { height: "41px" } }}
              />
              <Typography {...titleStyle}>Decimal Places</Typography>
              <TextField
                name="decimalPlaces"
                value={formik.values?.decimalPlaces}
                type="number"
                onChange={formik.handleChange}
                onWheel={(e) => e.target.blur()}
                fullWidth
                variant="outlined"
                placeholder="Enter Decimal Places"
                InputProps={{ sx: { height: "41px" } }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Conversion Rate</Typography>
              <TextField
                name="conversionRate"
                value={formik?.values?.conversionRate}
                onChange={formik.handleChange}
                type="number"
                onWheel={(e) => e.target.blur()}
                fullWidth
                variant="outlined"
                placeholder="Enter Conversion Rate"
                InputProps={{ sx: { height: "41px" } }}
              />
            </Grid>
          </InformationCard>
        </form>
      </Box>
    </>
  );
};

export default AddEditCurrency;

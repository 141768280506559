import { BookmarkBorderOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Constants } from "services/keyConstants";
import { Box } from "@mui/system";
import CustomDatePicker from "components/CustomDatePicker";
import InformationCard from "components/InformationCard";
import { Toastr } from "components/Toastr";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { ApiServices } from "services/apiServices";
import * as url from "services/url_helper";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/delete.svg";
import { ReactComponent as CrossIcon } from "../../../assets/button_svg/Vector.svg";
import { helperFunction } from "services/helperFunction";
import { dataStyle, titleStyle } from "services/commonStyles";
import DeleteModal from "components/DeleteModal";
import { getObjectPermissions } from "services/utils";
import * as Yup from "yup";

const AddEditPaymentDetail = ({
  id = null,
  data,
  setData,
  setAddEditOpen,
  setId,
  parentData,
  parentModel,
  setRecallState,
}) => {
  const [deleteData, setDeleteData] = useState(null);
  const [options, setOptions] = useState({
    account: [],
    currency: [],
  });
  const permission = getObjectPermissions()?.["payment-detail"];

  const validationSchema = Yup.object({
    accountId: Yup.mixed().test(
      "accountId-validation",
      "Account Name is required",
      (value) => {
        if (!value) return false;
        if (typeof value === "string" && value.trim() !== "") return true;
        if (typeof value === "object" && value._id) return true;
        return false;
      }
    ),
    currencyId: Yup.mixed().test(
      "currencyId-validation",
      "Currency is required",
      (value) => {
        if (!value) return false;
        if (typeof value === "string" && value.trim() !== "") return true;
        if (typeof value === "object" && value._id) return true;
        return false;
      }
    ),

    amount: Yup.number()
      .typeError("Amount must be a number")
      .positive("Amount must be greater than zero")
      .required("Amount is required"),

    paymentDate: Yup.string().trim().required("Payment Date is required"),
  });

  const formik = useFormik({
    initialValues: {
      ...data,
      paymentDate: data?.paymentDate ? data?.paymentDate : new Date() || "",
      accountId: data?.accountId || "",
      amount: data?.amount || "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (id) {
        const updatedValues = helperFunction.getUpdatedValues(values, data);
        update(updatedValues);
      } else {
        create(values);
      }
    },
  });

  const handleOpenDelete = () => {
    setDeleteData({ open: true, id: id, title: data?.name });
  };

  const getPaymentDetail = () => {
    try {
      let uri = url.PAYMENT_DETAILS + id;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.data);
            setData(response?.result?.data);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const currencyData = () => {
    try {
      let uri = url.CURRENCY;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              currency: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };
  const getAccount = (query) => {
    try {
      let uri = url.ACCOUNT + `/name/${query}`;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              account: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };
  const handleInputChange = (event, newInputValue, reason) => {
    if (reason === "input") {
      if (newInputValue?.length >= 3) {
        getAccount(newInputValue);
      } else {
        setOptions((prev) => ({ ...prev, account: [] }));
      }
    }
  };

  const update = (values) => {
    try {
      let uri = url.PAYMENT_DETAILS + id;
      let body = {
        ...values,
      };

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);
            setAddEditOpen(false);
            if (setRecallState) {
              setRecallState(true);
            }
            Toastr("Payment Details Updated Successfully", Constants.SUCCESS);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };
  const create = (values) => {
    try {
      let uri = url.PAYMENT_DETAILS;
      let body = {
        ...values,
      };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            setData(response?.result?.data);

            Toastr("Payment Details Added Successfully", Constants.SUCCESS);
            if (setRecallState) {
              setRecallState(true);
            }
            setAddEditOpen(false);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
            setAddEditOpen(false);
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };
  useEffect(() => {
    if (id && !data) {
      getPaymentDetail();
    }
    currencyData();
    if (parentModel) {
      if (parentModel == "account") {
        formik.setFieldValue("accountId", parentData);
      } else if (parentData?.accountId) {
        formik.setFieldValue("accountId", parentData?.accountId);
      } else {
        getAccount(`getallvalues?${parentModel}Id=${parentData?._id}`);
      }
    }
  }, []);
  return (
    <>
      <DeleteModal
        dataUrl={url.PAYMENT_DETAILS}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        //    callFunc={addFilter}
        navLink={"/paymentDetail-list"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          py: 2,
        }}>
        <Typography
          component='div'
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}>
          Payment Detail
        </Typography>
        <>
          <Typography
            component='span'
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
            }}>
            <IconButton
              size='small'
              onClick={() => {
                setAddEditOpen((prev) => !prev);
                if (location?.pathname === "/paymentDetail-list") {
                  setData(null);
                  setId(null);
                }
              }}
              sx={{
                p: 0.95,
                borderRadius: "5px",
                border: "1px solid red",
              }}>
              <CrossIcon width='2' height='2' />
            </IconButton>

            <IconButton
              size='small'
              color='green'
              onClick={() => {
                formik.handleSubmit();
                // if (location?.pathname === "/paymentDetail-list") {
                //   setData(null);
                //   // setId(null);
                // }
              }}
              sx={{
                p: 0,
                borderRadius: "5px",
                border: "1px solid green",
              }}>
              <BookmarkBorderOutlined sx={{ color: "green", height: "16px" }} />
            </IconButton>

            {id && permission !== undefined && permission[0] >= 3 && (
              <IconButton
                size='small'
                onClick={handleOpenDelete}
                sx={{
                  p: 0,
                  borderRadius: "5px",
                  border: "1px solid #4680FF ",
                }}>
                <DeleteIcon />
              </IconButton>
            )}
          </Typography>
        </>
      </Box>
      <Box
        sx={{
          m: 0,
          p: 0,
          maxHeight: "65vh",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}>
        <form onSubmit={formik.handleSubmit}>
          <InformationCard title='Core Details' color='red'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle} variant='h6'>
                Payment Detail Number
              </Typography>
              <Typography {...dataStyle}>
                {formik?.values?.name || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Amount
                <Typography variant='span' color='red'>
                  *
                </Typography>
              </Typography>
              <TextField
                name='amount'
                value={formik?.values?.amount || ""}
                onChange={formik.handleChange}
                onWheel={(e) => e.target.blur()}
                fullWidth
                type='number'
                variant='outlined'
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.paymentDate && formik.errors.amount}
                placeholder='Enter Amount'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Account Name
                <Typography variant='span' color='red'>
                  *
                </Typography>
              </Typography>
              <Autocomplete
                id='accountId'
                name='accountId'
                options={options?.account}
                getOptionLabel={(option) => option?.name}
                value={
                  typeof formik.values.accountId === "object"
                    ? formik?.values?.accountId
                    : options?.account?.find(
                        (option) => option._id === formik.values?.accountId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "accountId",
                    newValue ? newValue?._id : ""
                  );
                }}
                onInputChange={handleInputChange}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select Account Name'
                    error={
                      formik.touched?.accountId &&
                      Boolean(formik.errors?.accountId)
                    }
                    helperText={
                      formik.touched?.accountId && formik.errors?.accountId
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Currency
                <Typography variant='span' color='red'>
                  *
                </Typography>
              </Typography>
              <Autocomplete
                id='currencyId'
                name='currencyId'
                options={options?.currency}
                getOptionLabel={(option) => option?.isoCode}
                value={
                  typeof formik.values.currencyId === "object"
                    ? {
                        isoCode: formik.values.currencyId?.isoCode || "",
                        _id: formik.values.currencyId?._id || "",
                      }
                    : options.currency.find(
                        (option) => option._id === formik.values?.currencyId
                      ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "currencyId",
                    newValue ? newValue?._id : ""
                  );
                }}
                onBlur={formik.handleBlur}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select Currency'
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        opacity: 1,
                        color: "#4c4d4f !important",
                        WebkitTextFillColor: "#4c4d4f !important",
                      },
                    }}
                    error={
                      formik.touched?.accountId &&
                      Boolean(formik.errors?.currencyId)
                    }
                    helperText={
                      formik.touched?.accountId && formik.errors?.currencyId
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>
                Payment Date
                <Typography variant='span' color='red'>
                  *
                </Typography>
              </Typography>

              <CustomDatePicker
                value={formik?.values?.paymentDate}
                onChange={(date) => formik.setFieldValue("paymentDate", date)}
                error={
                  formik.touched.paymentDate &&
                  Boolean(formik.errors.paymentDate)
                }
                helperText={
                  formik.touched.paymentDate && formik.errors.paymentDate
                }
              />
            </Grid>
          </InformationCard>
          <InformationCard title='General Detail' color='blue'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Amount Distributed</Typography>

              <Typography {...dataStyle}>
                {formik?.values?.amountDistributed || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Advanced Recieved Amount</Typography>
              <Typography {...dataStyle}>
                {!formik?.values?.amountDistributed
                  ? formik?.values?.amount
                  : formik?.values?.amount - formik?.values?.amountDistributed}
              </Typography>
            </Grid>
          </InformationCard>
          <InformationCard title='Other Details' color='green'>
            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Payment Detail</Typography>
              <TextField
                name='paymentDetail'
                value={formik?.values?.paymentDetail || ""}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Payment Detail'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
              <Typography {...titleStyle}>Payment Note</Typography>
              <TextField
                name='paymentNote'
                value={formik?.values?.paymentNote || ""}
                onChange={formik.handleChange}
                fullWidth
                variant='outlined'
                placeholder='Enter Payment Note'
                InputProps={{
                  sx: {
                    height: "41px",
                  },
                }}
              />
            </Grid>
          </InformationCard>
        </form>
      </Box>
    </>
  );
};

export default AddEditPaymentDetail;

import React from "react";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";

const CustomLink = ({ newTab = false, to, children, ...rest }) => {
  // const history = useHistory();
  const navigate = useNavigate();

  const handleClick = (e) => {
    if (newTab) {
      window.open(to, "_blank");
      e.preventDefault();
    } else if (!e.ctrlKey && !e.metaKey && !e.shiftKey && e.button === 0) {
      e.preventDefault();
      if (to && !to.includes("undefined")) {
        navigate(to);
      }
    }
  };

  return (
    <MuiLink
      component={RouterLink}
      to={to}
      onClick={handleClick}
      underline="none"
      sx={{
        color: "primary.main",
        fontWeight: "bold",
      }}
      {...rest}
    >
      {children}
    </MuiLink>
  );
};

export default CustomLink;

import React, { useEffect, useMemo, useState } from "react";
import * as url from "../../../services/url_helper";
import BreadCrumb from "components/BreadCrumb";
import TablePagination from "components/TablePagination/TablePagination";
import { Box, Stack } from "@mui/system";
import { useLocation } from "react-router";
import CustomLink from "components/CustomLink";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { ReactComponent as DeleteIcon } from "../../../assets/button_svg/deleteRed.svg";
import SimpleTable from "components/SimpleTable";
import { getObjectPermissions } from "services/utils";

const ServiceBrief = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [deleteData, setDeleteData] = useState(null);
  const [addEditOpen, setAddEditOpen] = useState(false);
  const [id, setId] = useState(null);
  const location = useLocation();
  const permission = getObjectPermissions()?.["service-brief"];

  const handleOpenDelete = (id, name) => {
    setDeleteData({ open: true, id: id, title: name });
  };

  const column = useMemo(
    () =>
      [
        // {
        //   Header: "",
        //   accessor: "srno",
        //   Cell: (cellProps) => (
        //     <>
        //       <div className="d-flex align-items-center">
        //         <div className="flex-grow-1 ms-2 name">
        //           {cellProps.row.index + 1}
        //         </div>
        //       </div>
        //     </>
        //   ),
        // },
        {
          Header: "Service Brief Name",
          accessor: "name",
          type: "string",
          Cell: (cellProps) => (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-2 name ">
                  <CustomLink
                    to={
                      cellProps.row.original?._id
                        ? `/serviceBrief/view/${cellProps.row.original?._id}`
                        : "#"
                    }
                  >
                    {cellProps.row.original?.name}
                  </CustomLink>
                </div>
              </div>
            </>
          ),
        },
        {
          Header: "Owner Name",
          accessor: "ownerId?.fullName",
          type: "string",
          Cell: (cellProps) => (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-2 name ">
                  {cellProps.row.original.ownerId?.fullName}
                </div>
              </div>
            </>
          ),
        },

        permission !== undefined &&
          permission[0] >= 1 && {
            Header: "Action",
            accessor: "Action",
            Cell: (cellProps) => {
              return (
                <Stack direction="row" spacing={1}>
                  <CustomLink
                    to={
                      cellProps.row.original?._id
                        ? `/serviceBrief/view/${cellProps.row.original?._id}`
                        : "#"
                    }
                    newTab={true}
                  >
                    <Tooltip title="new tab">
                      <IconButton color="primary">
                        <OpenInNewIcon />
                      </IconButton>
                    </Tooltip>
                  </CustomLink>
                  <Tooltip title="Edit">
                    <IconButton
                      color="success"
                      onClick={(e) => {
                        setAddEditOpen(true);
                        setId(cellProps.row.original?._id);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    {/* </Link> */}
                  </Tooltip>

                  {permission !== undefined && permission[0] >= 3 && (
                    <Tooltip title="Delete">
                      <IconButton
                        color="error"
                        onClick={(e) => {
                          e.preventDefault();
                          handleOpenDelete(
                            cellProps.row.original?._id,
                            cellProps.row.original?.name
                          );
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      {/* </Link> */}
                    </Tooltip>
                  )}
                </Stack>
              );
            },
          },
      ].filter(Boolean),
    [permission]
  );
  document.title = "Service Brief List";

  return (
    <React.Fragment>
      <div className="page-content" style={{ height: "100%" }}>
        {/* <Container fluid> */}
        <BreadCrumb title="Service Brief" currentPage="RegTech" />

        {isLoading ? (
          // <Loader />
          "Loader"
        ) : (
          <Box id="accountList">
            {permission !== undefined ? (
              location.pathname === "/serviceBrief-list" ? (
                <SimpleTable
                  column={column}
                  addEditOpen={addEditOpen}
                  setAddEditOpen={setAddEditOpen}
                  dataUrl={url.SERVICE_BRIEF}
                  setDeleteData={setDeleteData}
                  deleteData={deleteData}
                  id={id}
                  setId={setId}
                  modal={"serviceBrief"}
                />
              ) : (
                <tr>
                  <td colSpan="3">
                    <h5
                      className="text-primary text-center"
                      style={{ fontWeight: "bold" }}
                    >
                      No Data Available
                    </h5>
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="3">
                  <h5
                    className="text-primary text-center"
                    style={{ fontWeight: "bold" }}
                  >
                    No Access
                  </h5>
                </td>
              </tr>
            )}
          </Box>
        )}
      </div>
    </React.Fragment>
  );
};

export default ServiceBrief;

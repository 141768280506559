import React, { useEffect, useState, useMemo } from "react";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  Checkbox,
  Button,
} from "@mui/material";

//import COmponents
import { useLocation, useNavigate, useParams } from "react-router";

import { PopupTransition } from "components/@extended/Transitions";
import * as url from "services/url_helper";
import { ApiServices } from "services/apiServices";
import { Constants } from "services/keyConstants";
import { Toastr } from "components/Toastr";
import InformationCard from "components/InformationCard";
import { useFormik } from "formik";
import CustomDatePicker from "components/CustomDatePicker";
import { getSecureToken } from "services/utils";
import {
  BookmarkBorderOutlined,
  ColorLens,
  Close as CrossIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Colors } from "services/colorConstant";
import { dataStyle, titleStyle } from "services/commonStyles";
import * as Yup from "yup";
import AddEditContact from "../Contact/AddEditContact";
import RelatedAddEdit from "components/RelatedList/RelatedAddEdit";

const schemapathOptions = [
  { value: "account", label: "Account" },
  { value: "opportunity", label: "Opportunity" },
  { value: "bank", label: "Bank" },
  { value: "certification", label: "Certification" },
  { value: "estimate", label: "Estimate" },
  { value: "quotation", label: "Quotation" },
  { value: "fInvoice", label: "F Invoice" },
  { value: "fInvoiceline", label: "F Invoice Line" },
  { value: "internalExpense", label: "Internal Expense" },
  { value: "mavenExpense", label: "Maven Expense" },
  { value: "pInvoice", label: "P Invoice" },
  { value: "pdRequirement", label: "PD Requirement" },
  { value: "scopeofwork", label: "Scope of Work" },
];
const statusOptions = [
  "Not Started",
  "In Progress",
  "Completed",
  "Waiting on someone else",
  "Deferred",
];
const priorityOptions = ["High", "Low", "Normal"];
const recurrenceTypeOptions = ["Daily", "Weekly", "Monthly", "Annually"];
const weekOptions = [
  { value: "1", label: "Monday" },
  { value: "2", label: "Tuesday" },
  { value: "3", label: "Wednesday" },
  { value: "4", label: "Thursday" },
  { value: "5", label: "Friday" },
  { value: "6", label: "Saturday" },
  { value: "0", label: "Sunday" },
];
const monthDayOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "secondlast", label: "Second Last Day of Month" },
  { value: "last", label: "Last Day of Month" },
];

const AddEditFollowUp = ({
  id = null,
  setId,
  data,
  setData,
  addEditOpen,
  setAddEditOpen,
  setRecallState,
  parentData,
  parentModel,
}) => {
  const [selectRecordId, setSelectRecordId] = useState(null);
  const [options, setOptions] = useState({
    relatedTo: [],
    contact: [],
    users: [],
    relatedData: [],
  });
  const [openAddContact, setOpenAddContact] = useState(false);
  const permission = [3, 1];

  // schemapathOptions.find((option) => option.value === "opportunity");
  const validationSchema = Yup.object({
    subject: Yup.string().trim().required("Subject is required"),
    relatedTo: Yup.mixed().test(
      "RelatedTo-validation",
      "Related To is required",
      (value) => {
        if (!value) return false;
        if (typeof value === "string" && value.trim() !== "") return true;
        if (typeof value === "object" && value._id) return true;
        return false;
      }
    ),
    activityDate: Yup.string().trim().required("Due Date is required"),
  });
  const formik = useFormik({
    initialValues: {
      ...data,
      subject: data?.subject || "",
      relatedTo: data?.relatedTo || "",
      schemapath: data?.schemapath ? data?.schemapath : "opportunity",
      status: data?.status ? data?.status : "Not Started",
      priority: data?.priority ? data?.priority : "Normal",
      recurring: data?.recurring ? data?.recurring : false,
      activityDate: data?.activityDate ? data?.activityDate : new Date(),

      ownerId: data?.ownerId
        ? data?.ownerId
        : getSecureToken(Constants.KEY_USER_DATA),
    },
    validationSchema,
    onSubmit: (values) => {
      if (id) {
        update(values);
      } else {
        create(values);
      }
    },
  });

  const handleAccountInputChange = (event, newInputValue, reason) => {
    if (reason === "input") {
      if (newInputValue?.length >= 3) {
        relatedToData(newInputValue);
      } else {
        setOptions((prev) => ({ ...prev, relatedData: [] }));
        // setSelectRelatedName([]);
      }
    }
  };
  // const followUpDataById = () => {
  //   try {
  //     let uri = url.FOLLOW_UP_TASK + selectRecordId;
  //     ApiServices.callServiceGet(uri)
  //       .then((response) => {
  //         if (response?.result?.type === "success") {
  //           formik.setValues(response?.result?.data);
  //         } else {
  //           Toastr(
  //             response?.result?.message
  //               ? response?.result?.message
  //               : "Error Occurred"
  //           );
  //         }
  //       })

  //       .catch((error) => {
  //         console.warn(" API error", error);
  //       });
  //   } catch (error) {
  //     console.warn(" function error", error);
  //   }
  // };
  const followUpDataCC = () => {
    try {
      let uri = url.FOLLOW_UP_TASK + selectRecordId;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            response.result.data.comment = "";
            response.result.data.activityDate = "";

            setSelectRecordId(null);
            formik.setValues(response?.result?.data);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })

        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };
  const getFollowUpDetail = () => {
    try {
      let uri = url.FOLLOW_UP_TASK + id;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            formik.setValues(response?.result?.data);
            setData(response?.result?.data);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred",
              "Failed"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const relatedToData = (query) => {
    try {
      let option =
        formik?.values?.schemapath ||
        formik.values?.schemapath?.value ||
        "opportunity";
      // let uri = url.GET_RELATED_LIST_DATA + option;

      let uri = url.CRM + option + `/name/${query}`;

      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              relatedData: response?.result?.data,
            }));

            // setSelectRelatedName(response?.result?.data);
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })

        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const getContact = () => {
    try {
      const variable =
        formik?.values?.schemapath === "account"
          ? `?accountId=${
              formik?.values?.relatedTo?._id || formik?.values?.relatedTo
            }`
          : formik?.values?.schemapath === "opportunity"
          ? `?opportunityId=${
              formik?.values?.relatedTo?._id || formik?.values?.relatedTo
            }`
          : "/";
      let uri = url.CONTACT + variable;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({
              ...prev,
              contact: response?.result?.data,
            }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  const create = (values) => {
    try {
      let uri = url.FOLLOW_UP_TASK;

      let body = {
        ...values,
      };

      ApiServices.callServicePostWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            Toastr("Follow Up Task Added Successfully", Constants.SUCCESS);

            // recall(true)
            // getFollowUpSingle(response?.result?.data?.[0]._id);
            setData(response?.result?.data);
            setId(response?.result?.data?._id);
            setAddEditOpen(false);
            if (setRecallState) {
              setRecallState(true);
            }

            // props.history.push("/followUp");
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn("Error in callServicePostWithBodyData:", error);
        });
    } catch (error) {
      console.warn("Error in onAddContact:", error);
    }
  };

  const update = (values) => {
    try {
      let uri = url.FOLLOW_UP_TASK + id;
      let body = {
        ...values,
      };

      // if (values === "complete" || values === "completed") {
      //   body.status = "Completed";
      // }

      ApiServices.callServicePutWithBodyData(uri, body)
        .then((response) => {
          if (response?.result?.type === "success") {
            Toastr("Follow Up Task Updated Successfully", Constants.SUCCESS);
            setId(response?.result?.data?._id);
            setData(response?.result?.data);
            setAddEditOpen(false);
            if (setRecallState) {
              setRecallState(true);
            }

            // if (values === "complete") {
            //   // setOneFollowUp((prevData) => ({
            //   //   ...prevData,
            //   //   status: { value: "Not Started", label: "Not Started" },
            //   //   priority: {
            //   //     value: "Normal",
            //   //     label: "Normal",
            //   //   },
            //   // }));
            //   followUpDataCC(selectRecordId);
            //   // setAddEditOpen(false);
            // } else {
            //   // recall();
            // }
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" call Error", error);
        });
    } catch (error) {
      console.warn(" API error", error);
    }
  };
  // const handleRedirect = () => {
  //   const { history } = props;
  //   if (history.length > 1) {
  //     history.goBack();
  //   } else {
  //     props.history.push("/opportunity-list");
  //   }
  // };

  const userData = () => {
    try {
      let uri = url.USERS;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOptions((prev) => ({ ...prev, users: response?.result?.users }));
          } else {
            Toastr(
              response?.result?.message
                ? response?.result?.message
                : "Error Occurred"
            );
          }
        })
        .catch((error) => {
          console.warn(" API error", error);
        });
    } catch (error) {
      console.warn(" function error", error);
    }
  };

  console.log("openAddContact", openAddContact);

  useEffect(() => {
    userData();
    // relatedToData();
    // setSelectRecordId(recordId);
    if (parentModel) {
      formik.setFieldValue("schemapath", parentModel);
      formik.setFieldValue("relatedTo", parentData);
    }
    if (id && !data) {
      getFollowUpDetail();
    }
  }, []);
  useEffect(() => {
    if (
      formik?.values?.schemapath === "account" ||
      formik?.values?.schemapath === "opportunity"
    )
      getContact();
  }, [formik?.values?.relatedTo]);
  // useEffect(() => {
  //   if (selectRecordId) {
  //     setButton("Update Follow Up Task");
  //     setTitle("Update Follow Up Task");
  //     followUpDataById();
  //   }
  // }, [selectRecordId]);

  // useEffect(() => {
  //   if (relatedTo && relatedTo != "opportunity") {
  //     setSelectSchemapath(
  //       schemapathOptions.find((option) => option.value === relatedTo)
  //     );
  //   }

  //   if (parentId) {
  //     setSelectRelatedId(
  //       selectRelatedName?.find((data) => data._id == parentId)
  //     );
  //   }
  // }, [selectRelatedName, parentId]);

  // useEffect(() => {
  //   if (!id) {
  //     if (selectSchemapath.value == relatedTo) {
  //       relatedToData();
  //     } else if (location.pathname == "/followUp") {
  //       relatedToData();
  //     }
  //   }
  // }, [selectSchemapath]);
  // useEffect(() => {
  //   if (selectRelatedId) getContact();
  // }, [selectRelatedId]);

  //   document.title = "Contact | MAVEN";
  return (
    <React.Fragment>
      <Dialog
        open={addEditOpen}
        onClose={() => {
          setAddEditOpen(false);
        }}
        keepMounted
        TransitionComponent={PopupTransition}
        maxWidth="md"
        sx={{
          "& .MuiDialog-paper": {
            width: { xs: "90%", sm: "800px" },
            minHeight: "50%",
            borderRadius: "20px",
            overflow: "auto",
          },
        }}
        style={{ zIndex: 4 }}
        aria-labelledby="column-delete-title"
        aria-describedby="column-delete-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              py: 2,
            }}
          >
            <Typography
              component="div"
              sx={{
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              FollowUp Information
            </Typography>
            {/* <Typography variant="h6">Account Information</Typography> */}
            {permission !== undefined && permission[0] >= 3 && (
              <>
                <Typography
                  component="span"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (location?.pathname !== "/followUpTask") {
                        setData(null);
                        setId(null);
                      }
                      // setAddEditOpen((prev) => !prev);
                      setAddEditOpen(false);
                      // setOneFollowUp(null);
                      setSelectRecordId(null);
                    }}
                    sx={{
                      p: 0.95,
                      borderRadius: "5px",
                      border: `1px solid ${Colors.DANGER_COLOR}`,
                    }}
                  >
                    <CrossIcon
                      width="2"
                      height="2"
                      sx={{ color: Colors.DANGER_COLOR }}
                    />
                  </IconButton>

                  <IconButton
                    size="small"
                    color="green"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    sx={{
                      p: 0,
                      borderRadius: "5px",
                      border: `1px solid ${Colors.SUCCESS_COLOR}`,
                    }}
                  >
                    <BookmarkBorderOutlined
                      sx={{ color: Colors.SUCCESS_COLOR, height: "16px" }}
                    />{" "}
                  </IconButton>
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              m: 0,
              p: 0,
              maxHeight: "65vh",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <InformationCard title="Core Details" color="red">
                {/* <Stack direction={'row'} spacing={1}> */}

                <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                  <Typography {...titleStyle}>
                    Subject{" "}
                    <Typography variant="span" color="red">
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    name="subject"
                    value={formik?.values?.subject}
                    onChange={formik.handleChange}
                    fullWidth
                    variant="outlined"
                    placeholder="Subject"
                    error={
                      formik.touched.subject && Boolean(formik.errors.subject)
                    }
                    helperText={formik.touched.subject && formik.errors.subject}
                    InputProps={{
                      sx: {
                        height: "41px",
                        mb: 1,
                      },
                    }}
                  />

                  <Typography {...titleStyle}>
                    Related To{" "}
                    <Typography variant="span" color="red">
                      *
                    </Typography>
                  </Typography>
                  <Grid container spacing={2} direction="row">
                    <Grid item xs={4}>
                      {!id ? (
                        <Autocomplete
                          id="schemapath"
                          name="schemapath"
                          options={schemapathOptions || []}
                          getOptionLabel={(option) => option?.label || ""}
                          value={
                            typeof formik.values.schemapath === "object"
                              ? {
                                  label: formik?.values?.label || "",
                                  value: formik?.values?.value || "",
                                }
                              : schemapathOptions?.find(
                                  (option) =>
                                    option.value === formik.values?.schemapath
                                ) || {
                                  value: "opportunity",
                                  label: "Opportunity",
                                }
                          }
                          onChange={(event, newValue) => {
                            formik.setFieldValue(
                              "schemapath",
                              newValue ? newValue?.value : "opportunity"
                            );
                          }}
                          onBlur={formik.handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  opacity: 1,
                                  color: "#4c4d4f !important",
                                  WebkitTextFillColor: "#4c4d4f !important",
                                  mb: 1,
                                },
                              }}
                            />
                          )}
                        />
                      ) : (
                        <Typography {...dataStyle}>
                          {
                            schemapathOptions?.find(
                              (option) =>
                                option.value === formik?.values?.schemapath
                            )?.label
                          }
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={8}>
                      {!id ? (
                        <Autocomplete
                          id="relatedTo"
                          name="relatedTo"
                          options={options?.relatedData || []}
                          // getOptionLabel={(option) => option?.value || ""}
                          getOptionLabel={(option) =>
                            option && typeof option === "object"
                              ? option.name || null
                              : null
                          }
                          value={
                            typeof formik.values.relatedTo === "object"
                              ? formik.values.relatedTo
                              : options?.relatedData?.find(
                                  (option) =>
                                    option._id === formik.values?.relatedTo
                                ) || null
                          }
                          onChange={(event, newValue) => {
                            formik.setFieldValue(
                              "relatedTo",
                              newValue ? newValue?._id : null
                            );
                          }}
                          onInputChange={handleAccountInputChange}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          onBlur={formik.handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={
                                formik.touched?.relatedTo &&
                                Boolean(formik.errors?.relatedTo)
                              }
                              helperText={
                                formik.touched?.relatedTo &&
                                formik.errors?.relatedTo
                              }
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  opacity: 1,
                                  color: "#4c4d4f !important",
                                  WebkitTextFillColor: "#4c4d4f !important",
                                },
                              }}
                            />
                          )}
                        />
                      ) : (
                        <Typography {...dataStyle}>
                          {formik?.values?.relatedTo?.name}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Typography {...titleStyle}>Contact Name</Typography>
                  <Grid container spacing={2} direction="row">
                    <Grid item xs={7}>
                      <Autocomplete
                        fullWidth
                        id="contactName"
                        name="contactName"
                        getOptionLabel={(option) => option?.fullName}
                        options={options?.contact || []}
                        isOptionEqualToValue={(option, value) =>
                          option?._id === value?._id
                        }
                        value={
                          typeof formik.values?.contactId === "object"
                            ? formik.values?.contactId
                            : options?.contact?.find(
                                (option) =>
                                  option._id === formik.values?.contactId
                              ) || null
                        }
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            "contactId",
                            newValue ? newValue?._id : ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                opacity: 1,
                                color: "#4c4d4f !important",
                                WebkitTextFillColor: "#4c4d4f !important",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Button onClick={() => setOpenAddContact(true)}>
                        Add Contact
                      </Button>
                    </Grid>
                  </Grid>
                  <Typography {...titleStyle}>Comment</Typography>
                  <TextField
                    type="textarea"
                    name="comment"
                    value={formik?.values?.comment}
                    onChange={formik.handleChange}
                    fullWidth
                    variant="outlined"
                    placeholder="Subject"
                    multiline
                    rows={2}
                  />

                  <Typography {...titleStyle}>Status</Typography>
                  <Autocomplete
                    id="status"
                    name="status"
                    options={statusOptions}
                    value={formik.values?.status || "Not Started"}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("status", newValue || "Not Started");
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            opacity: 1,
                            color: "#4c4d4f !important",
                            WebkitTextFillColor: "#4c4d4f !important",
                            mb: 1,
                          },
                        }}
                      />
                    )}
                  />

                  <Typography {...titleStyle}>Priority</Typography>
                  <Autocomplete
                    id="priority"
                    name="priority"
                    options={priorityOptions}
                    value={formik.values?.priority || "Normal"}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "priority",
                        newValue ? newValue.value : "Normal"
                      );
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            opacity: 1,
                            color: "#4c4d4f !important",
                            WebkitTextFillColor: "#4c4d4f !important",
                            mb: 1,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                  <Typography {...titleStyle}>Owner Name</Typography>

                  <Autocomplete
                    id="ownerId"
                    name="ownerId"
                    getOptionLabel={(option) => option?.fullName}
                    options={options?.users || []}
                    value={
                      typeof formik.values?.ownerId === "object"
                        ? {
                            fullName: formik.values?.ownerId?.fullName || "",
                            _id: formik.values?.ownerId?._id || "",
                          }
                        : options?.users?.find(
                            (option) => option._id === formik.values?.ownerId
                          ) || null
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "ownerId",
                        newValue ? newValue?._id : ""
                      );
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            opacity: 1,
                            color: "#4c4d4f !important",
                            WebkitTextFillColor: "#4c4d4f !important",
                          },
                        }}
                      />
                    )}
                  />

                  {!id && (
                    <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                      <Typography {...titleStyle}>
                        Create Recurring Series of Tasks
                      </Typography>

                      <Checkbox
                        name="recurring"
                        recurring
                        id="recurring"
                        onChange={(event, newValue) => {
                          formik.setFieldValue("recurring", newValue);
                        }}
                        onBlur={formik.handleBlur}
                        checked={formik?.values?.recurring}
                      />
                    </Grid>
                  )}
                  {formik.values?.recurring && !selectRecordId ? (
                    <>
                      <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                        <Typography {...titleStyle}>Recurrence Type</Typography>
                        <Autocomplete
                          id="recurrenceType"
                          name="recurrenceType"
                          options={recurrenceTypeOptions}
                          value={formik.values?.recurrenceType || ""}
                          onChange={(event, newValue) => {
                            formik.setFieldValue(
                              "recurrenceType",
                              newValue || ""
                            );
                          }}
                          onBlur={formik.handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  opacity: 1,
                                  color: "#4c4d4f !important",
                                  WebkitTextFillColor: "#4c4d4f !important",
                                  mb: 1,
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>

                      {formik.values?.recurrenceType === "Weekly" ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ px: 2, pt: 1.5, m: 0 }}
                          >
                            <Typography {...titleStyle}>
                              Select Weekday
                            </Typography>
                            <Autocomplete
                              id="modifier"
                              name="modifier"
                              options={weekOptions}
                              getOptionLabel={(option) => option?.label || ""}
                              // isOptionEqualToValue={(option, value) =>
                              //   option._id === value._id
                              // }
                              // value={formik.values?.modifier || ""}
                              value={
                                typeof formik?.values?.modifier === "object"
                                  ? {
                                      label: formik?.values?.label || "",
                                      value: formik?.values?.value || "",
                                    }
                                  : weekOptions?.find(
                                      (option) =>
                                        option.value === formik.values?.modifier
                                    ) || null
                              }
                              onChange={(event, newValue) => {
                                formik.setFieldValue(
                                  "modifier",
                                  newValue ? newValue?.value : null
                                );
                              }}
                              onBlur={formik.handleBlur}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      opacity: 1,
                                      color: "#4c4d4f !important",
                                      WebkitTextFillColor: "#4c4d4f !important",
                                      mb: 1,
                                    },
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      ) : formik.values?.recurrenceType === "Monthly" ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ px: 2, pt: 1.5, m: 0 }}
                          >
                            <Typography {...titleStyle}>Select Date</Typography>
                            <Autocomplete
                              id="modifier"
                              name="modifier"
                              options={monthDayOptions}
                              getOptionLabel={(option) => option?.label || ""}
                              // isOptionEqualToValue={(option, value) =>
                              //   option._id === value._id
                              // }
                              // value={formik.values?.modifier || ""}
                              value={
                                typeof formik?.values?.modifier === "object"
                                  ? {
                                      label: formik?.values?.label || "",
                                      value: formik?.values?.value || "",
                                    }
                                  : monthDayOptions?.find(
                                      (option) =>
                                        option.value === formik.values?.modifier
                                    ) || null
                              }
                              onChange={(event, newValue) => {
                                formik.setFieldValue(
                                  "modifier",
                                  newValue ? newValue?.value : null
                                );
                              }}
                              onBlur={formik.handleBlur}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      opacity: 1,
                                      color: "#4c4d4f !important",
                                      WebkitTextFillColor: "#4c4d4f !important",
                                      mb: 1,
                                    },
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                      <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                        <Typography {...titleStyle}>Start Date</Typography>
                        <CustomDatePicker
                          value={formik?.values?.activityDate}
                          onChange={(date) =>
                            formik.setFieldValue("activityDate", date)
                          }
                          // skipDay={0}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} sx={{ px: 2, pt: 1.5, m: 0 }}>
                        <Typography {...titleStyle}>End Date</Typography>
                        <CustomDatePicker
                          value={formik?.values?.recurrenceTill}
                          onChange={(date) =>
                            formik.setFieldValue("recurrenceTill", date)
                          }
                          // skipDay={1}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Typography {...titleStyle}>
                        Due Date{" "}
                        <Typography variant="span" color="red">
                          *
                        </Typography>
                      </Typography>

                      <CustomDatePicker
                        value={formik?.values?.activityDate}
                        onChange={(date) =>
                          formik.setFieldValue("activityDate", date)
                        }
                        // skipDay={0}
                        error={
                          formik.touched?.activityDate &&
                          Boolean(formik.errors?.activityDate)
                        }
                        helperText={
                          formik.touched?.activityDate &&
                          formik.errors?.activityDate
                        }
                      />
                    </>
                  )}
                </Grid>
              </InformationCard>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
      {openAddContact && (
        <RelatedAddEdit
          model={"contact"}
          addEditModal={openAddContact}
          setAddEditModal={setOpenAddContact}
        />
      )}
    </React.Fragment>
  );
};

export default AddEditFollowUp;

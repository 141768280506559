import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Profile from "./Header/HeaderContent/Profile";
import Notification from "./Header/HeaderContent/Notification";
import { openDrawer } from "store/reducers/menu";
import { dispatch, useSelector } from "store";
import { Fragment, useEffect, useRef, useState } from "react";
import { Stack } from "@mui/system";
import {
  Divider,
  Grid,
  Icon,
  ListItemIcon,
  TextField,
  Drawer,
} from "@mui/material";
import {
  ArrowRight,
  Clock,
  HambergerMenu,
  LogoutCurve,
  MenuBoard,
  SearchNormal1,
  UserEdit,
} from "iconsax-react";
import { Search } from "@mui/icons-material";
import { ClockIcon } from "@mui/x-date-pickers";
import MenuData from "menu-items";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import useAuth from "hooks/useAuth";
import SimpleBar from "simplebar-react";
import avatar1 from "../../assets/images/users/avatar-6.png";
import Avatar from "components/@extended/Avatar";

const NavBar = (props) => {
  const theme = useTheme();
  const navbarRef = useRef(null);
  const buttonRef = useRef(null);
  const menuItems = MenuData();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [allFlag, setAllFlag] = useState(false);
  const [sorteditem, setSortedItem] = useState();
  // const [item, setItem] = useState(menuItems?.items);
  const [visibleItems, setVisibleItems] = useState([]);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [profileAvatar, setProfileAvatar] = useState(undefined);

  const { logout, user } = useAuth();
  const isMobile = useMediaQuery("(max-width: 426px)"); //mobile view
  const isSmallScreen = useMediaQuery("(max-width:500px)");
  const isMediumScreen = useMediaQuery("(max-width:600px)");
  const { drawerOpen } = useSelector((state) => state.menu);
  // const {
  //   // window,
  //   theme,
  // } = props;
  const location = useLocation();
  const gridItemSize = isSmallScreen ? 3 : isMediumScreen ? 2 : 1.714;
  const sliceCount = isSmallScreen ? 3 : isMediumScreen ? 5 : 6;
  const container =
    typeof window !== "undefined" ? () => window.document.body : undefined;
  const color = [
    "#E99C26",
    "#4680FF",
    "#353535",
    "#722ED1",
    "#A0D911",
    "#FF85C0",
    "#DC2626",
    "#13C2C2",
    "#2F54EB",
    "#FADB14",
  ];

  useEffect(() => {
    adjustNavItems();
    window.addEventListener("resize", adjustNavItems);
    return () => window.removeEventListener("resize", adjustNavItems);
  }, [menuItems]);

  const adjustNavItems = () => {
    if (!navbarRef.current) return;
    setAnchorEl(buttonRef.current);
    const navWidth = navbarRef.current.offsetWidth;
    const maxWidthPerItem = 125;
    const maxVisible = Math.floor(navWidth / maxWidthPerItem);

    setVisibleItems(menuItems?.slice(0, maxVisible - 1));
    setHiddenItems(menuItems?.slice(maxVisible - 1));
  };

  useEffect(() => { 
    search();
  }, [searchQuery]);
  useEffect(() => {
    hiddenItems?.forEach((i) => {
      if (location.pathname === i?.url) {
        lastNav(i);
      }
    });
  }, [hiddenItems, location]);

  const search = () => {
    const searchedItem = hiddenItems?.filter((itm) =>
      itm?.id?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );
    const remainingItem = hiddenItems?.filter(
      (itm) => !searchedItem?.includes(itm?.id)
    );

    setSortedItem([...searchedItem]);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(`/login`, {
        state: {
          from: "",
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const lastNav = (value) => {
    let temp = visibleItems[visibleItems?.length - 1];
    // setVisibleItems((prev)=>{
    //   return {...prev,value}
    // })

    setVisibleItems((prev) => [...prev?.slice(0, -1), value]);
    setHiddenItems((prev) => [temp, ...prev?.filter((itm) => itm !== value)]);
  };

  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter") {
  //     search();
  //   }
  // };

  const handleClick = (event) => {
    setSearchQuery("");
    setSortedItem(null);
    setAllFlag(false);
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <AppBar
        ref={navbarRef}
        position="sticky"
        sx={{
          m: 0,
          p: 0,
          gap: 0,
          backgroundColor: "#ffffff",
          boxShadow: "0px 2px 4px -2px rgba(27, 46, 94, 0.08)",
        }}
      >
        <Container
          // maxWidth="xl"
          maxWidth="100%"
          sx={{
            m: 0,
            p: 1,
            gap: 0,
            alignSelf: "center",
          }}
        >
          <Toolbar
            sx={{
              m: "-15px",
              p: 0,
              gap: 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              pl: { xs: "24px", md: "24px" },
              pr: { xs: "24px", md: "24px" },
            }}
          >
            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                // flexGrow: 1,
                justifyContent: "left",
              }}
            >
              {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="#app-bar-with-responsive-menu"
                  sx={{
                    color: "#000000",
                    textDecoration: "none",
                    pr: "5px",
                  }}
                >
                  Logo
                </Typography>
                {!isMobile && (
                  <>
                    {visibleItems?.map((itm, index) => (
                      <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                          color: "#000000",
                          gap: "35px",
                          padding: "12px",
                          pointerEvents: "none",
                          borderBottom:
                            location?.pathname === itm?.url
                              ? "1px solid black"
                              : "none",
                          "&:hover": {
                            borderBottom: "none",
                          },
                          // "&:hover": {
                          //   pb: "20px",
                          // },
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "#000000",
                            pointerEvents: "auto",
                          }}
                          to={itm?.url}
                        >
                          <Typography
                            sx={{
                              // paddingBottom: "12px",
                              transition:
                                "padding-bottom 0.2s ease, margin-bottom 0.2s ease",
                              "&:hover": {
                                paddingBottom: "8px",
                                borderBottom: "1px solid black",
                              },
                            }}
                          >
                            {itm?.id}
                          </Typography>
                        </Link>
                      </Typography>
                    ))}

                    {hiddenItems?.length > 0 && (
                      <Typography
                        variant="h6"
                        ref={buttonRef}
                        noWrap
                        component="div"
                        onClick={handleClick}
                        sx={{
                          alignSelf: "center",
                          color: "#000000",
                          textDecoration: "none",
                          "&:hover": { cursor: "pointer" },
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.92 8.17999H11.69H6.07999C5.11999 8.17999 4.63999 9.33999 5.31999 10.02L10.5 15.2C11.33 16.03 12.68 16.03 13.51 15.2L15.48 13.23L18.69 10.02C19.36 9.33999 18.88 8.17999 17.92 8.17999Z"
                            fill="#292D32"
                          />
                        </svg>
                      </Typography>
                    )}
                  </>
                )}
              </Typography>
              {!isMobile && (
                <>
                  {hiddenItems?.length >= sliceCount ? (
                    // length > 6
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Grid
                        container
                        spacing={0}
                        sx={{
                          p: 0.5,
                          m: 0.5,
                          px: 1,
                          textAlign: "center",
                          // width: "700px",
                          maxWidth: "600px",
                          overflowX: "auto",
                        }}
                      >
                        <Grid item xs={11} sx={{ p: 0 }}>
                          <TextField
                            id="outlined-size-small"
                            sx={{ p: 0, width: "100%", height: "42px" }}
                            InputProps={{ startAdornment: <Search /> }}
                            size="small"
                            value={searchQuery}
                            onChange={(e) => {
                              e.preventDefault();
                              setSearchQuery(e.target.value);
                              search();
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sx={{ p: 0, m: 0, textAlign: "center" }}
                        >
                          <Clock variant="TwoTone" color="blue" />
                        </Grid>

                        {(sorteditem ? sorteditem : hiddenItems)
                          ?.slice(0, sliceCount)
                          ?.map((itm, index) => (
                            <Grid
                              key={index}
                              item
                              xs={gridItemSize}
                              sx={{
                                p: 0,
                                m: 0,
                                mt: 1,
                                textAlign: "center",
                              }}
                            >
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "#000000",
                                }}
                                to={itm?.url}
                                onClick={(e) => {
                                  if (e.ctrlKey || e.metaKey) {
                                    return;
                                  }
                                  lastNav(itm);
                                }}
                              >
                                <MenuItem
                                  onClick={handleClose}
                                  sx={{
                                    p: 1,
                                    // px: 1,
                                    m: 0,
                                    // mt: 2,
                                    overflow: "hidden",

                                    maxWidth: "200px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",

                                    borderRight: "1px solid #CFD1D4",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      overflow: "hidden",
                                      maxWidth: "100px",
                                    }}
                                  >
                                    {itm?.id}
                                  </Typography>
                                  {/* <Divider
                                orientation="vertical"
                                flexItem
                                // color="black"
                                sx={{
                                  bgcolor: "black", // Ensures the divider color
                                }}
                              /> */}
                                </MenuItem>
                              </Link>
                            </Grid>
                          ))}
                        <Grid
                          item
                          xs={gridItemSize}
                          // xs={1.714}
                          sx={{ p: 0, m: 0, textAlign: "center" }}
                        >
                          <MenuItem
                            onClick={() => setAllFlag((prev) => !prev)}
                            sx={{
                              p: 0,
                              px: 1,
                              m: 0,
                              mt: 1,
                              overflow: "hidden",
                              maxWidth: "200px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Typography
                              component="button"
                              sx={{
                                border: "none",
                                color: "blue",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {hiddenItems?.length > sliceCount && (
                                <>
                                  {"All"}
                                  <ArrowRight />
                                </>
                              )}
                            </Typography>
                          </MenuItem>
                        </Grid>
                        <Grid item xs={12} sx={{ py: 1 }}>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{
                              borderStyle: "dashed",
                              borderColor: "#CFD1D4 ",
                            }}
                          />
                        </Grid>
                        {allFlag && (
                          <>
                            {(sorteditem ? sorteditem : hiddenItems)
                              ?.slice(sliceCount)
                              ?.map((itm, index) => (
                                <>
                                  <Grid
                                    key={index}
                                    item
                                    xs={gridItemSize}
                                    sx={{ p: 0, m: 0, textAlign: "center" }}
                                  >
                                    <Link
                                      style={{
                                        textDecoration: "none",
                                        color: "#000000",
                                      }}
                                      to={itm?.url}
                                      onClick={(e) => {
                                        if (e.ctrlKey || e.metaKey) {
                                          return;
                                        }
                                        lastNav(itm);
                                      }}
                                    >
                                      <MenuItem
                                        onClick={handleClose}
                                        sx={{
                                          p: 1,
                                          // px: 1,
                                          m: 0,
                                          // mt: 1,

                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          // borderRight: 1,
                                          borderRight: "1px solid #CFD1D4",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            overflow: "hidden",
                                            maxWidth: "100px",
                                          }}
                                        >
                                          {itm?.id}
                                        </Typography>
                                        {/* <Divider
                                    orientation="vertical"
                                    flexItem
                                    // color="black"
                                    sx={{
                                      bgcolor: "black",
                                    }}
                                  /> */}
                                      </MenuItem>
                                    </Link>
                                  </Grid>
                                  {(index + (sliceCount + 1)) %
                                    (sliceCount + 1) ===
                                    sliceCount && (
                                    <Grid item xs={12} sx={{ py: 1 }}>
                                      <Divider
                                        orientation="horizontal"
                                        flexItem
                                        // color="black"
                                        sx={{
                                          borderStyle: "dashed",
                                          borderColor: "#CFD1D4 ",
                                        }}
                                      />
                                    </Grid>
                                  )}
                                </>
                              ))}
                          </>
                        )}
                      </Grid>
                    </Menu>
                  ) : hiddenItems?.length == 5 ? (
                    <div>
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        color="#FFFFFF"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        // sx={{
                        //   marginTop: "30px",
                        //   boxShadow: "initial",
                        // }}
                      >
                        <Grid
                          container
                          direction="row"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            // width: "508px",
                            // height: "62px",
                            padding: "2px 5px",
                          }}
                        >
                          {hiddenItems?.map((item, index) => {
                            return (
                              <>
                                <Link
                                  onClick={(e) => {
                                    {
                                      if (e.ctrlKey || e.metaKey) {
                                        return;
                                      }
                                      lastNav(itm);
                                    }
                                    handleClose();
                                  }}
                                  style={{
                                    textDecoration: "none",
                                    color: "#000000",
                                  }}
                                  to={item?.url}
                                >
                                  <MenuItem
                                    sx={{
                                      m: 0,
                                      px: "8px",
                                    }}
                                  >
                                    {item?.id}
                                  </MenuItem>
                                </Link>
                                {index < hiddenItems?.length - 1 ? (
                                  <Divider
                                    orientation="vertical"
                                    color="black"
                                    flexItem
                                  />
                                ) : null}
                              </>
                            );
                          })}
                        </Grid>
                      </Menu>
                    </div>
                  ) : (
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      sx={
                        {
                          // px: "10px",
                        }
                      }
                    >
                      {hiddenItems?.map((item, index) => (
                        <Link
                          style={{
                            textDecoration: "none",
                            fontWeight: 400,
                            color: "rgba(53, 53, 53, 1)",
                          }}
                          to={item?.url}
                          onClick={() => {
                            {
                              if (e.ctrlKey || e.metaKey) {
                                return;
                              }
                              lastNav(itm);
                            }
                            handleClose();
                          }}
                        >
                          <Typography
                            key={index}
                            sx={{
                              px: "18px",
                              // py: "5px",
                              gap: "10px",
                              width: "150px",
                            }}
                          >
                            <MenuItem
                              sx={{
                                pl: 0,
                                py: 1.5,
                                color: "black",
                                overflow: "hidden",

                                borderBottom: "0.5px",
                                borderStyle: "dashed",
                                borderColor: "rgba(53, 53, 53, 0.53)",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                }}
                              >
                                {item?.id}
                              </Typography>
                            </MenuItem>
                            {/* {index < hiddenItems.length - 1 && (
                      <Divider
                        sx={{ border: "dashed 0.5px gray", width: "inherit" }}
                      />
                    )} */}
                          </Typography>
                        </Link>
                      ))}
                    </Menu>
                  )}
                </>
              )}
            </Typography>
            <Typography
              sx={{
                // ml: "auto",
                display: "flex",
                minWidth: "fit-content",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              {isMobile && <HambergerMenu />}
              <Notification />
              {isMobile ? (
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-block",
                    marginLeft: "10px",
                  }}
                >
                  {/* <Profile /> */}
                  <Avatar
                    alt="profile user"
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    src={
                      profileAvatar !== undefined
                        ? service.IMG_URL + profileAvatar
                        : avatar1
                    }
                    sx={{ width: 36, height: 36 }}
                  />
                  <IconButton
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    sx={{
                      position: "absolute",
                      bottom: 21,
                      left: 25,
                      backgroundColor: "#4680FF",
                      color: "white",
                      width: 20,
                      height: 20,
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MenuIcon
                      fontSize="small"
                      sx={{ border: "1px solid white", borderRadius: "50%" }}
                    />
                  </IconButton>
                </Box>
              ) : (
                <Profile />
              )}

              {isMobile && (
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor="right"
                  open={drawerOpen}
                  onClose={() => dispatch(openDrawer(false))}
                  ModalProps={{ keepMounted: true }}
                  sx={{
                    display: { xs: "block", lg: "none" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: "220px",
                      borderRight: `1px solid ${theme?.palette?.divider}`,
                      backgroundImage: "none",
                      boxShadow: "inherit",
                      // height: "1275px",
                      height: "100vh",
                      overflowY: "auto",
                      // height: "1400px",
                      borderRadius: "5px",
                    },
                  }}
                >
                  <Box sx={{ textAlign: "center", marginTop: "11px" }}>
                    {/* <Profile /> */}
                    <Box
                      sx={{
                        display: "inline-block",
                      }}
                    >
                      {/* Avatar */}
                      <Avatar
                        alt="profile user"
                        src={
                          profileAvatar !== undefined
                            ? service.IMG_URL + profileAvatar
                            : avatar1
                        }
                        sx={{ width: 65, height: 65 }}
                      />

                      {/* Button on Avatar */}
                    </Box>

                    <Typography style={{ fontSize: "14px", marginTop: "10px" }}>
                      {user?.name || "User-Name"}
                    </Typography>
                    <Typography
                      // variant="caption"
                      color="text.secondary"
                      sx={{ fontSize: "10px", margin: "1px" }}
                    >
                      Email: abc@gmail.com
                    </Typography>
                    <Typography sx={{ fontSize: "10px", margin: "1px" }}>
                      Designation
                    </Typography>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"center"}
                      sx={{ marginTop: "15px" }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#7EA6FF",
                          minWidth: "91px",
                          height: "26px",
                          borderRadius: "5px",
                          fontSize: "10px",
                          padding: "13px 7px 13px 10px",
                          textTransform: "none",
                        }}
                        startIcon={<UserEdit sx={{ fontSize: "14px" }} />}
                      >
                        Edit Profile
                      </Button>

                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#7EA6FF",
                          width: "91px",
                          height: "26px",
                          borderRadius: "5px",
                          fontSize: "10px",
                        }}
                        color="error"
                        startIcon={<LogoutCurve />}
                        onClick={handleLogout}
                      >
                        Logout
                      </Button>
                    </Stack>
                  </Box>

                  <Divider
                    sx={{
                      borderBottomWidth: 1,
                      marginTop: "15px",
                      border: "#e8e7e7 solid 1px",
                    }}
                  />

                  <TextField
                    id="drawer-search"
                    sx={{
                      p: 0,
                      width: "190px",
                      height: "32px",
                      marginTop: "15px",
                      textAlign: "center",
                      marginLeft: "18px",
                      border: "#EAEAEA 1px",
                      marginBottom: "10px",
                    }}
                    InputProps={{ startAdornment: <Search /> }}
                    size="small"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />
                  {menuItems
                    ?.filter((value) =>
                      String(value?.id || "")
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    ?.map((value, index) => (
                      <Box key={index} sx={{ textAlign: "end" }}>
                        <ul style={{ textAlign: "start", paddingLeft: "40px" }}>
                          <li
                            style={{
                              fontSize: "12px",
                              listStyle: "none",
                              position: "relative",
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                                left: "-15px",
                                top: "8px",
                                width: "6px",
                                height: "6px",
                                backgroundColor: color[index % color.length],
                                borderRadius: "50%",
                                display: "inline-block",
                              }}
                            ></span>
                            <NavLink
                              to={value?.url}
                              style={{
                                textDecoration: "none",
                                color: "#000000",
                              }}
                            >
                              <Typography>{value?.id}</Typography>
                            </NavLink>
                          </li>
                        </ul>
                      </Box>
                    ))}
                  <SimpleBar />
                </Drawer>
              )}
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default NavBar;

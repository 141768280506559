import { FormattedMessage } from "react-intl";
import { useState,useEffect  } from "react";
import {
  Home3,
  Profile2User,
  Box1,
  DocumentForward,
  Category2,
  DocumentCode2,
  MessageQuestion,
  Award,
  DocumentCode,
} from "iconsax-react";
import contents from "./contents";
import navigation from "./navigation";
import RegTechView from "pages/CRM/RagTech/RegTechView";
import { getObjectPermissions } from "services/utils";
// const permission = getObjectPermissions();
// const userData = JSON.parse(localStorage.getItem("user_data"));
  // const [
  //   accountPerm,
  //   contactPerm,
  //   opportunityPerm,
  //   proposalPerm,
  //   pInPerm,
  //   fInPerm,
  //   payDetailPerm,
  //   payDistributionPerm,
  //   termConditionPerm,
  //   serviceBriefPerm,
  //   bankPerm,
  //   currencyPerm,
  // ] = [
  //   permission?.account || false,
  //   permission?.contact || false,
  //   permission?.opportunity || false,
  //   permission?.proposal || false,
  //   permission?.["p-invoice"] || false,
  //   permission?.["f-invoice"] || false,
  //   permission?.["payment-detail"] || false,
  //   permission?.["payment-distribution"] || false,
  //   permission?.["term-condition"] || false,
  //   permission?.["service-brief"] || false,
  //   permission?.bank || false,
  //   permission?.currency || false,
  // ];

const icons = {
  dashboard: Home3,
  client: Profile2User,
  product: Box1,
  templates: DocumentForward,
  category: Category2,
  question: MessageQuestion,
  standard: Award,
  documentization: DocumentCode,
};

const Clients = {
  id: "Clients",
  title: <FormattedMessage id="Clients" />,
  type: "item",
  url: "/client/client-list",
  icon: icons.client,
};

const Standards = {
  id: "Standards",
  title: <FormattedMessage id="Standards" />,
  type: "item",
  url: "/standards/standard-list",
  icon: icons.standard,
};
const Accounts = {
  id: "Accounts",
  title: <FormattedMessage id="Accounts" />,
  type: "item",
  url: "/account-list",
  // icon: icons.,
};

const Opportunity = {
  id: "Opportunity",
  title: <FormattedMessage id="Opportunity" />,
  type: "item",
  url: "/opportunity-list",
  // icon: icons.,
};

const Proposal = {
  id: "Proposal",
  title: <FormattedMessage id="Proposal" />,
  type: "item",
  url: "/proposal-list",
  // icon: icons.,
};
const FollowUpTask = {
  id: "FollowUp Task",
  title: <FormattedMessage id="FollowUp Task" />,
  type: "item",
  url: "/followUpTask",
  // icon: icons.,
};

const Payment = {
  id: "Payment Details",
  title: <FormattedMessage id="Payment Details" />,
  type: "item",
  url: "/paymentDetail-list",
  // icon: icons.,
};
const PaymentDistribution = {
  id: "Payment Distribution",
  title: <FormattedMessage id="Payment Distribution" />,
  type: "item",
  url: "/paymentDistribution-list",
  // icon: icons.,
};

const PInvoice = {
  id: "P Invoice",
  title: <FormattedMessage id="P Invoice" />,
  type: "item",
  url: "/pInvoice-list",
  // icon: icons.,
};

const FInvoice = {
  id: "F Invoice",
  title: <FormattedMessage id="F Invoice" />,
  type: "item",
  url: "/fInvoice-list",
};

const TermCondition = {
  id: "Term Condition",
  title: <FormattedMessage id="Term Condition" />,
  type: "item",
  url: "/termCondition-list",
  // icon: icons.,
};

const ServiceBrief = {
  id: "Service Brief",
  title: <FormattedMessage id="Service Brief" />,
  type: "item",
  url: "/serviceBrief-list",
  // icon: icons.,
};

const Currency = {
  id: "Currency",
  title: <FormattedMessage id="Currency" />,
  type: "item",
  url: "/currency",
  // icon: icons.,
};
const Contact = {
  id: "Contact",
  title: <FormattedMessage id="Contact" />,
  type: "item",
  url: "/contact-list",
  // icon: icons.,
};
const Users = {
  id: "Users",
  title: <FormattedMessage id="Users" />,
  type: "item",
  url: "/users-list",
  // icon: icons.,
};


const Bank = {
  id: "Bank",
  title: <FormattedMessage id="Bank" />,
  type: "item",
  url: "/bank-list",
  // icon: icons.,
};

// const contents = {
//   id: "Document Question",
//   title: <FormattedMessage id="contents" />,
//   type: "group",
//   icon: icons.question,
//   children: [
//     {
//       id: "Document Question",
//       title: <FormattedMessage id="Document Question" />,
//       type: "collapse",
//       icon: icons.question,
//       children: [
//         {
//           id: "allQuestions",
//           title: <FormattedMessage id="Question List" />,
//           type: "item",
//           url: "/documentQuestion/allQuestions",
//         },
//         {
//           id: "short-question",
//           title: <FormattedMessage id="Short Question" />,
//           type: "item",
//           url: "/documentQuestion/short-question",
//         },
//         {
//           id: "mcq-question",
//           title: <FormattedMessage id="MCQ Question" />,
//           type: "item",
//           url: "/documentQuestion/mcq-question",
//         },
//         {
//           id: "mcmc-question",
//           title: <FormattedMessage id="MCMC Question" />,
//           type: "item",
//           url: "/documentQuestion/mcmc-question",
//         },
//         {
//           id: "dropdown-question",
//           title: <FormattedMessage id="Dropdown Question" />,
//           type: "item",
//           url: "/documentQuestion/dropdown-question",
//         },
//       ],
//     },
//   ],
// };

const MenuData = () => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user_data"));
    const userType = userData?.userType;
    const permission = getObjectPermissions();

    const [
      accountPerm,
      contactPerm,
      opportunityPerm,
      proposalPerm,
      pInPerm,
      fInPerm,
      payDetailPerm,
      payDistributionPerm,
      termConditionPerm,
      serviceBriefPerm,
      bankPerm,
      currencyPerm,
    ] = [
      permission?.account || false,
      permission?.contact || false,
      permission?.opportunity || false,
      permission?.proposal || false,
      permission?.["p-invoice"] || false,
      permission?.["f-invoice"] || false,
      permission?.["payment-detail"] || false,
      permission?.["payment-distribution"] || false,
      permission?.["term-condition"] || false,
      permission?.["service-brief"] || false,
      permission?.bank || false,
      permission?.currency || false,
    ];

    const filteredItems = [
      navigation,
      FollowUpTask,
      accountPerm && Accounts,
      opportunityPerm && Opportunity,
      proposalPerm && Proposal,
      pInPerm && PInvoice,
      fInPerm && FInvoice,
      payDetailPerm && Payment,
      payDistributionPerm && PaymentDistribution,
      contactPerm && Contact,
      termConditionPerm && TermCondition,
      serviceBriefPerm && ServiceBrief,
      currencyPerm && Currency,
      bankPerm && Bank,
      ...contents?.children,
    ].filter(Boolean);

    const AdminItems = [
      navigation,
      FollowUpTask,
      Accounts,
      Opportunity,
      Proposal,
      PInvoice,
      FInvoice,
      Payment,
      PaymentDistribution,
      Contact,
      TermCondition,
      ServiceBrief,
      Users,
      Currency,
      Bank,
      ...contents?.children,
    ];

    setMenuItems(userType === "admin" ? AdminItems : filteredItems);
  }, []);

  return menuItems; // Return menu items to be used in Navbar component
};

export default MenuData;
